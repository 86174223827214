
import React, { useContext, useEffect, useState }  from 'react'
import { useLocation, Link } from "react-router-dom";
import pathToRegexp from "path-to-regexp";
import styled from 'styled-components'
import Header from '../Header'
import Footer from 'components/Footer';



export default function Layout({children, routeArr}) {
  const [currentRoute, setCurrentRoute] = useState<any>({})
  const [currentId, setCurrentId] = useState('')
  // const { state, dispatch } = useContext(mainContext);
  const location = useLocation();
  useEffect(() => {
    // find current route
    routeArr.find(cell => {
      if (pathToRegexp(cell.path).test(location.pathname)) {
        setCurrentRoute(cell)
        return true
      }
    })
    setCurrentId(location.pathname.substring(7))
  }, [location.pathname])
  return (
    <div>
      <Header></Header>
      <div>{children}</div>
      <Footer></Footer>
    </div>
  )
}
