import React from 'react'
import { BREAKPOINTS } from 'theme';

import styled from 'styled-components';

import social1 from 'assets/img/Twitter.svg';
import social2 from 'assets/img/Telegram.svg';
import social3 from 'assets/img/Medium.svg';
import social4 from 'assets/img/Discord.svg';
import social5 from 'assets/img/Instagram.svg';
import social6 from 'assets/img/TikTok.svg';
import deesse from 'assets/img/logo_deesse2.svg'
import linktree from 'assets/img/Linktree_logo.svg.svg'
import logoTron from 'assets/img/logo_tron.svg'
import logoYgg from 'assets/img/logo_ygg.svg'
import logoPolygonStudios from 'assets/img/logo_polygonstudios.svg'
import logoPolygon from 'assets/img/logo_polygon.svg'
import logoNgc from 'assets/img/logo_ngc.svg'
import logoAu21 from 'assets/img/logo_au21.svg'
import logoInfinity from 'assets/img/logo_Infinity.svg'
import logoJumptrading from 'assets/img/logo_jumptrading.svg'
import logoRepublic from 'assets/img/logo_republic.svg'

const FooterBox = styled.div`
  margin: 80px auto 0;
  border-top: 2px solid #292526;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-top: 20px;
  }
`
const Footer1 = styled.div`
  display: flex;
  /* height: 240px; */
  width: 1200px;
  margin: auto;
  padding: 30px 0px;
  /* border-top: 2px solid #292526; */
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    flex-direction: column;
    height: auto;
    margin-top: 40px;
    padding: 30px 24px;
  }
`
const ParentBox = styled.div`
  display: flex;
  flex-direction: column;
`
const ParentBox1 = styled(ParentBox)`
  margin-right: 180px;
`
const ParentBox2 = styled(ParentBox)`
  margin-right: 80px;
`
const BoxTitle = styled.div`
  margin-bottom: 20px;
  color: #292526;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
      margin-top: 20px;
  }
`
const BoxTxt = styled.a`
  margin-bottom: 10px;
  color: #666;
  font-family: Helvetica;
  font-size: 16px;
`
const ParentImg1 = styled.img`
  margin-bottom: 30px;
`
const Inverstors = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-wrap: nowrap;
  }
`
const ParentImg2 = styled.img`
  margin-right: 30px;
  margin-bottom: 10px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-right: 6px;
    margin-bottom: 14px;
    width: 70px;
  }
`
const Footer2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  /* height: 106px; */
  padding: 20px 100px;
  background: #292526;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    height: auto;
    padding: 20px 20px;
  }
`
const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
  }
`
const ALink = styled.a`
  margin-right: 18px;
  color: #FFF;
  font-family: Helvetica;
  font-size: 16px;
  text-decoration-line: underline;
`
const SocialIcon = styled.img`
  margin-right: 18px;
`
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  color: #999;
  font-size: 14px;
`

export default function Footer() {
  return (
    <FooterBox>
      <Footer1>
        <ParentBox1>
          <ParentImg1 src={deesse}></ParentImg1>
          <ParentImg1 src={linktree} style={{cursor: 'pointer'}} onClick={()=>{window.open('https://linktr.ee/DEESSE_DAO')}}></ParentImg1>
        </ParentBox1>
        <ParentBox2>
          <BoxTitle>DEESSE</BoxTitle>
          <BoxTxt target='_blank' href='https://deesse-2.gitbook.io/deesse/'>White Paper</BoxTxt>
          <BoxTxt target='_blank' href='/about'>About</BoxTxt>
        </ParentBox2>
        <ParentBox>
          <BoxTitle>INVERSTORS</BoxTitle>
          <Inverstors>
            <ParentImg2 src={logoTron}></ParentImg2>
            <ParentImg2 src={logoYgg}></ParentImg2>
            <ParentImg2 src={logoPolygonStudios}></ParentImg2>
            <ParentImg2 src={logoPolygon}></ParentImg2>
          </Inverstors>
          <Inverstors>
            <ParentImg2 src={logoNgc}></ParentImg2>
            <ParentImg2 src={logoAu21}></ParentImg2>
            <ParentImg2 src={logoInfinity}></ParentImg2>
            <ParentImg2 src={logoJumptrading}></ParentImg2>
            <ParentImg2 src={logoRepublic}></ParentImg2>
          </Inverstors>
        </ParentBox>
        <div></div>
        <div></div>
      </Footer1>
      <Footer2>
        <SpaceBetween>
          <div className='mb10'>
            <ALink>Privacy policy</ALink>
            <ALink>Terms & condition</ALink>
          </div>
          <div>
            <a target='_blank' href='https://twitter.com/deesse_dao'>
              <SocialIcon src={social1}></SocialIcon>
            </a>
            <a target='_blank' href='https://t.me/deesse2021'>
              <SocialIcon src={social2}></SocialIcon>
            </a>
            <a target='_blank' href='https://medium.com/@deesse_2021'>
              <SocialIcon src={social3}></SocialIcon>
            </a>
            <a target='_blank' href='https://discord.com/invite/mh4cSefPxg'>
              <SocialIcon src={social4}></SocialIcon>
            </a>
            <a target='_blank' href='https://www.instagram.com/deesse_dao/'>
              <SocialIcon src={social5}></SocialIcon>
            </a>
            <a target='_blank' href='https://www.tiktok.com/@deesse.art'>
              <SocialIcon src={social6}></SocialIcon>
            </a>
          </div>
        </SpaceBetween>
        <FlexCenter>Copyright © 2023 DEESSE.All rights reserved</FlexCenter>
      </Footer2>
    </FooterBox>
  )
}
