import jh1 from 'assets/img/human/1stJanuary-Elegance.png'
import jh2 from 'assets/img/human/2nd January - Genial.png'
import jh3 from 'assets/img/human/3rd January - Euphoria.png'
import jh4 from 'assets/img/human/4th January - Evocative.png'
import jh5 from 'assets/img/human/5th January - Abundance.png'
import jh6 from 'assets/img/human/6th January - Vital.png'
import jh7 from 'assets/img/human/7th January - Peace.png'
import jh8 from 'assets/img/human/8th January - Sanguine.png'
import jh9 from 'assets/img/human/9th January - Serendipity.png'
import jh10 from 'assets/img/human/10th January - Energetic.png'
import jh11 from 'assets/img/human/11th January - Resilience.png'
import jh12 from 'assets/img/human/12th January - Brave.png'
import jh13 from 'assets/img/human/13th January - Felicity.png'
import jh14 from 'assets/img/human/14th January - Effervescent.png'
import jh15 from 'assets/img/human/15th January - Eloquence.png'
import jh16 from 'assets/img/human/16th January = Mellifluous.png'
import jh17 from 'assets/img/human/17th January - Authenticity.png'
import jh18 from 'assets/img/human/18th January - Fantasy.png'
import jh19 from 'assets/img/human/19th January - Vivacious.png'
import jh20 from 'assets/img/human/20th January - Soulful.png'
import jh21 from 'assets/img/human/21th January - Willing.png'
import jh22 from 'assets/img/human/22-Wanderlust.png'
import jh23 from 'assets/img/human/23th January - Reliable.png'
import jh24 from 'assets/img/human/24 January - Joy.png'
import jh25 from 'assets/img/human/25th January - Marvelous.png'
import jh26 from 'assets/img/human/26th January - Truthful.png'
import jh27 from 'assets/img/human/27th January - Generosity.png'
import jh28 from 'assets/img/human/28th January - Genuine.png'
import jh29 from 'assets/img/human/29th January - Adorable.png'
import jh30 from 'assets/img/human/30th January - Hilarity.png'
import jh31 from 'assets/img/human/31th January - Virtuosity.png'

import m2h1 from 'assets/img/human/1st February - Beautiful.png'
import m2h2 from 'assets/img/human/2nd February - Safe.png'
import m2h3 from 'assets/img/human/3rd February - Bliss.png'
import m2h4 from 'assets/img/human/4th February - Charming.png'
import m2h5 from 'assets/img/human/5th February - Thrilling.png'
import m2h6 from 'assets/img/human/6th February - Confidence.png'
import m2h7 from 'assets/img/human/7th February - Phenomenal.png'
import m2h8 from 'assets/img/human/8th February - Tranquillity.png'
import m2h9 from 'assets/img/human/9th February - Awesome.png'
import m2h10 from 'assets/img/human/10th February - Angelic.png'
import m2h11 from 'assets/img/human/11th February - Discipline.png'
import m2h12 from 'assets/img/human/12th February - Elegant.png'
import m2h13 from 'assets/img/human/13th February - Sparkly.png'
import m2h14 from 'assets/img/human/14th February - Love.png'
import m2h15 from 'assets/img/human/15th february - Secure.png'
import m2h16 from 'assets/img/human/16th February - Opulence.png'
import m2h17 from 'assets/img/human/17th February - Happy.png'
import m2h18 from 'assets/img/human/18th February - Ecstatic.png'
import m2h19 from 'assets/img/human/19th February - Bountiful.png'
import m2h20 from 'assets/img/human/20th February - Accuracy.png'
import m2h21 from 'assets/img/human/21th February - Gallant.png'
import m2h22 from 'assets/img/human/22th February - Certainity.png'
import m2h23 from 'assets/img/human/23th February - Thriving.png'
import m2h24 from 'assets/img/human/24th February - Heavenly.png'
import m2h25 from 'assets/img/human/25th February - Virtuous.png'
import m2h26 from 'assets/img/human/26th February - Security.png'
import m2h27 from 'assets/img/human/27th February - Lucky.png'
import m2h28 from 'assets/img/human/28th February - Pleasant.png'
import m2h29 from 'assets/img/human/29th February - Bonanza.png'

import ju1 from 'assets/img/universe/1st January - Rigel Kent.png'
import ju2 from 'assets/img/universe/2nd January -Acamar.png'
import ju3 from 'assets/img/universe/3rd January - Errai.png'
import ju4 from 'assets/img/universe/4th January - Lesath.png'
import ju5 from 'assets/img/universe/5th January - Acrab.png'
import ju6 from 'assets/img/universe/6th January - Sargas.png'
import ju7 from 'assets/img/universe/7th January - Sarin.png'
import ju8 from 'assets/img/universe/8th January - Sceptrum.png'
import ju9 from 'assets/img/universe/9th January - Scheat.png'
import ju10 from 'assets/img/universe/10th January - Adhil.png'
import ju11 from 'assets/img/universe/11th January - Ain.png'
import ju12 from 'assets/img/universe/12th January - Mirach.png'
import ju13 from 'assets/img/universe/13th January - Miram.png'
import ju14 from 'assets/img/universe/14th January-Mirfak.png'
import ju15 from 'assets/img/universe/15th January - Mirzam.png'
import ju16 from 'assets/img/universe/16th January - Misam.png'
import ju17 from 'assets/img/universe/17th January - Alchiba.png'
import ju18 from 'assets/img/universe/18th January - Asellus Australis.png'
import ju19 from 'assets/img/universe/19th January - Alcyone.png'
import ju20 from 'assets/img/universe/20th January - Aldebaran.png'
import ju21 from 'assets/img/universe/21th January - Wezen.png'
import ju22 from 'assets/img/universe/22th January - Wurren.png'
import ju23 from 'assets/img/universe/23th January - Xamidimura.png'
import ju24 from 'assets/img/universe/24th January - Xuange.png'
import ju25 from 'assets/img/universe/25th January - Yed Posterior.png'
import ju26 from 'assets/img/universe/26th January - Algedi.png'
import ju27 from 'assets/img/universe/27th January - Algenib.png'
import ju28 from 'assets/img/universe/28th January - Tania Borealis.png'
import ju29 from 'assets/img/universe//29th January - Tarazed.png'
import ju30 from 'assets/img/universe/30th January - Algorab.png'
import ju31 from 'assets/img/universe/31th January -Dalim.png'

import m2u1 from 'assets/img/universe/1st February - Saclateni.png'
import m2u2 from 'assets/img/universe/2nd February - Musica.png'
import m2u3 from 'assets/img/universe/3rd February - Homam.png'
import m2u4 from 'assets/img/universe/4th February - Beid.png'
import m2u5 from 'assets/img/universe/5th January - Bellatrix.png'
import m2u6 from 'assets/img/universe/6th February - Alphard.png'
import m2u7 from 'assets/img/universe/7th February - Tegmine.png'
import m2u8 from 'assets/img/universe/8th February - Pipirima.png'
import m2u9 from 'assets/img/universe/9th February - Electra.png'
import m2u10 from 'assets/img/universe/10th February - Castor.png'
import m2u11 from 'assets/img/universe/11th February - Bunda.png'
import m2u12 from 'assets/img/universe/12th Febraury - Canopus.png'
import m2u13 from 'assets/img/universe/13th February - Capella.png'
import m2u14 from 'assets/img/universe/14th February - Merga.png'
import m2u15 from 'assets/img/universe/15th February - Copernicus.png'
import m2u16 from 'assets/img/universe/16th February - Proxima Centauri.png'
import m2u17 from 'assets/img/universe/17th February - Sirius.png'
import m2u18 from 'assets/img/universe/18th February - Ascella.png'
import m2u19 from 'assets/img/universe/19th February - Taygeta.png'
import m2u20 from 'assets/img/universe/20th February - Tarf.png'
import m2u21 from 'assets/img/universe/21st February - Chamukuy.png'
import m2u22 from 'assets/img/universe/22nd February - Zosma.png'
import m2u23 from 'assets/img/universe/23rd February - Vindemiatrix.png'
import m2u24 from 'assets/img/universe/24th February - Maia.png'
import m2u25 from 'assets/img/universe/25th February - Izar.png'
import m2u26 from 'assets/img/universe/26th February - Situla.png'
import m2u27 from 'assets/img/universe/27th February - Praecipua.png'
import m2u28 from 'assets/img/universe/28th February - Dabih.png'
import m2u29 from 'assets/img/universe/29th February - Shaula.png'

import jn1 from 'assets/img/nature/1th January - Aconitum.png'
import jn2 from 'assets/img/nature/2nd January - White Gazania.png'
import jn3 from 'assets/img/nature/3rd January - African.png'
import jn4 from 'assets/img/nature/4th January - Platycodon.png'
import jn5 from 'assets/img/nature/5th January - Winterberry.png'
import jn6 from 'assets/img/nature/6th January - Alchemilla.png'
import jn7 from 'assets/img/nature/7th January - Allium.png'
import jn8 from 'assets/img/nature/8th January - Freesia.png'
import jn9 from 'assets/img/nature/9th January - Sorbus Aucuparia.png'
import jn10 from 'assets/img/nature/10th January - Astrantia.png'
import jn11 from 'assets/img/nature/11th January - Pine.png'
import jn12 from 'assets/img/nature/12th January - Amaryllis.png'
import jn13 from 'assets/img/nature/13th January - Wood anemone.png'
import jn14 from 'assets/img/nature/14th January - Corchorus capsularis.png'
import jn15 from 'assets/img/nature/15th January - Magnolia.png'
import jn16 from 'assets/img/nature/16th January - Anthurium.png'
import jn17 from 'assets/img/nature/17th January - Pansy.png'
import jn18 from 'assets/img/nature/18th January - Aquilegia.png'
import jn19 from 'assets/img/nature/19th January - Xerophyllum.png'
import jn20 from 'assets/img/nature/20th January - Asclepias syriaca.png'
import jn21 from 'assets/img/nature/21th January - Aster amellus.png'
import jn22 from 'assets/img/nature/22th January - Guava.png'
import jn23 from 'assets/img/nature/23th January - Astilbes.png'
import jn24 from 'assets/img/nature/24th January - Alyssum.png'
import jn25 from 'assets/img/nature/25th January - Mauve.png'
import jn26 from 'assets/img/nature/26th January - Aubreita deltoidea.png'
import jn27 from 'assets/img/nature/27th January - Bee Balm Flower.png'
import jn28 from 'assets/img/nature/28th January - Lunaria.png'
import jn29 from 'assets/img/nature/29th January - Hophornbeam.png'
import jn30 from 'assets/img/nature/30th January - Gypsophila.png'
import jn31 from 'assets/img/nature/31th January - Campanula latifolia.png'

import m2n1 from 'assets/img/nature/1st February - Begonia Dragon Wing.png'
import m2n2 from 'assets/img/nature/2nd February - Campanula Alpestris.png'
import m2n3 from 'assets/img/nature/3rd February - Cardinal Flower.png'
import m2n4 from 'assets/img/nature/4th February - Black eyed Susan.png'
import m2n5 from 'assets/img/nature/5th February - Bergenia crassifolia.png'
import m2n6 from 'assets/img/nature/6th February - Bleeding Heart.png'
import m2n7 from 'assets/img/nature/7th February - Linaria.png'
import m2n8 from 'assets/img/nature/8th February - Primula.png'
import m2n9 from 'assets/img/nature/9th February - Cockscomb Celosia.png'
import m2n10 from 'assets/img/nature/10th February - Bouvardia.png'
import m2n11 from 'assets/img/nature/11th February - Buddleja.png'
import m2n12 from 'assets/img/nature/12th February - Bush Morning Glory.png'
import m2n13 from 'assets/img/nature/13th February - Ranunculus Buttercup.png'
import m2n14 from 'assets/img/nature/14th February - Calendula Officinalis.png'
import m2n15 from 'assets/img/nature/15th February - Cape Primrose.png'
import m2n16 from 'assets/img/nature/16th February - Calla Lily.png'
import m2n17 from 'assets/img/nature/17th February - Campanula Latifolia.png'
import m2n18 from 'assets/img/nature/18th February - Candytuft.png'
import m2n19 from 'assets/img/nature/19th February - Canna Lily.png'
import m2n20 from 'assets/img/nature/20th February - Blazing Star.png'
import m2n21 from 'assets/img/nature/21st February - California Poppy.png'
import m2n22 from 'assets/img/nature/22nd February - Carnation.png'
import m2n23 from 'assets/img/nature/23rd February - Celosia.png'
import m2n24 from 'assets/img/nature/24th February - Chrysanthemum.png'
import m2n25 from 'assets/img/nature/25th February - Clarkia.png'
import m2n26 from 'assets/img/nature/26th February - Clematis.png'
import m2n27 from 'assets/img/nature/27th February - Four Leaf Clover.png'
import m2n28 from 'assets/img/nature/28th February - Blue Star Flower.png'
import m2n29 from 'assets/img/nature/29th February - Dianella.png'

import m3h1 from 'assets/img/human3/1st March - Prosperity.png'
import m3h2 from 'assets/img/human3/2nd March - Intellectual.png'
import m3h3 from 'assets/img/human3/3rd March - Shiny.png'
import m3h4 from 'assets/img/human3/4th March - Graceful.png'
import m3h5 from 'assets/img/human3/5th March - Fantastic.png'
import m3h6 from 'assets/img/human3/6th March - Affability.png'
import m3h7 from 'assets/img/human3/7th March - Glamorous.png'
import m3h8 from 'assets/img/human3/8th March - Impressive.png'
import m3h9 from 'assets/img/human3/9th March - Enthusiastic.png'
import m3h10 from 'assets/img/human3/10th March - Stupendous.png'
import m3h11 from 'assets/img/human3/11th March - Harmony.png'
import m3h12 from 'assets/img/human3/12th March - Terrific.png'
import m3h13 from 'assets/img/human3/13th March - Gracious.png'
import m3h14 from 'assets/img/human3/14 March - Jubilant.png'
import m3h15 from 'assets/img/human3/15th March - Exquisite.png'
import m3h16 from 'assets/img/human3/16th March - Lively.png'
import m3h17 from 'assets/img/human3/17 March - Superb.png'
import m3h18 from 'assets/img/human3/18th March - Attractive.png'
import m3h19 from 'assets/img/human3/19th March - Jauntiness.png'
import m3h20 from 'assets/img/human3/20th March - Rejoice.png'
import m3h21 from 'assets/img/human3/21st March - Genius.png'
import m3h22 from 'assets/img/human3/22nd March - Delicacy.png'
import m3h23 from 'assets/img/human3/23rd March - Compassion.png'
import m3h24 from 'assets/img/human3/24th March - Proud.png'
import m3h25 from 'assets/img/human3/25th March - Diplomacy.png'
import m3h26 from 'assets/img/human3/26th March - Plentiful.png'
import m3h27 from 'assets/img/human3/27th March - Dignity.png'
import m3h28 from 'assets/img/human3/28th March - Reverence.png'
import m3h29 from 'assets/img/human3/29th March - Transformative.png'
import m3h30 from 'assets/img/human3/30th March - Liberty.png'
import m3h31 from 'assets/img/human3/31st March - Loquacity.png'

import m3u1 from 'assets/img/universe3/1st March - Larawag.png'
import m3u2 from 'assets/img/universe3/2nd March - La Superba.png'
import m3u3 from 'assets/img/universe3/3rd March - Kurhah.png'
import m3u4 from 'assets/img/universe3/4th March - Sadachbia.png'
import m3u5 from 'assets/img/universe3/5th March - Libertas.png'
import m3u6 from 'assets/img/universe3/6th March - Lich.png'
import m3u7 from 'assets/img/universe3/7th March - Lilii Borea.png'
import m3u8 from 'assets/img/universe3/8th March - Maasym.png'
import m3u9 from 'assets/img/universe3/9th March - Vega.png'
import m3u10 from 'assets/img/universe3/10th March - Alrescha.png'
import m3u11 from 'assets/img/universe3/11th March - Denebola.png'
import m3u12 from 'assets/img/universe3/12th March - Markab.png'
import m3u13 from 'assets/img/universe3/13th March - Secunda Giedi.png'
import m3u14 from 'assets/img/universe3/14th March - Matar.png'
import m3u15 from 'assets/img/universe3/15th March - Mebsuta.png'
import m3u16 from 'assets/img/universe3/16th March - Megrez.png'
import m3u17 from 'assets/img/universe3/17th March - Meissa.png'
import m3u18 from 'assets/img/universe3/18th March - Mekbuda.png'
import m3u19 from 'assets/img/universe3/19th March - Meleph.png'
import m3u20 from 'assets/img/universe3/20th March - Ancha.png'
import m3u21 from 'assets/img/universe3/21th March - Menkar.png'
import m3u22 from 'assets/img/universe3/22nd March - Cursa.png'
import m3u23 from 'assets/img/universe3/23nd March - Ogma.png'
import m3u24 from 'assets/img/universe3/24th March - Castula.png'
import m3u25 from 'assets/img/universe3/25th March - Chertan.png'
import m3u26 from 'assets/img/universe3/26th March - Meridiana.png'
import m3u27 from 'assets/img/universe3/27th March - Merope.png'
import m3u28 from 'assets/img/universe3/28th March - Mesarthim.png'
import m3u29 from 'assets/img/universe3/29th March - Subra.png'
import m3u30 from 'assets/img/universe3/30th March - Sulafat.png'
import m3u31 from 'assets/img/universe3/31st March - Adhafera.png'

import m3n1 from 'assets/img/nature3/1st March - Watsonia.png'
import m3n2 from 'assets/img/nature3/2nd March - Coreopsis.png'
import m3n3 from 'assets/img/nature3/3rd March - Hosta.png'
import m3n4 from 'assets/img/nature3/4th March - Cotoneaster.png'
import m3n5 from 'assets/img/nature3/5th March - Orchid.png'
import m3n6 from 'assets/img/nature3/6th March - Creeping Phlox.png'
import m3n7 from 'assets/img/nature3/7th March - Crocosmia.png'
import m3n8 from 'assets/img/nature3/8th March - Rondeletia.png'
import m3n9 from 'assets/img/nature3/9th March - Crown Imperial.png'
import m3n10 from 'assets/img/nature3/10th March - Daphne.png'
import m3n11 from 'assets/img/nature3/11th March - Cyclamen.png'
import m3n12 from 'assets/img/nature3/12th March - Fuschia.png'
import m3n13 from 'assets/img/nature3/13th March - Coral Bells.png'
import m3n14 from 'assets/img/nature3/14th March - Lavatera.png'
import m3n15 from 'assets/img/nature3/15th March - Cosmos.png'
import m3n16 from 'assets/img/nature3/16th March - Day Lily.png'
import m3n17 from 'assets/img/nature3/17th March - Gladiolus.png'
import m3n18 from 'assets/img/nature3/18th March - Desert Rose.png'
import m3n19 from 'assets/img/nature3/19th March - Dichondra.png'
import m3n20 from 'assets/img/nature3/20th March - Geranium.png'
import m3n21 from 'assets/img/nature3/21th March - Diascia.png'
import m3n22 from 'assets/img/nature3/22th March - Bluebell.png'
import m3n23 from 'assets/img/nature3/23th March - Lobelia.png'
import m3n24 from 'assets/img/nature3/24th March - Dahlia.png'
import m3n25 from 'assets/img/nature3/25th March - Echinacea.png'
import m3n26 from 'assets/img/nature3/26th March - Echium.png'
import m3n27 from 'assets/img/nature3/27th March - Acacia.png'
import m3n28 from 'assets/img/nature3/28th March - Erica.png'
import m3n29 from 'assets/img/nature3/29th March - Rock Rose.png'
import m3n30 from 'assets/img/nature3/30th March - Flower Bed.png'
import m3n31 from 'assets/img/nature3/31st March - Gaillardia.png'

import m4h1 from 'assets/img/human4/1st April - Refined.png'
import m4h2 from 'assets/img/human4/2nd April - Honorable.png'
import m4h3 from 'assets/img/human4/3rd April - Sunny.png'
import m4h4 from 'assets/img/human4/4th April - Intelligent.png'
import m4h5 from 'assets/img/human4/5th April - Fluidity.png'
import m4h6 from 'assets/img/human4/6th April - Capacity.png'
import m4h7 from 'assets/img/human4/7th April - Courageous.png'
import m4h8 from 'assets/img/human4/8th April - Fortunate.png'
import m4h9 from 'assets/img/human4/9th April - Alliance.png'
import m4h10 from 'assets/img/human4/10th April - Mastery.png'
import m4h11 from 'assets/img/human4/11th April - Ability.png'
import m4h12 from 'assets/img/human4/12th April - Acquiescence.png'
import m4h13 from 'assets/img/human4/13th April - Wondrous.png'
import m4h14 from 'assets/img/human4/14th April - Affectionate.png'
import m4h15 from 'assets/img/human4/15th April - Upbeat.png'
import m4h16 from 'assets/img/human4/16th April - Perfection.png'
import m4h17 from 'assets/img/human4/17th April - Cheerful.png'
import m4h18 from 'assets/img/human4/18th April - Keen.png'
import m4h19 from 'assets/img/human4/19th April - Gaiety.png'
import m4h20 from 'assets/img/human4/20th April - Magnificent.png'
import m4h21 from 'assets/img/human4/21th April - Lover.png'
import m4h22 from 'assets/img/human4/22th April - Thorough.png'
import m4h23 from 'assets/img/human4/23th April - Sympathy.png'
import m4h24 from 'assets/img/human4/24th April - Friendly.png'
import m4h25 from 'assets/img/human4/25th April - Luscious.png'
import m4h26 from 'assets/img/human4/26th April - Dynamicity.png'
import m4h27 from 'assets/img/human4/27th April - Passionate.png'
import m4h28 from 'assets/img/human4/28th April - Flashiness.png'
import m4h29 from 'assets/img/human4/29th April - Jovial.png'
import m4h30 from 'assets/img/human4/30th April - Legend.png'

import m4u1 from 'assets/img/universe4/1st April - Sabik.png'
import m4u2 from 'assets/img/universe4/2nd April - Aludra.png'
import m4u3 from 'assets/img/universe4/3rd April - Celaeno.png'
import m4u4 from 'assets/img/universe4/4th April - Diphda.png'
import m4u5 from 'assets/img/universe4/5th April - Minelauva.png'
import m4u6 from 'assets/img/universe4/6th April - Cujam.png'
import m4u7 from 'assets/img/universe4/7th April - Algieba.png'
import m4u8 from 'assets/img/universe4/8th April - Spica.png'
import m4u9 from 'assets/img/universe4/9th April - Salm.png'
import m4u10 from 'assets/img/universe4/10th April - Butterfly.png'
import m4u11 from 'assets/img/universe4/11th April - Cartwheel.png'
import m4u12 from 'assets/img/universe4/12th April - Fang.png'
import m4u13 from 'assets/img/universe4/13th April - Alphecca.png'
import m4u14 from 'assets/img/universe4/14th April - Schedar.png'
import m4u15 from 'assets/img/universe4/15th April - Chara.png'
import m4u16 from 'assets/img/universe4/16th April - Segin.png'
import m4u17 from 'assets/img/universe4/17th April - Phecda.png'
import m4u18 from 'assets/img/universe4/18th April - Fawaris.png'
import m4u19 from 'assets/img/universe4/19th April - Large Magellanic Cloud.png'
import m4u20 from 'assets/img/universe4/20th April - Alruba.png'
import m4u21 from 'assets/img/universe4/21st April - Sadachbia.png'
import m4u22 from 'assets/img/universe4/22nd April - Miaplacidus.png'
import m4u23 from 'assets/img/universe4/23rd April - Gomeisa.png'
import m4u24 from 'assets/img/universe4/24th April - Syrma.png'
import m4u25 from 'assets/img/universe4/25th april - Skat.png'
import m4u26 from 'assets/img/universe4/26th April - Sualocin.png'
import m4u27 from 'assets/img/universe4/27th April - Tania Australis.png'
import m4u28 from 'assets/img/universe4/28th april - Zubenelgenubi.png'
import m4u29 from 'assets/img/universe4/29th April - Veritate.png'
import m4u30 from 'assets/img/universe4/30th April - Sadr.png'

import m4n1 from 'assets/img/nature4/1st April - Eustoma.png'
import m4n2 from 'assets/img/nature4/2nd April - Evening Primrose.png'
import m4n3 from 'assets/img/nature4/3rd April - French Marigold.png'
import m4n4 from 'assets/img/nature4/4th April - Euphorbia cyathophor.png'
import m4n5 from 'assets/img/nature4/5th April - Floss Flower.png'
import m4n6 from 'assets/img/nature4/6th April - Flax Flower.png'
import m4n7 from 'assets/img/nature4/7th April - Weigela.png'
import m4n8 from 'assets/img/nature4/8th April - Forget Me Not.png'
import m4n9 from 'assets/img/nature4/9th April - Rose of Sharon.png'
import m4n10 from 'assets/img/nature4/10th April - Foxglove.png'
import m4n11 from 'assets/img/nature4/11th April - Frangipani.png'
import m4n12 from 'assets/img/nature4/12th April - Alstroemeria aurea.png'
import m4n13 from 'assets/img/nature4/13th April - Honesty.png'
import m4n14 from 'assets/img/nature4/14th April - Everlasting Daisy.png'
import m4n15 from 'assets/img/nature4/15th April - Oyster.png'
import m4n16 from 'assets/img/nature4/16th April - Mandevilla.png'
import m4n17 from 'assets/img/nature4/17th April - Flannel Flower.png'
import m4n18 from 'assets/img/nature4/18th April - Maltese Cross.png'
import m4n19 from 'assets/img/nature4/19th April - Gerbera.png'
import m4n20 from 'assets/img/nature4/20th April - Dutch Iris.png'
import m4n21 from 'assets/img/nature4/21th April - Winter Jasmine.png'
import m4n22 from 'assets/img/nature4/22th April - Zenobia.png'
import m4n23 from 'assets/img/nature4/23th April - Jacob_s Ladder.png'
import m4n24 from 'assets/img/nature4/24th April - Jaborosa.png'
import m4n25 from 'assets/img/nature4/25th April - Gypsophila.png'
import m4n26 from 'assets/img/nature4/26th April - Jonquil.png'
import m4n27 from 'assets/img/nature4/27th April - Jasmine.png'
import m4n28 from 'assets/img/nature4/28th April - Jamesia americana.png'
import m4n29 from 'assets/img/nature4/29th april - Wishbone Flower.png'
import m4n30 from 'assets/img/nature4/30th April - Kalmia.png'


export const JanuaryHumanIntros = [
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Elegance',guideName: 'Elegance', day: 'January 1st', theme: ' Humans', src:jh1, power: 'Connect Indigo Humans to Greek Goddesses', des: 'Elegance is the quality of being graceful and stylish in appearance or manner; style. Or the quality of being pleasingly ingenious and simple; neatness.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Genial',guideName: 'Genial', day: 'January 2nd', theme: ' Humans', src:jh2, power: ' Connect Raimbow Humans to Norse Goddesses ', des: 'Genial mean friendly and cheerful, pleasant, kindly.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Euphoria',guideName: 'Euphoria', day: 'January 3rd', theme: ' Humans',src:jh3, power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Euphoria mean a feeling or state of intense excitement and Happiness. elation, joy, ecstasy, bliss.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Evocative',guideName: 'Evocative', day: 'January 4th', theme: ' Humans',src:jh4, power: 'Connect Crystal Humans to Norse Goddesses', des: 'Evocative mean bringing strong images, memories, or feelings to mind, having the power to evoke an especially emotional response.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Abundance', guideName: 'Abundance', day: 'January 5th', theme: ' Humans',src:jh5, power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Abundance mean a very large quantity of something, so also the state or condition of having copious good feelings; plentifulness.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Vital',guideName: 'Vital', day: 'January 6th', theme: ' Humans', src:jh6,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Vital mean absolutely necessary or important; essential. Or full of energy; lively.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Peace', guideName: 'Peace', day: 'January 7th', theme: ' Humans', src:jh7,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Peace mean freedom from disturbance; tranquility. Also a state or period in which there is no war or a war has ended.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Sanguine',guideName: 'Sanguine', day: 'January 8th', theme: ' Humans', src:jh8,power: 'Connect Crystal Humans to Japanese Goddesses ', des: 'Sanguine mean optimistic or positive, especially in an apparently bad or difficult situation.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Serendipity', namguideNamee: 'Serendipity',day: 'January 9th', theme: ' Humans', src:jh9,power: 'Connect Indigo Humans to Norse Goddesses ', des: 'Serendipity mean the faculty or phenomenon of finding valuable or agreeable things not sought for.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Energetic',guideName: 'Energetic', day: 'January 10th', theme: ' Humans', src:jh10,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Energetic mean showing or involving great activity or vitality, active with enthusiasm and excitement to spare.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Resilience',guideName: 'Resilience', day: 'January 11th', theme: ' Humans', src:jh11,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Resilience mean the ability of an ecosystem to return to its original state after being disturbed, so the ability to recover strength, spirits, good humor, etc. quickly; buoyancy.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Brave', guideName: 'Brave',day: 'January 12th', theme: ' Humans', src:jh12,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Brave meaning: ready to face and endure danger or pain; showing courage. Having or showing mental or moral strength to face danger, fear, or difficulty.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Felicity', guideName: 'Felicity',day: 'January 13th', theme: ' Humans', src:jh13,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Felicity mean happiness, luck, or a condition that produces positive results: the quality of being good, pleasant, or desirable.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Effervescent', guideName: 'Effervescent',day: 'January 14th', theme: ' Humans', src:jh14,power: 'Connect Raimbow Humans to Japanese Goddesses', des: 'Effervescent mean someone is lively, entertaining, enthusiastic, and exciting, liveliness.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Eloquence', guideName: 'Eloquence',day: 'January 15th', theme: ' Humans', src:jh15,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Eloquence mean fluent or persuasive speaking or writing. The quality of being moving with a powerful and effective language.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Mellifluous',  guideName: 'Mellifluous',day: 'January 16th', theme: ' Humans', src:jh16,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Mellifluous mean sweetly or smoothly flowing with honey; sweetened with or as if with honey. Voice or piece of music is smooth and gentle and very pleasant to listen to.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Authenticity',guideName: 'Authenticity', day: 'January 17th', theme: ' Humans', src:jh17,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Authenticity mean the quality on people or things of being genuine or real, someone true with the own personality, values, and spirit, regardless of the pressure to act otherwise.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Fantasy',guideName: 'Fantasy', day: 'January 18th', theme: ' Humans', src:jh18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Fantasy mean the faculty or activity of imagining things, especially things that are impossible or improbable. Originate in the Greek word, 'phantasia,' which literally means "to make visible."` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Vivacious',guideName: 'Vivacious', day: 'January 19th', theme: ' Humans', src:jh19,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Vivacious mean a person, especially a woman or girl, that is attractively energetic and enthusiastic, lively in temper, conduct, or spirit. Or a colorful mixed thing.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Soulful', guideName: 'Soulful', day: 'January 20th', theme: ' Humans', src:jh20,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Soulful mean full of or expressing feeling or emotion. Sensitive, eloquent, moving.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Willing',guideName: 'Willing', day: 'January 21th', theme: ' Humans', src:jh21,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Willing describe someone who does something fairly enthusiastically and because they want to do it rather than because they are forced to do it. So, If someone is willing to do something, they are fairly happy about doing it.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Wanderlust',guideName: 'Wanderlust', day: 'January 22th', theme: ' Humans', src:jh22,power: 'Connect Raimbow Humans to Norse Goddesses', des: 'Wonderlast describe the wish to travel far away and to many different places, strong longing for or impulse toward wandering.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Reliable',guideName: 'Reliable', day: 'January 23th', theme: ' Humans', src:jh23,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Reliable means consistently good in quality or performance; able to be trusted. A person or thing with trustworthy qualities.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Joy', guideName: 'Joy', day: 'January 24th', theme: ' Humans', src:jh24,power: 'Connect Crystal Humans to Greek Goddesses', des: 'Joy is a feeling of great happiness. Delight, pleasure, triumph, satisfaction.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Marvelous',guideName: 'Marvelous', day: 'January 25th', theme: ' Humans', src:jh25,power: 'Connect Indigo Humans to Norse Goddesses', des: 'If you describe someone or something as marvelous, you are emphasizing that they are very good.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Truthful',guideName: 'Truthful', day: 'January 26th', theme: ' Humans', src:jh26,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Truthful is used to telling or expressing the truth. Honest, sincere, trustworthy, genuine.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Generosity',guideName: 'Generosity', day: 'January 27th', theme: ' Humans', src:jh27,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Generosity is a willingness to give help or support. Goodness, hospitality, kindness, largesse, unselfishness, alms-giving, magnaminity.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Genuine', guideName: 'Genuine',day: 'January 28th', theme: ' Humans', src:jh28,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Genuine is used to describe people and things that are exactly what they appear to be, and are not false or an imitation.  Authentic, real, original, actual.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Adorable',guideName: 'Adorable',  day: 'January 29th', theme: ' Humans', src:jh29,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'If you say that someone or something is adorable, you are emphasizing that they are very attractive and you feel great affection for them. Lovable, pleasing, appealing, dear.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Hilarity',guideName: 'Hilarity', day: 'January 30th', theme: ' Humans', src:jh30,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Hilarity is great amusement and laughter. Merriment, high spirits, mirth, gaiety.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Virtuosity',guideName: 'Virtuosity', day: 'January 31th', theme: ' Humans', src:jh31,power: 'Connect Indigo Humans to Greek Goddesses', des: 'The precise meaning of the terms varies widely, although they generally encompass extraordinary skill, technical ability, and an element of display. Skillfulness, mastery, expertise, expertness, prowess, proficiency, ability, aptitude' },
]
export const JanuaryUniverseIntros = [
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Rigil Kent (Alpha Centauri)',guideName: 'Rigil Kent', day: 'January 1st', theme: 'Universe',src:ju1, power: 'Connect Indigo Humans to Greek Goddesses', des: 'Alpha Centauri (α Centauri, Alpha Cen, or α Cen) is a triple star system in the southern constellation of Centaurus. It consists of three stars: Rigil Kentaurus (Alpha Centauri A), Toliman (B) and Proxima Centauri (C). Proxima Centauri is the closest star to the Sun at 4.2465 light-years.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Acamar (Theta Eridani)',guideName: 'Acamar', day: 'January 2nd', theme: 'Universe',src:ju2, power: 'Connect Raimbow Humans to Norse Goddesses', des: `Theta Eridani, Latinized from θ Eridani, is a binary system in the constellation of Eridanus with a combined apparent magnitude of 2.88. Its two components are designated θ1 Eridani, formally named Acamar /ˈækəmɑːr/ (the traditional name of the system), and θ2 Eridani. The system's distance from the Sun based on parallax measurements is approximately 165 light-years` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Errai (Gamma Cephei)', guideName: 'Errai',day: 'January 3rd', theme: 'Universe',src:ju3, power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Gamma Cephei (γ Cephei, abbreviated Gamma Cep, γ Cep) is a binary star system approximately 45 light-years away in the constellation of Cepheus. The primary (designated Gamma Cephei A, officially named Errai /ɛˈreɪ.iː/, the traditional name of the system) is a stellar class K1 orange giant or subgiant star.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Lesath (Upsilon Scorpii)',guideName: 'Lesath', day: 'January 4th', theme: 'Universe',src:ju4, power: 'Connect Crystal Humans to Norse Goddesses', des: `Upsilon Scorpii (υ Scorpii, abbreviated Upsilon Sco, υ Sco), formally named Lesath /ˈliːsæθ/, is a star located in the "stinger" of the southern zodiac constellation of Scorpius, the scorpion. Based on parallax measurements obtained during the Hipparcos mission, it is approximately 580 light-years from the Sun. In the night sky it lies near the 1.6 magnitude star Lambda Scorpii, and the two form an optical pair that is sometimes called the "Cat's Eyes".` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Acrab (Beta Scorpii)', guideName: 'Acrab',day: 'January 5th', theme: 'Universe',src:ju5, power: 'Connect Indigo Humans to Japanese Goddesses', des: `The β Scorpii system is a kinematic member of the Upper Scorpius subgroup of the Scorpius–Centaurus association, a group of thousands of young stars with mean age 11 million years at distance 470 light years (145 parsecs). Analysis of β1 Scorpii as a single star derived an evolutionary age between 9 and 12 million years, but analysis of the β Scorpii system as a whole suggest an age closer to 6 million years.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Sargas (Girtab)', guideName: 'Sargas',day: 'January 6th', theme: 'Universe',src:ju6, power: 'Connect Raimbow Humans to Greek Goddesses', des: `Theta Scorpii (θ Scorpii, abbreviated Theta Sco, θ Sco) is a binary star in the southern zodiac constellation of Scorpius. The apparent visual magnitude of this star is +1.87, making it readily visible to the naked eye and one of the brightest stars in the night sky. The two components are designated θ Scorpii A (officially named Sargas /ˈsɑːrɡæs/, the traditional name for the system) and star B.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Sarin (Delta Herculis)', guideName: 'Sarin',day: 'January 7th', theme: 'Universe',src:ju7, power: 'Connect Diamond Humans to Norse Goddesses', des: 'Delta Herculis (δ Herculis, abbreviated Delta Her, δ Her with the star A named Sarin) is a multiple star system in the constellation of Hercules. Its light produces to us apparent magnitude 3.12, as such the third-brightest star in the large, fairly dim constellation. Based on parallax measurement taken during the Hipparcos mission, it is approximately 23.1 parsecs (75 light-years) from the Sun.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Sceptrum (Eridani)', guideName: 'Sceptrum',day: 'January 8th', theme: 'Universe',src:ju8, power: 'Connect Crystal Humans to Japanese Goddesses', des: '53 Eridani (abbreviated 53 Eri), also designated l Eridani (l Eri), is a binary star in the constellation of Eridanus. The system has a combined apparent magnitude of 3.87. Parallax estimates made by the Hipparcos spacecraft put it at a distance of about 110 light-years, or 33.7 parsecs, from the Sun. The two components are designated 53 Eridani A (officially named Sceptrum) and B.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Scheat (Beta Pegasi)', guideName: 'Scheat',day: 'January 9th', theme: 'Universe',src:ju9, power: 'Connect Indigo Humans to Norse Goddesses', des: 'Beta Pegasi (β Pegasi, abbreviated Beta Peg, β Peg), formally named Scheat /ˈʃiːæt/, is a red giant star and the second-brightest star (after Epsilon Pegasi) in the constellation of Pegasus. It forms the upper right corner of the Great Square of Pegasus, a prominent rectangular asterism.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Adhil (Xi Andromedae)', guideName: 'Adhil',day: 'January 10th', theme: 'Universe',src:ju10, power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Xi Andromedae (ξ Andromedae, abbreviated Xi And, ξ And), officially named Adhil /əˈdɪl/, is a solitary star in the northern constellation of Andromeda. It has an apparent magnitude of +4.9.[2] Based on parallax measurements obtained during the Gaia mission, it lies at a distance of roughly 223 light-years (68 parsecs) from the Sun.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Ain (Epsilon Tauri)', guideName: 'Ain',day: 'January 11th', theme: 'Universe',src:ju11, power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Epsilon Tauri or ε Tauri, formally named Ain (/ˈeɪn/), is an orange giant star located approximately 45 parsecs (147 light-years) from the Sun in the constellation of Taurus. An extrasolar planet (designated Epsilon Tauri b, later named Amateru) is believed to be orbiting the star.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Mirach (Beta Andromedae)', guideName: 'Mirach',day: 'January 12th', theme: 'Universe',src:ju12, power: 'Connect Crystal Humans to Norse Goddesses', des: `Beta Andromedae, Latinized from β Andromedae, and officially named Mirach /ˈmaɪræk/, is a prominent star in the northern constellation of Andromeda. It is northeast of the Great Square of Pegasus and is potentially visible to all observers north of latitude 54° S. It is commonly used by stargazers to find the Andromeda Galaxy. The galaxy NGC 404, also known as Mirach's Ghost, is seven arcminutes away from Mirach.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Miram (Eta Persei)', guideName: 'Miram',day: 'January 13th', theme: 'Universe',src:ju13, power: ' Connect Indigo Humans to Greek Goddesses', des: `Eta Persei (η Persei, abbreviated Eta Per, η Per), is a binary star and the 'A' component of a triple star system (the 'B' component is the star HD 237009) in the constellation of Perseus. It is approximately 1331 light-years away from Earth. The two components of Eta Persei itself are designated Eta Persei A (officially named Miram /ˈmaɪræm/, a recent name for the system) and star B` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Mirfak (Alpha Persei)', guideName: 'Mirfak',day: 'January 14th', theme: 'Universe',src:ju14, power: 'Connect Indigo Humans to Greek Goddesses', des: `Alpha Persei (Latinized from α Persei, abbreviated Alpha Per, α Per), formally named Mirfak (pronounced /ˈmɜːrfæk/ or /ˈmɪərfæk/), is the brightest star in the northern constellation of Perseus, outshining the constellation's best-known star, Algol (β Persei). Alpha Persei has an apparent visual magnitude of 1.8, and is a circumpolar star when viewed from mid-northern latitudes.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Mirzam (Canis Majoris)', dguideName: 'Mirzam',ay: 'January 15th', theme: 'Universe',src:ju15, power: 'Connect Diamond Humans to Greek Goddesses', des: `Beta Canis Majoris (β Canis Majoris, abbreviated Beta CMa, β CMa), also named Mirzam /ˈmɜːrzəm/, is a star in the southern constellation of Canis Major, the "Great Dog", located at a distance of about 500 light-years (150 parsecs) from the Sun. In the modern constellation it lies at the position of the dog's front leg.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Misam (Kappa Persei)', guideName: 'Misam',day: 'January 16th', theme: 'Universe',src:ju16, power: 'Connect Crystal Humans to Norse Goddesses', des: 'Kappa Persei or κ Persei, is a triple star system in the northern constellation of Perseus. Based upon an annual parallax shift of 28.93 mas, it is located at a distance of 113 light-years from the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Alchiba (Alpha Corvi)', guideName: 'Alchiba',day: 'January 17th', theme: 'Universe',src:ju17, power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Alpha Corvi (α Corvi, abbreviated Alpha Crv, α Crv), also named Alchiba /ˈælkɪbə/, is an F-type main-sequence star and the fifth-brightest star in the constellation of Corvus. Based on parallax measurements made during the Hipparcos mission, it is approximately 49 light-years from the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Asellus Australis (Delta Cancri)', guideName: 'Asellus',day: 'January 18th', theme: 'Universe',src:ju18, power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Delta Cancri (δ Cancri, abbreviated Delta Cnc, δ Cnc) is a double star about 180 light-years from the Sun in the constellation of Cancer. Its two main constituents are designated Delta Cancri A and B. A is itself a binary star whose components are Delta Cancri Aa (formally named Asellus Australis /əˈsɛləs ɔːsˈtreɪlɪs/, the traditional name of the entire system) and Ab.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Alcyone (Eta Tauri)', guideName: 'Alcyone',day: 'January 19th', theme: 'Universe', src:ju19,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Alcyone /ælˈsaɪəniː/, designated η Tauri (Eta Tauri, abbreviated Eta Tau, η Tau), is a star in the constellation of Taurus. Approximately 440 light-years from the Sun, it is the brightest star in the Pleiades open cluster, which is a young cluster, around 100 million years old. There are a number of fainter stars very close to Alcyone, some of which are members of the same cluster.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Aldebaran ( Alfa Tauri)', guideName: 'Aldebaran',day: 'January 20th', theme: 'Universe', src:ju20,power: 'Connect Crystal Humans to Japanese Goddesses', des: `ldebaran (Arabic: الدَّبَران, lit. 'The Follower') is a star located in the zodiac constellation of Taurus. It has the Bayer designation α Tauri, which is Latinized to Alpha Tauri and abbreviated Alpha Tau or α Tau. Aldebaran varies in brightness from an apparent visual magnitude 0.75 down to 0.95, making it the brightest star in the constellation, as well as (typically) the fourteenth-brightest star in the night sky. It is positioned at a distance of approximately 65 light-years from the Sun. ` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Wezen (Canis Majoris)', guideName: 'Wezen',day: 'January 21th', theme: 'Universe',src:ju21, power: 'Connect Indigo Humans to Greek Goddesses', des: 'Delta Canis Majoris (Latinised from δ Canis Majoris, abbreviated Delta CMa, δ CMa), officially named Wezen /ˈwiːzən/, is a star in the constellation of Canis Major. It is a yellow-white F-type supergiant with an apparent magnitude of +1.83. Since 1943, the spectrum of this star has served as one of the stable anchor points by which other stars are classified.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Wurren (Zeta Phoenicis)', guideName: 'Wurren',day: 'January 22th', theme: 'Universe', src:ju22,power: 'Connect Raimbow Humans to Norse Goddesses', des: 'AZeta Phoenicis (ζ Phoenicis, abbreviated Zet Phe, ζ Phe) is a multiple star system in the constellation of Phoenix. It is visible to the naked eye. Based upon parallax measurements made by the Hipparcos spacecraft, it is located some 300 light-years (92 parsecs) away.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Xamidimura (Mu1 Scorpii)', guideName: 'Xamidimura',day: 'January 23th', theme: 'Universe', src:ju23,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Mu1 Scorpii (μ1 Scorpii, abbreviated Mu1 Sco, μ1 Sco) is a binary star system in the southern zodiac constellation of Scorpius. The combined apparent visual magnitude of the pair is about magnitude 3, making it one of the brighter members of Scorpius. Based upon parallax measurements, the distance of this system from the Sun is roughly 500 light-years (150 parsecs). This system is a member of the Scorpius–Centaurus association, the nearest OB association of co-moving stars to the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Xuange (Lambda Boötis)', guideName: 'Xuange',day: 'January 24th', theme: 'Universe',src:ju24, power: 'Connect Crystal Humans to Greek Goddesses', des: 'Lambda Boötis (λ Boötis, abbreviated Lam Boo, λ Boo), also named Xuange (/ˈʃwɛnɡə/[citation needed]), is a star in the northern constellation of Boötes. Based on parallax measurements, it is approximately 99 light-years from the Sun.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Yed Posterior (Epsilon Ophiuchi)', guideName: 'Yed Posterior',day: 'January 25th', theme: 'Universe', src:ju25,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Epsilon Ophiuchi or ε Ophiuchi, formally named Yed Posterior (/ˌjɛd pɒˈstɪəriər/), is a red giant star in the constellation of Ophiuchus. Located less than five degrees south of the celestial equator in the eastern part of the constellation, it forms a naked eye optical double with Delta Ophiuchi (named Yed Prior. With an apparent visual magnitude of 3.220, the star can be seen with the naked eye from most of the Earth under suitably dark skies. Parallax measurements yield an estimated distance of 106.4 light-years (32.6 parsecs) from the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Algedi (Alpha Capricorni)', guideName: 'Algedi',day: 'January 26th', theme: 'Universe',src:ju26, power: 'Connect Indigo Humans to Greek Goddesses', des: `Alpha2 Capricorni (α2 Capricorni), or Algedi /ælˈdʒiːdi/, is a triple star system in the southern constellation of Capricornus. It is visible to the naked eye with an apparent visual magnitude of +3.57. It is separated from the fainter α¹ Capricorni by 0.11° of the sky, a gap just resolvable with the naked eye, similar to Mizar and Alcor. Based on parallax shift as refined from orbits around the Sun of the Gaia spacecraft at Earth's Lagrange point 2, the star is 101 to 103 light years from the Solar System.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Algenib (Gamma Pegasi)',guideName: 'Algenib', day: 'January 27th', theme: 'Universe', src:ju27,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Gamma Pegasi is a star in the constellation of Pegasus, located at the southeast corner of the asterism known as the Great Square. It has the formal name Algenib /ælˈdʒiːnɪb/; the Bayer designation Gamma Pegasi is Latinized from γ Pegasi and abbreviated Gamma Peg or γ Peg. The average apparent visual magnitude of +2.84[4] makes this the fourth-brightest star in the constellation. The distance to this star has been measured using the parallax technique, yielding a value of roughly 470 light-years (144 parsecs).' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Tania Borealis (Lambda UMa)', guideName: 'Tania Borealis',day: 'January 28th', theme: 'Universe', src:ju28,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Lambda Ursae Majoris (λ Ursae Majoris, abbreviated Lambda UMa, λ UMa), formally named Tania Borealis /ˈteɪniə ˌbɒriˈælɪs/, is a star in the northern circumpolar constellation of Ursa Major. This star has an apparent visual magnitude of +3.45,making it one of the brighter members of the constellation.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Tarazed (Gamma Aquilae)', guideName: 'Tarazed',day: 'January 29th', theme: 'Universe', src:ju29,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Gamma Aquilae, Latinized from γ Aquilae, and formally known as Tarazed /ˈtærəzɛd/, is a star in the constellation of Aquila. It has an apparent visual magnitude of 2.712,making it readily visible to the naked eye at night. Parallax measurements place it at a distance of 395 light-years (121 parsecs) from the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Algorab (Delta Corvi)', guideName: 'Algorab',day: 'January 30th', theme: 'Universe',src:ju30, power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Delta Corvi (δ Corvi, abbreviated Delta Crv, δ Crv), also named Algorab /ˈælɡəræb/, is a third magnitude star at a distance of 86.9 light-years (26.6 parsecs) from the Sun in the southern constellation of Corvus.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Dalim (Alpha Fornacis)', guideName: 'Dalim',day: 'January 31th', theme: 'Universe', src:ju31,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Alpha Fornacis (α Fornacis, abbreviated Alpha For, α For) is a binary star system in the southern constellation of Fornax. It is the brightest star in the constellation and the only one brighter than magnitude 4.0. Based on parallax measurements obtained during the Hipparcos mission, it is approximately 46 light-years (14 parsecs) distant from the Sun. Its two components are designated Alpha Fornacis A (officially named Dalim /ˈdeɪlɪm/) and B.' },
]
export const JanuaryNatureIntros = [
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Aconitum',  guideName: 'Aconitum',day: 'January 1st', theme: 'Nature', src:jn1,power: 'Connect Indigo Humans to Greek Goddesses', des: ' The genus Aconitum contains more than 350 species of flowering plants belonging to the family Ranunculaceae. It can be found throughout the world, and it has long been known as a poison. 44. Several species of Aconitum have been used as arrow poisons, for suicide and homicide because of its high toxicity.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'White Gazania',  guideName: 'White Gazania',day: 'January 2nd', theme: 'Nature', src:jn2,power: 'Connect Raimbow Humans to Norse Goddesses', des: `Gazania is also called a Treasure flower and it represents wealth and richness in a spiritual meaning in many cultures. Is a stunning compact groundcover that produces multiple blooms over a long flowering period. These tough as nail plants provide a colourful highlight anywhere they are planted and are well adapted to drought conditions.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'African Daisy',  guideName: 'African Daisy',day: 'January 3rd', theme: 'Nature', src:jn3,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Gazania is also called a Treasure flower and it represents wealth and richness in a spiritual meaning in many cultures. Is a stunning compact groundcover that produces multiple blooms over a long flowering period. These tough as nail plants provide a colourful highlight anywhere they are planted and are well adapted to drought conditions.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Platycodon Grandiflorus',  guideName: 'Platycodon Grandiflorus',day: 'January 4th', theme: 'Nature',src:jn4, power: 'Connect Crystal Humans to Norse Goddesses', des: `Platycodon grandiflorus (from Ancient Greek "wide" and "bell") is a species of herbaceous flowering perennial plant of the family Campanulaceae, and the only member of the genus Platycodon. It is native to East Asia (China, Korea, Japan, and the Russian Far East). It is commonly known as balloon flower (referring to the balloon-shaped flower buds), Chinese bellflower, or platycodon.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Winterberry',  guideName: 'Winterberry',day: 'January 5th', theme: 'Nature',src:jn5, power: 'Connect Indigo Humans to Japanese Goddesses', des: `Winterberry is a globular, upright, medium-sized shrub, typically 6-10 ft. tall (to 20 ft. in some circumstances). Extremely showy in late fall and early winter when covered with their bright red fruit, these shrubs are either male or female--a trait typical of the holly family.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Alchemilla',  guideName: 'Alchemilla',day: 'January 6th', theme: 'Nature',src:jn6, power: 'Connect Raimbow Humans to Greek Goddesses', des: `Alchemilla vulgaris is a herbaceous perennial found throughout Europe, especially on upland grassland and verges. Thin round green stems (up to 60 cm but usually less) bear bright green, palmately lobed leaves with toothed edges. There is a basal rosette and tufts of leaves encircle the stem at the apices.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Allium Roseum',  guideName: 'Allium Roseum',day: 'January 7th', theme: 'Nature', src:jn7,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Allium roseum, commonly called rosy garlic, is an edible, Old World species of wild garlic. It is native to the Mediterranean region and nearby areas, with a natural range extending from Portugal and Morocco to Turkey and the Palestine region. It is cultivated widely, and has become naturalised in scattered locations in other regions outside its natural range' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Freesia',  guideName: 'Freesia',day: 'January 8th', theme: 'Nature', src:jn8,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Native to South Africa, freesias are frost tender perennials that grow from corms. The large-flowered hybrids sold by florists feature sweetly fragrant, funnel-shaped flowers (5-10 per stem) that bloom in one-sided racemes atop leafless, arching, wiry stems to 18" tall.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Sorbus Aucuparia',  guideName: 'Sorbus Aucuparia',day: 'January 9th', theme: 'Nature', src:jn9,power: 'Connect Indigo Humans to Norse Goddesses', des: 'Sorbus aucuparia, commonly called rowan (/ˈroʊən/, also UK: /ˈraʊən/) and mountain-ash, is a species of deciduous tree or shrub in the rose family. It is a highly variable species, and botanists have used different definitions of the species to include or exclude trees native to certain areas. A recent definition includes trees native to most of Europe and parts of Asia, as well as northern Africa.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Astrantia',  guideName: 'Astrantia',day: 'January 10th', theme: 'Nature', src:jn10,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Astrantia is a genus of herbaceous plants in the family Apiaceae, native to Central, Eastern and Southern Europe and the Caucasus. There are several species, which have aromatic roots, palmate leaves, and decorative flowers. They are commonly known as great masterwort or masterwort which may also refer to other plants, particularly the unrelated Peucedanum ostruthium.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Pine',  guideName: 'Pine',day: 'January 11th', theme: 'Nature',src:jn11, power: 'Connect Raimbow Humans to Greek Goddesses', des: 'A pine is any conifer tree or shrub in the genus Pinus (/ˈpiːnuːs/) of the family Pinaceae. Pinus is the sole genus in the subfamily Pinoideae. World Flora Online, created by the Royal Botanic Gardens, Kew and Missouri Botanical Garden, accepts 187 species names of pines as current, together with more synonyms' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Amaryllis',  guideName: 'Amaryllis',day: 'January 12th', theme: 'Nature', src:jn12,power: 'Connect Crystal Humans to Norse Goddesses', des: `Amaryllis (/ˌæməˈrɪlɪs/) is the only genus in the subtribe Amaryllidinae (tribe Amaryllideae). It is a small genus of flowering bulbs, with two species. The better known of the two, Amaryllis belladonna, is a native of the Western Cape region of South Africa, particularly the rocky southwest area between the Olifants River Valley and Knysna.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Wood Anemone',  guideName: 'Wood Anemone',day: 'January 13th', theme: 'Nature', src:jn13,power: ' Connect Indigo Humans to Greek Goddesses', des: `Is an early-spring flowering plant in the buttercup family Ranunculaceae, native to Europe.. Wood anemone is one of the first spring blooms, arriving to take in the light through the leafless canopy in broadleaf woodland. Look for them in old and ancient woodland that suits their slow growth.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Corchorus Capsularis',  guideName: 'Corchorus Capsularis',day: 'January 14th', theme: 'Nature', src:jn14,power: 'Connect Indigo Humans to Greek Goddesses', des: `Corchorus capsularis (also known as patsun), commonly known as white jute, is a shrub species in the family Malvaceae. It is one of the sources of jute fibre, considered to be of finer quality than fibre from Corchorus olitorius, the main source of jute. The leaves are used as a foodstuff and the leaves, unripe fruit and the roots are used in traditional medicine.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Magnolia',  guideName: 'Magnolia',day: 'January 15th', theme: 'Nature', src:jn15,power: 'Connect Diamond Humans to Greek Goddesses', des: `Magnolia is a large genus of about 210 to 340[a] flowering plant species in the subfamily Magnolioideae of the family Magnoliaceae. The natural range of Magnolia species is disjunct, with a main center in east and southeast Asia and a secondary center in eastern North America, Central America, the West Indies, and some species in South America. Magnolia is an ancient genus that appeared before bees evolved. They are theorized to have evolved to encourage pollination by beetles instead.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Anthurium',  guideName: 'Anthurium',day: 'January 16th', theme: 'Nature', src:jn16,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Anthurium (/ænˈθjuːriəm/; Schott, 1829) is a genus of about 1,000 species of flowering plants, the largest genus of the arum family, Araceae. General common names include anthurium, tailflower, flamingo flower, and laceleaf. The genus is native to the Americas, where it is distributed from northern Mexico to northern Argentina and parts of the Caribbean.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Pansy',  guideName: 'Pansy',day: 'January 17th', theme: 'Nature', src:jn17,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'The garden pansy (Viola × wittrockiana) is a type of polychromatic large-flowered hybrid plant cultivated as a garden flower. It is derived by hybridization from several species in the section Melanium ("the pansies") of the genus Viola, particularly V. tricolor, a wildflower of Europe and western Asia known as heartsease. It is sometimes known as V. tricolor var. hortensis, but this scientific name is ambiguous.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Aquilegia',  guideName: 'Aquilegia',day: 'January 18th', theme: 'Nature', src:jn18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Aquilegia (common names: granny's bonnet, columbine) is a genus of about 60–70 species of perennial plants that are found in meadows, woodlands, and at higher altitudes throughout the Northern Hemisphere, known for the spurred petals. The genus name Aquilegia comes from the Latin "Aquila", or "eagle"; this is in obvious reference to the spurred, "hook" shapes within the blooms, that many gardeners say resemble an eagle's talons.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Xerophyllum',  guideName: 'Xerophyllum',day: 'January 19th', theme: 'Nature',src:jn19, power: 'Connect Diamond Humans to Norse Goddesses', des: `Xerophyllum is a genus of perennial plants from the family Melanthiaceae. The genus is native to North America. There are two species: Xerophyllum asphodeloides and Xerophyllum tenax. The elongated leaves of X. tenax, commonly known as bear grass, are used for basket weaving by the Native Americans. Xerophyllum asphodeloides, also known as turkey's beard, is a popular garden plant, producing spikes of white flowers.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Asclepias Syriaca',  guideName: 'Asclepias Syriaca',day: 'January 20th', theme: 'Nature', src:jn20,power: 'Connect Crystal Humans to Japanese Goddesses', des: `Asclepias syriaca, commonly called common milkweed, butterfly flower, silkweed, silky swallow-wort, and Virginia silkweed, is a species of flowering plant. It is native to southern Canada and much of the United States east of the Rocky Mountains, excluding the drier parts of the prairies. It is in the genus Asclepias, the milkweeds. It grows in sandy soils as well as other kinds of soils in sunny areas. ` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Aster Amellus',  guideName: 'Aster Amellus',day: 'January 21th', theme: 'Nature',src:jn21, power: 'Connect Indigo Humans to Greek Goddesses', des: 'The specific name amellus is first used in the Georgics (Book IV, 271–280), a poem of the Latin poet Publius Vergilius Maro (70 BCE – 19 BCE), but the etymology is obscure and uncertain. This plant is present on the European mountains from the Pyrenees and the Alps to the Carpathians. Outside Europe it is located in western Asia (Turkey), the Caucasus, Siberia, South Asia (Uttarakhand, India) and Central Asia (Kazakhstan).' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Guava',  guideName: 'Guava',day: 'January 22th', theme: 'Nature', src:jn22,power: 'Connect Raimbow Humans to Norse Goddesses', des: 'Guava (/ˈɡwɑːvə/ is a common tropical fruit cultivated in many tropical and subtropical regions. The common guava Psidium guajava (lemon guava, apple guava) is a small tree in the myrtle family (Myrtaceae), native to Mexico, Central America, the Caribbean and northern South America The name guava is also given to some other species in the genus Psidium such as strawberry guava (Psidium cattleyanum) and to the pineapple guava, Feijoa sellowiana.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Astilbes',  guideName: 'Astilbes',day: 'January 23th', theme: 'Nature',src:jn23, power: 'Connect Diamond Humans to Japanese Goddesses', des: `Astilbe /əˈstɪlbiː/ is a genus of 18 species of rhizomatous flowering plants within the family Saxifragaceae, native to mountain ravines and woodlands in Asia and North America. Some species are known by the common names false goat's beard and false spirea. These hardy herbaceous perennials are cultivated by gardeners for their large, handsome, often fern-like foliage and dense, feathery plumes of flowers. ` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Mauve',  guideName: 'Mauve',day: 'January 24th', theme: 'Nature',src:jn24, power: 'Connect Crystal Humans to Greek Goddesses', des: 'Alyssum is a genus of over a hundred species of flowering plants in the family Brassicaceae, native to Europe, Asia, and northern Africa, with the highest species diversity in the Mediterranean region. The genus comprises annual and perennial herbaceous plants or (rarely) small shrubs, growing to 10–100 cm tall, with oblong-oval leaves. Alyssum flowers are characteristically small and grouped in terminal clusters; they are often yellow or white colored but can be pink or purple.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Alyssum',  guideName: 'Alyssum',day: 'January 25th', theme: 'Nature', src:jn25,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Mauve (/ˈmoʊv/ ⓘ, mohv; /ˈmɔːv/ ⓘ, mawv) is a pale purple color named after the mallow flower (French: mauve). The first use of the word mauve as a color was in 1796–98 according to the Oxford English Dictionary, but its use seems to have been rare before 1859. Mauve contains more gray and more blue than a pale tint of magenta. Many pale wildflowers called "blue" are more accurately classified as mauve. Mauve is also sometimes described as pale violet.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Aubreita Deltoidea',  guideName: 'Aubreita Deltoidea',day: 'January 26th', theme: 'Nature',src:jn26, power: 'Connect Indigo Humans to Greek Goddesses', des: `Aubrieta deltoidea is a species of flowering plant in the mustard family. Common names include lilacbush, purple rock cress and rainbow rock cress. It should be grown in zones 4a to 9b. It is native to southeastern Europe, but is grown worldwide as an ornamental plant and it grows wild in some areas as a garden escapee. This is a small herbaceous perennial forming carpets of green spoon-shaped to oval-shaped leaves, some of which are lobed. The showy inflorescence bears small flowers with four lavender to deep pink petals. The fruit is an inflated, hairy silique up to two centimeters long.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Bee Balm Flower',  guideName: 'Bee Balm Flower',day: 'January 27th', theme: 'Nature',src:jn27, power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Bee balm is a Native American plant not only known for its attractive scarlet flowers that bloom in the summertime but also its fragrant foliage. Slender, tubular flowers are produced in 2- to 3-inch-wide flower heads in June and July.  Flower colors include white, pink, red, lavender, and purple. In the garden, its most frequent visitors are hummingbirds, bees, and  butterflies because they have the long tongues required to reach the tubular flowers’ nectar.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Lunaria',  guideName: 'Lunaria',day: 'January 28th', theme: 'Nature', src:jn28,power: 'Connect Indigo Humans to Japanese Goddesses', des: `Lunaria is a genus of flowering plants in the family Brassicaceae. It is native to Europe and has been introduced to North America and elsewhere. Species include:
  L. annua (syn. L. biennis), annual or biennial
  L. rediviva, perennial
  L. telekiana. rare Balkan species 
  The Latin name Lunaria means "moon-like" and refers to the plants' decorative seedpods. They have hairy toothed leaves and terminal racemes of white or violet flowers in Spring and Summer, followed by prominent, translucent, disc-shaped seedpods, which are frequently seen in flower arrangements.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Hophornbeam',  guideName: 'Hophornbeam',day: 'January 29th', theme: 'Nature', src:jn29,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Ostrya is a genus of eight to 10 small deciduous trees belonging to the birch family Betulaceae. Common names include hop-hornbeam and hophornbeam. It may also be called ironwood, a name shared with a number of other plants. The genus is native in southern Europe, southwest and eastern Asia, and North and Central America. They have a conical or irregular crown and a scaly, rough bark.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Gypsophila',  guideName: 'Gypsophila',day: 'January 30th', theme: 'Nature', src:jn30,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Gypsophila (/dʒɪpˈsɒfɪlə/) is a genus of flowering plants in the carnation family, Caryophyllaceae. They are native to Eurasia, Africa, Australia, and the Pacific Islands. Turkey has a particularly high diversity of Gypsophila taxa, with about 35 endemic species. The genus name is from the Greek gypsos ("gypsum") and philios ("loving"), a reference to the gypsum-rich substrates on which some species grow. Plants of the genus are known commonly as baby's-breath, or bebe's breath, a name which also refers specifically to the well known ornamental species Gypsophila paniculata.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Campanula Latifolia',  guideName: 'Campanula Latifolia',day: 'January 31th', theme: 'Nature', src:jn31,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Campanula latifolia, the giant bellflower, is a species of bellflower in the family Campanulaceae. It is also known as the large campanula and the wide-leaved bellflower. It is native to Europe and western Asia and is widely grown as an ornamental plant. Campanula latifolia is native to Europe and western Asia as far east as Kashmir. Its natural habitat is broad-leaved woodland, coppices, parkland and forest margins. Some occurrences are as a result of escape from cultivation.' },
]

export const FebruaryHumanIntros = [
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Beautiful',guideName: 'Beautiful', day: 'February 1st', theme: ' Humans', src:m2h1, power: 'Connect Indigo Humans to Greek Goddesses', des: 'Having beauty; possessing qualities that give great pleasure or satisfaction to see, hear, think about, etc.; delighting the senses or mind' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Safe',guideName: 'Safe', day: 'February 2nd', theme: ' Humans', src:m2h2, power: ' Connect Raimbow Humans to Norse Goddesses ', des: 'Secure from liability to harm, injury, danger, or risk: free from hurt.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Bliss',guideName: 'Bliss', day: 'February 3rd', theme: ' Humans',src:m2h3, power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Supreme happiness; utter joy or contentment. In theology. the joy of heaven.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Charming',guideName: 'Charming', day: 'February 4th', theme: ' Humans',src:m2h4, power: 'Connect Crystal Humans to Norse Goddesses', des: 'A quality that makes you like or feel attracted to someone or something:p leasing; delightful, using charm; exercising magic power.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Thrilling', guideName: 'Thrilling', day: 'February 5th', theme: ' Humans',src:m2h5, power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Producing sudden, strong, and deep emotion or excitement. producing a tremor, as by chilling.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Confidence',guideName: 'Confidence', day: 'February 6th', theme: ' Humans', src:m2h6,power: 'Connect Raimbow Humans to Greek Goddesses', des: `full trust; belief in the powers, trustworthiness, or reliability of a person or thing: We have every confidence in their ability to succeed. "Belief in oneself and one's owers or abilities; self-confidence; self-reliance; assurance."` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Phenomenal', guideName: 'Phenomenal', day: 'February 7th', theme: ' Humans', src:m2h7,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Highly extraordinary or prodigious; exceptional.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Tranquillity',guideName: 'Tranquillity', day: 'February 8th', theme: ' Humans', src:m2h8,power: 'Connect Crystal Humans to Japanese Goddesses ', des: 'The quality or state of being tranquil; calmness; peacefulness; quiet; serenity: a peaceful, calm state, without noise, violence, worry.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Awesome', namguideNamee: 'Awesome',day: 'February 9th', theme: ' Humans', src:m2h9,power: 'Connect Indigo Humans to Norse Goddesses ', des: 'Causing or inducing awe; inspiring an overwhelming feeling of reverence, admiration, or fear: an awesome sight.exhibiting or marked by awe.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Angelic',guideName: 'Angelic', day: 'February 10th', theme: ' Humans', src:m2h10,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Of or belonging to angels: the angelic host. like or befitting an angel, especially in virtue, beauty: very beautiful and very good.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Discipline',guideName: 'Discipline', day: 'February 11th', theme: ' Humans', src:m2h11,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Training to act in accordance with rules; drill: raining that makes people more willing to obey or more able to control themselves, in accordance with rules; drill. An activity, exercise, or regimen that develops or mproves a skill.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Elegant', guideName: 'Elegant',day: 'February 12th', theme: ' Humans', src:m2h12,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Tastefully fine or luxurious in dress, style, design. Gracefully refined and dignified, as in tastes, habits, or literary style. Graceful and attractive in appearance or behaviour:' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Sparkly', guideName: 'Sparkly',day: 'February 13th', theme: ' Humans', src:m2h13,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Shining brightly with a lot of small points of light: tending to sparkle; animated; lively. Of a drink: naturally carbonated; effervescent.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Love', guideName: 'Love',day: 'February 14th', theme: ' Humans', src:m2h14,power: 'Connect Raimbow Humans to Japanese Goddesses', des: 'A strong feeling of warm personal attachment or deep affection, such as for a parent, child, friend, or pet. A profoundly tender, passionate affection, attraction based on sexual desire: affection and tenderness felt by lovers. The fatherly concern of God for humankind.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Secure', guideName: 'Secure',day: 'February 15th', theme: ' Humans', src:m2h15,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Free from or not exposed to danger or harm; safe. Dependable; firm; not liable to fail, yield, become displaced, etc., as a support or a fastening. Positioned or fixed firmly and correctly and therefore not likely to move, fall, or break.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Opulence',  guideName: 'Opulence',day: 'February 16th', theme: ' Humans', src:m2h16,power: 'Connect Crystal Humans to Norse Goddesses', des: 'The quality of being expensive and luxurious: wealth, riches, or affluence. Abundance, as of resources or goods; plenty.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Happy',guideName: 'Happy', day: 'February 17th', theme: ' Humans', src:m2h17,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Feeling, showing, or causing pleasure or satisfaction: delighted, pleased, or glad, as over a particular thing. Characterized by or indicative of pleasure, contentment, or joy.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Ecstatic',guideName: 'Ecstatic', day: 'February 18th', theme: ' Humans', src:m2h18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Extremely happy, of, relating to, or characterized by ecstasy or a state of sudden, intense, overpowering emotion: an ecstatic frenzy. Subject to or in a state of ecstasy; full of joy; rapturous. A person subject to fits of ecstasy.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Bountiful',guideName: 'Bountiful', day: 'February 19th', theme: ' Humans', src:m2h19,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Large in amount, generous in giving to others: liberal in bestowing gifts, favors, or munificent: abundant; ample.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Accuracy', guideName: 'Accuracy', day: 'February 20th', theme: ' Humans', src:m2h20,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'The fact of being exact or correct: the condition or quality of being true, correct, or exact; freedom from error or defect; precision or exactness; correctness. Chemistry, Physics. the extent to which a given measurement agrees with the standard value for that measurement.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Gallant',guideName: 'Gallant', day: 'February 21th', theme: ' Humans', src:m2h21,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Brave, spirited, noble-minded, or chivalrous: a gallant knight; a gallant rescue attempt. Exceptionally polite and attentive to others, especially to women; courtly. A brave, noble-minded, or chivalrous man, exceptionally attentive to women, especially when in public.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Certainty',guideName: 'Certainty', day: 'February 22th', theme: ' Humans', src:m2h22,power: 'Connect Raimbow Humans to Norse Goddesses', des: 'The state of being completely confident or having no doubt about something. Something that cannot be doubted: the state of being certain. Something certain; an assured fact.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Thriving',guideName: 'Thriving', day: 'February 23th', theme: ' Humans', src:m2h23,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Growing, developing vigorously, or being highly successful: prospering or doing well, flourishing.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Heavenly', guideName: 'Heavenly', day: 'February 24th', theme: ' Humans', src:m2h24,power: 'Connect Crystal Humans to Greek Goddesses', des: 'Of or in the heavens: of, belonging to, or coming from the heaven of God, the angels, etc.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Virtuous',guideName: 'Virtuous', day: 'February 25th', theme: ' Humans', src:m2h25,power: 'Connect Indigo Humans to Norse Goddesses', des: 'Conforming to moral and ethical principles; morally excellent; upright. Having good moral qualities and behaviour.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Security',guideName: 'Security', day: 'February 26th', theme: ' Humans', src:m2h26,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Freedom from care, danger, risk, anxiety or doubt; well-founded confidence. safety. Protection of a person, building, organization, or country against threats such as crime or attacks by foreign countries.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Lucky',guideName: 'Lucky', day: 'February 27th', theme: ' Humans', src:m2h27,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Having good things happen to you by chance. Having or marked by good luck; fortunate: happening fortunately.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Pleasant', guideName: 'Pleasant',day: 'February 28th', theme: ' Humans', src:m2h28,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Enjoyable, attractive, friendly, or easy to like. Pleasing, agreeable, or enjoyable; giving pleasure. Of a person: socially acceptable or adept; polite; amiable; agreeable.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Bonanza',guideName: 'Bonanza',  day: 'February 29th', theme: ' Humans', src:m2h29,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'A situation from which large profits are made. a rich mass of ore, as found in mining. A source of great and sudden wealth or luck; a spectacular windfall.' },
]
export const FebruaryUniverseIntros = [
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Saclateni', guideName: 'Saclateni', day: 'February 1st', theme: 'Universe',src:m2u1, power: 'Connect Indigo Humans to Greek Goddesses', des: 'Zeta Aurigae, or ζ Aurigae, is a binary star system in the northern constellation of Auriga. Based upon parallax measurements made during the Hipparcos mission, this system is approximately 790 light-years (240 parsecs) distant from the sun. It has a combined apparent visual magnitude of 3.75, which is bright enough to be seen with the naked eye.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Musica', guideName: 'Musica', day: 'February 2nd', theme: 'Universe',src:m2u2, power: 'Connect Raimbow Humans to Norse Goddesses', des: `18 Delphini, also named Musica /ˈmjuːzɪkə/, is a single star in the constellation of Delphinus of the low northern hemisphere. It has a Sun-like golden hue and is faintly visible to the naked eye with an apparent visual magnitude of 5.506. The star is located at a distance of approximately 249 light years from the Sun based on parallax,[1] and it is drifting further away with a radial velocity of +4 km/s. An object believed to be an extrasolar planet (designated 18 Delphini b or Arion) orbits the star.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Homam', guideName: 'Homam',day: 'February 3rd', theme: 'Universe',src:m2u3, power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Zeta Pegasi or ζ Pegasi, formally named Homam (/ˈhoʊmæm/), is a single star in the northern constellation of Pegasus. With an apparent visual magnitude of +3.4, this star is bright enough to be seen with the naked eye and is one of the brighter members of Pegasus. Parallax measurements place it at a distance of around 204 light-years (62.7 parsecs) from the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Beid', guideName: 'Beid', day: 'February 4th', theme: 'Universe',src:m2u4, power: 'Connect Crystal Humans to Norse Goddesses', des: `Omicron1 Eridani (ο1 Eridani, abbreviated Omicron1 Eri, ο1 Eri), also named Beid /ˈbaɪd/, is a variable star in the constellation of Eridanus. With an average apparent visual magnitude of 4.04,it is visible to the naked eye on a clear, dark night. Based upon parallax measurements, it lies approximately 122 light-years from the Sun.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Bellatrix', guideName: 'Bellatrix',day: 'February 5th', theme: 'Universe',src:m2u5, power: 'Connect Indigo Humans to Japanese Goddesses', des: `Bellatrix is the third-brightest star in the constellation of Orion, positioned 5° west of the red supergiant Betelgeuse (Alpha Orionis). It has the Bayer designation γ Orionis, which is Latinized to Gamma Orionis. With a slightly variable magnitude of around 1.6, it is typically the 25th-brightest star in the night sky. Located at a distance of 250±10 light-years from the Sun, it is a blue giant star around 7.7 times as massive as the sun with 5.75 times its diameter.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Alphard', guideName: 'Alphard',day: 'February 6th', theme: 'Universe',src:m2u6, power: 'Connect Raimbow Humans to Greek Goddesses', des: `Alphard /ˈælfɑːrd/, designated Alpha Hydrae (α Hydrae, abbreviated Alpha Hya, α Hya), is the brightest star in the constellation of Hydra. It is a single giant star, cooler than the Sun but larger and more luminous. It is about 177 light-years away.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Tegmine', guideName: 'Tegmine',day: 'February 7th', theme: 'Universe',src:m2u7, power: 'Connect Diamond Humans to Norse Goddesses', des: 'Zeta Cancri (ζ Cancri, abbreviated Zeta Cnc, ζ Cnc) is a multiple star system in the constellation of Cancer. It is approximately 83.4 light-years from Earth, and has a combined apparent magnitude of +4.67. Since it is near the ecliptic, it can be occulted by the Moon and, very rarely, by planets.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Pipirima', guideName: 'Pipirima',day: 'February 8th', theme: 'Universe',src:m2u8, power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Mu2 Scorpii (μ2 Scorpii, abbreviated Mu2 Sco, μ2 Sco), also named Pipirima /pɪˈpɪrɪmə/, is a star in the zodiac constellation of Scorpius. It has an apparent visual magnitude of +3.56, which is bright enough to be seen with the naked eye. Its distance from the Sun is about 474 light-years, as determined by parallax measurements It is a member of the Upper Centaurus–Lupus subgroup of the Scorpius–Centaurus association.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Electra', guideName: 'Electra',day: 'February 9th', theme: 'Universe',src:m2u9, power: 'Connect Indigo Humans to Norse Goddesses', des: 'Electra /əˈlɛktrə/, designated 17 Tauri, is a blue-white giant star in the constellation of Taurus, about 400 light years away. It is the third-brightest star in the Pleiades open star cluster (M45), visible to the naked eye with an apparent magnitude of 3.7. Like the other bright stars of the Pleiades, it is named for one of the Seven Sisters of Greek mythology.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Castor', guideName: 'Castor',day: 'February 10th', theme: 'Universe',src:m2u10, power: 'Connect Raimbow Humans to Greek Goddesses', des: `Castor is the second-brightest object in the zodiac constellation of Gemini. It has the Bayer designation α Geminorum, which is Latinised to Alpha Geminorum and abbreviated Alpha Gem or α Gem. With an apparent visual magnitude of 1.93, it is one of the brightest stars in the night sky. Castor appears singular to the naked eye, but it is actually a sextuple star system organized into three binary pairs. Although it is the 'α' (alpha) member of the constellation, it is fainter than 'β' (beta) Geminorum, Pollux.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Bunda', guideName: 'Bunda',day: 'February 11th', theme: 'Universe',src:m2u11, power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Xi Aquarii (ξ Aquarii, abbreviated Xi Aqr, ξ Aqr) is a binary star system in the equatorial constellation of Aquarius. It is visible to the naked eye with an apparent visual magnitude of 4.7. Based upon parallax measurements made during the Hipparcos mission, this system lies at a distance of around 179 light-years (55 parsecs) from the Sun. The two components are designated Xi Aquarii A (also named Bunda and B.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Canopus', guideName: 'Canopus',day: 'February 12th', theme: 'Universe',src:m2u12, power: 'Connect Crystal Humans to Norse Goddesses', des: `Canopus is the brightest star in the southern constellation of Carina and the second-brightest star in the night sky. It is also designated α Carinae, which is romanized (transliterated) to Alpha Carinae. With a visual apparent magnitude of −0.74, it is outshone only by Sirius.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Capella', guideName: 'Capella',day: 'February 13th', theme: 'Universe',src:m2u13, power: ' Connect Indigo Humans to Greek Goddesses', des: `Capella is the brightest star in the northern constellation of Auriga. It has the Bayer designation α Aurigae, which is Latinised to Alpha Aurigae and abbreviated Alpha Aur or α Aur. Capella is the sixth-brightest star in the night sky, and the third-brightest in the northern celestial hemisphere after Arcturus and Vega. A prominent object in the northern winter sky, it is circumpolar to observers north of 44°N. Its name meaning "little goat" in Latin, Capella depicted the goat Amalthea that suckled Zeus in classical mythology. ` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Merga', guideName: 'Merga',day: 'February 14th', theme: 'Universe',src:m2u14, power: 'Connect Indigo Humans to Greek Goddesses', des: `38 Boötis is a single star in the northern constellation of Boötes, located approximately 157 light years from the Sun. It has the traditional name Merga /ˈmɜːrɡə/ and the Bayer designation h Boötis; 38 Boötis is the star's Flamsteed designation. This object is visible to the naked eye as a dim, yellow-white hued star with an apparent visual magnitude of 5.76. It is moving closer to the Earth with a heliocentric radial velocity of −4.5 km/s.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Copernicus', guideName: 'Copernicus',day: 'February 15th', theme: 'Universe',src:m2u15, power: 'Connect Diamond Humans to Greek Goddesses', des: `55 Cancri is a binary star system located light-years away from the Sun in the zodiac constellation of Cancer. It has the Bayer designation Rho1 Cancri (ρ1 Cancri); 55 Cancri is the Flamsteed designation (abbreviated 55 Cnc). The system consists of a K-type star (designated 55 Cancri A, also named Copernicus /koʊˈpɜːrnɪkəs/) and a smaller red dwarf (55 Cancri B). As of 2015, five extrasolar planets (designated 55 Cancri b, c, d, e and f; named Galileo, Brahe, Lipperhey, Janssen and Harriot, respectively) are known to orbit 55 Cancri A.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Proxima Centauri', guideName: 'Proxima Centauri',day: 'February 16th', theme: 'Universe',src:m2u16, power: 'Connect Crystal Humans to Norse Goddesses', des: `Proxima Centauri is a small, low-mass star located 4.2465 light-years (1.3020 pc) away from the Sun in the southern constellation of Centaurus. Its Latin name means the 'nearest [star] of Centaurus'. It was discovered in 1915 by Robert Innes and is the nearest-known star to the Sun. With a quiescent apparent magnitude of 11.13, it is too faint to be seen with the unaided eye. Proxima Centauri is a member of the Alpha Centauri star system, being identified as component Alpha Centauri C, and is 2.18° to the southwest of the Alpha Centauri AB pair. It is currently 12,950 AU (0.2 ly) from AB, which it orbits with a period of about 550,000 years.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Sirius', guideName: 'Sirius',day: 'February 17th', theme: 'Universe',src:m2u17, power: 'Connect Indigo Humans to Japanese Goddesses', des: `Sirius is the brightest star in the night sky. Its name is derived from the Greek word Σείριος (Latin script: Seirios), meaning lit. 'glowing' or 'scorching'. The star is designated α Canis Majoris, Latinized to Alpha Canis Majoris, and abbreviated α CMa or Alpha CMa. With a visual apparent magnitude of −1.46, Sirius is almost twice as bright as Canopus, the next brightest star. Sirius is a binary star consisting of a main-sequence star of spectral type A0 or A1, termed Sirius A, and a faint white dwarf companion of spectral type DA2, termed Sirius B. The distance between the two varies between 8.2 and 31.5 astronomical units as they orbit every 50 years.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Ascella', guideName: 'Ascella',day: 'February 18th', theme: 'Universe',src:m2u18, power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Zeta Sagittarii (ζ Sagittarii, abbreviated Zeta Sgr, ζ Sgr) is a triple star system and the third-brightest star in the constellation of Sagittarius after Kaus Australis and Nunki. Based upon parallax measurements, it is about 88 light-years (27 parsecs) from the Sun. The three components are designated Zeta Sagittarii A (officially named Ascella /əˈsɛlə/, the traditional name for the entire system) and B, themselves forming a binary pair, and a smaller companion star, C.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Taygeta', guideName: 'Taygeta',day: 'February 19th', theme: 'Universe', src:m2u19,power: 'Connect Diamond Humans to Norse Goddesses', des: `19 Tauri is a double star in the constellation of Taurus and a member of the Pleiades open star cluster (M45). It consists of a binary pair designated 19 Tauri A together with a single star visual companion, 19 Tauri B. 'A's' two components are themselves designated 19 Tauri Aa (officially named Taygeta /teɪˈɪdʒətə/, the traditional name for the entire system) and Ab. Based on parallax measurements obtained during the Hipparcos mission, 19 Tauri A is approximately 440 light-years from the Sun.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Tarf', guideName: 'Tarf',day: 'February 20th', theme: 'Universe', src:m2u20,power: 'Connect Crystal Humans to Japanese Goddesses', des: `Beta Cancri (β Cancri, abbreviated Beta Cnc, β Cnc), also named Tarf /ˈtɑːrf/,  is the brightest star in the zodiacal constellation of Cancer. It has an apparent visual magnitude of +3.5 and an absolute magnitude of −1.2.[6] Based on parallax measurements obtained during the Hipparcos mission, it is approximately 290 light-years distant from the Sun. An exoplanet, designated Beta Cancri b, is believed to be orbiting the star. Beta Cancri has a companion listed and together they are designated WDS J08165+0911. As the primary, Beta Cancri bears the designation WDS J08165+0911A. The companion is designated WDS J08165+0911B.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Chamukuy', guideName: 'Chamukuy',day: 'February 21th', theme: 'Universe',src:m2u21, power: 'Connect Indigo Humans to Greek Goddesses', des: 'θ Tauri is composed of two 3rd magnitude stars, designated Theta1 Tauri (Theta Tauri B) and Theta2 Tauri (Theta Tauri A). Theta² is brighter, hence the pair are sometimes referred to as Theta Tauri B and A, respectively. They are separated by 5.62 arcminutes (0.094°) on the sky. Based upon parallax measurements, Theta¹ Tauri is located at a distance of 152 ly (47 pc), while Theta² Tauri is at a distance of 157 light-years (48 parsecs). θ Tauri A and B are both spectroscopic binaries; the four components are designated Theta Tauri Aa (formally named Chamukuy.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Zosma', guideName: 'Zosma',day: 'February 22th', theme: 'Universe', src:m2u22,power: 'Connect Raimbow Humans to Norse Goddesses', des: 'Delta Leonis (δ Leonis, abbreviated Delta Leo, δ Leo), also named Zosma /ˈzɒzmə/, is a star in the zodiac constellation of Leo. Based upon parallax measurements, it lies at a distance of about 58.4 light-years (17.9 parsecs) from the Sun. δ Leonis is a main-sequence star with a stellar classification of A4 V, meaning it is larger and hotter than the Sun. It is a fairly well-studied star, allowing relatively accurate measurements of its age and size. ' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Vindemiatrix', guideName: 'Vindemiatrix',day: 'February 23th', theme: 'Universe', src:m2u23,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Epsilon Virginis (ε Virginis, abbreviated Epsilon Vir, ε Vir), formally named Vindemiatrix /vɪndiːmiˈeɪtrɪks/, is a star in the zodiac constellation of Virgo. The apparent visual magnitude of this star is +2.8, making it the third-brightest member of Virgo. Based upon parallax measurements made during the Hipparcos mission, Vindemiatrix lies at a distance of about 109.6 light-years (33.6 parsecs) from the Sun, give or take a half light-year.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Maia', guideName: 'Maia',day: 'February 24th', theme: 'Universe', src:m2u24,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Maia /ˈmeɪə/, designated 20 Tauri (abbreviated 20 Tau), is a star in the constellation of Taurus. It is a blue giant of spectral type B8 III, a chemically peculiar star, and the prototype of the Maia variable class of variable star. Maia is the fourth-brightest star in the Pleiades open star cluster (Messier 45), after Alcyone, Atlas and Electra. It is surrounded by one of the brighter reflection nebulae within the Pleiades, designated NGC 1432 and sometimes called the Maia Nebula.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Izar', guideName: 'Izar',day: 'February 25th', theme: 'Universe',src:m2u25, power: 'Connect Crystal Humans to Greek Goddesses', des: 'Epsilon Boötis (ε Boötis, abbreviated Epsilon Boo, ε Boo), officially named Izar (/ˈaɪzɑːr/ EYE-zar), is a binary star in the northern constellation of Boötes. The star system can be viewed with the unaided eye at night, but resolving the pair with a small telescope is challenging; an aperture of 76 mm (3.0 in) or greater is required.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Situla', guideName: 'Situla',day: 'February 26th', theme: 'Universe',src:m2u26, power: 'Connect Indigo Humans to Greek Goddesses', des: `Kappa Aquarii (κ Aquarii, abbreviated Kappa Aqr, κ Aqr) is a probable binary star in the equatorial constellation of Aquarius. This system is visible to the naked eye, but it is faint at an apparent magnitude of 5.03. Based upon parallax measurements made during the Hipparcos mission, it is around 214 light-years (66 parsecs) from the Sun. The two components are designated Kappa Aquarii A (formally named Situla /ˈsɪtjuːlə/, the traditional name for the system) and B.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Praecipua', guideName: 'Praecipua',day: 'February 27th', theme: 'Universe', src:m2u27,power: 'Connect Crystal Humans to Japanese Goddesses', des: '46 Leonis Minoris (abbreviated 46 LMi), also named Praecipua /prɪˈsɪpjʊə/, is the brightest star in the constellation of Leo Minor. It is of spectral class K0+III-IV and of magnitude 3.83. It is a red clump giant. Based upon parallax measurements, its distance from the Sun is approximately 95 light-years. It is a suspected variable with an amplitude of about 0.05 magnitudes.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Dabih', guideName: 'Dabih',day: 'February 28th', theme: 'Universe', src:m2u28,power: 'Connect Indigo Humans to Japanese Goddesses', des: `Beta Capricorni (β Capricorni, abbreviated Beta Cap, β Cap) is a multiple star system in the constellation of Capricornus and located 328 light-years from the Sun. Because it is near the ecliptic, Beta Capricorni can be occulted by the Moon, and also (rarely) by planets. The system is believed to consist of five stars. With binoculars or a small telescope, Beta Capricorni can be resolved into a binary pair. The brighter of the two is designated Beta1 Capricorni or Beta Capricorni A; the dimmer, Beta2 Capricorni or Beta Capricorni B. Both are themselves made up of multiple stars.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Shaula', guideName: 'Shaula',day: 'February 29th', theme: 'Universe', src:m2u29,power: 'Connect Indigo Humans to Japanese Goddesses', des: `Lambda Scorpii is a triple star system and the second-brightest object in the constellation of Scorpius. It is formally named Shaula; Lambda Scorpii is its Bayer designation, which is Latinised from λ Scorpii and abbreviated Lambda Sco or λ Sco. With an apparent visual magnitude of 1.62, it is one of the brightest stars in the night sky.` },
]
export const FebruaryNatureIntros = [
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Begonia Dragon Wing',  guideName: 'Begonia Dragon Wing',day: 'February 1st', theme: 'Nature', src:m2n1,power: 'Connect Indigo Humans to Greek Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Campanula Alpestris',  guideName: 'Campanula Alpestris',day: 'February 2nd', theme: 'Nature', src:m2n2,power: 'Connect Raimbow Humans to Norse Goddesses', des: `` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Cardinal Flower',  guideName: 'Cardinal Flower',day: 'February 3rd', theme: 'Nature', src:m2n3,power: 'Connect Diamond Humans to Japanese Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Black Eyed Susan',  guideName: 'Black Eyed Susan',day: 'February 4th', theme: 'Nature',src:m2n4, power: 'Connect Crystal Humans to Norse Goddesses', des: `` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Bergenia Cordifolia',  guideName: 'Bergenia Cordifolia',day: 'February 5th', theme: 'Nature',src:m2n5, power: 'Connect Indigo Humans to Japanese Goddesses', des: `` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Bleeding Heart',  guideName: 'Bleeding Heart',day: 'February 6th', theme: 'Nature',src:m2n6, power: 'Connect Raimbow Humans to Greek Goddesses', des: `` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Linaria',  guideName: 'Linaria',day: 'February 7th', theme: 'Nature', src:m2n7,power: 'Connect Diamond Humans to Norse Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Primula',  guideName: 'Primula',day: 'February 8th', theme: 'Nature', src:m2n8,power: 'Connect Crystal Humans to Japanese Goddesses', des: '' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: `Cock's Comb Celosia `,  guideName: `Cock's Comb Celosia `,day: 'February 9th', theme: 'Nature', src:m2n9,power: 'Connect Indigo Humans to Norse Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Bouvardia',  guideName: 'Bouvardia',day: 'February 10th', theme: 'Nature', src:m2n10,power: 'Connect Raimbow Humans to Greek Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Buddleja',  guideName: 'Buddleja',day: 'February 11th', theme: 'Nature',src:m2n11, power: 'Connect Raimbow Humans to Greek Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Bush Morning Glory',  guideName: 'Bush Morning Glory',day: 'February 12th', theme: 'Nature', src:m2n12,power: 'Connect Crystal Humans to Norse Goddesses', des: `` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Ranunculus Buttercup',  guideName: 'Ranunculus Buttercup',day: 'February 13th', theme: 'Nature', src:m2n13,power: ' Connect Indigo Humans to Greek Goddesses', des: `` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Calendula Officinalis',  guideName: 'Calendula Officinalis',day: 'February 14th', theme: 'Nature', src:m2n14,power: 'Connect Indigo Humans to Greek Goddesses', des: `` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Cape Primrose',  guideName: 'Cape Primrose',day: 'February 15th', theme: 'Nature', src:m2n15,power: 'Connect Diamond Humans to Greek Goddesses', des: `` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Calla Lily',  guideName: 'Calla Lily',day: 'February 16th', theme: 'Nature', src:m2n16,power: 'Connect Crystal Humans to Norse Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Campanula Latifolia',  guideName: 'Campanula Latifolia',day: 'February 17th', theme: 'Nature', src:m2n17,power: 'Connect Indigo Humans to Japanese Goddesses', des: '' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Candytuft',  guideName: 'Candytuft',day: 'February 18th', theme: 'Nature', src:m2n18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Canna Lily',  guideName: 'Canna Lily',day: 'February 19th', theme: 'Nature',src:m2n19, power: 'Connect Diamond Humans to Norse Goddesses', des: `` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Blazing Star',  guideName: 'Blazing Star',day: 'February 20th', theme: 'Nature', src:m2n20,power: 'Connect Crystal Humans to Japanese Goddesses', des: `` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'California Poppy',  guideName: 'California Poppy',day: 'February 21th', theme: 'Nature',src:m2n21, power: 'Connect Indigo Humans to Greek Goddesses', des: '' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Carnation',  guideName: 'Carnation',day: 'February 22th', theme: 'Nature', src:m2n22,power: 'Connect Raimbow Humans to Norse Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Celosia',  guideName: 'Celosia',day: 'February 23th', theme: 'Nature',src:m2n23, power: 'Connect Diamond Humans to Japanese Goddesses', des: `` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Chrysanthemum',  guideName: 'Chrysanthemum',day: 'February 24th', theme: 'Nature',src:m2n24, power: 'Connect Crystal Humans to Greek Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Clarkia',  guideName: 'Clarkia',day: 'February 25th', theme: 'Nature', src:m2n25,power: 'Connect Raimbow Humans to Greek Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Clematis',  guideName: 'Clematis',day: 'February 26th', theme: 'Nature',src:m2n26, power: 'Connect Indigo Humans to Greek Goddesses', des: `` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Four Leaf Clover',  guideName: 'Four Leaf Clover',day: 'February 27th', theme: 'Nature',src:m2n27, power: 'Connect Crystal Humans to Japanese Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Blue Star Flower',  guideName: 'Blue Star Flower',day: 'February 28th', theme: 'Nature', src:m2n28,power: 'Connect Indigo Humans to Japanese Goddesses', des: `` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Dianella',  guideName: 'Dianella',day: 'February 29th', theme: 'Nature', src:m2n29,power: 'Connect Raimbow Humans to Greek Goddesses', des: '' },
]

export const MarchHumanIntros = [
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Prosperity',guideName: 'Prosperity', day: 'March 1st', theme: ' Humans', src:m3h1, power: 'Connect Indigo Humans to Greek Goddesses', des: 'A successful, flourishing, or thriving condition, especially in financial respects; good fortune. Prosperities, prosperous circumstances, characterized by financial success or good fortune.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Intellectual',guideName: 'Intellectual', day: 'March 2nd', theme: ' Humans', src:m3h2, power: ' Connect Raimbow Humans to Norse Goddesses ', des: 'Appealing to or engaging the intellect. Of or relating to the intellect or its use. A person who places a high value on or pursues things of interest to the intellect or the more complex forms and fields of knowledge, as aesthetic or philosophical matters, especially on an abstract and general level.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Shiny',guideName: 'Shiny', day: 'March 3rd', theme: ' Humans', src:m3h3, power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Bright or glossy in appearance. Filled with light, as by sunshine: brilliant, shining, gleaming, glistening.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Graceful',guideName: 'Graceful', day: 'March 4th', theme: ' Humans', src:m3h4, power: 'Connect Crystal Humans to Norse Goddesses', des: 'Characterized by elegance or beauty of form, manner, movement, or speech; elegant: a graceful dancer; a graceful reply. Limber, lithe, lissome.' },
  { sex: 'blue ', deesse: 'The Calendar of Love',name: 'Fantastic',guideName: 'Fantastic', day: 'March 5th', theme: ' Humans', src:m3h5, power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Extraordinarily good; excellent: singular, unbelievable, incredible.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Affability',guideName: 'Affability', day: 'March 6th', theme: ' Humans', src:m3h6,power: 'Connect Raimbow Humans to Greek Goddesses', des: `The quality of being pleasantly easy to approach and talk to; friendliness or warm politeness: Her affability and good nature endear her to all those acquainted with her.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Glamorous',guideName: 'Glamorous', day: 'March 7th', theme: ' Humans', src:m3h7,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Full of glamour; charmingly or fascinatingly attractive, especially in a mysterious or magical way. Full of excitement, adventure, and unusual activity' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Impressive',guideName: 'Impressive', day: 'March 8th', theme: ' Humans', src:m3h8,power: 'Connect Crystal Humans to Japanese Goddesses ', des: 'Having the ability to impress the mind; arousing admiration, awe, respect, etc.; moving; admirable: an impressive ceremony; an impressive appearance.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Enthusiastic',guideName: 'Enthusiastic', day: 'March 9th', theme: ' Humans', src:m3h9,power: 'Connect Indigo Humans to Norse Goddesses ', des: 'Full of or characterized by enthusiasm; ardent: He seems very enthusiastic about his role in the play.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Stupendous',guideName: 'Stupendous', day: 'March 10th', theme: ' Humans', src:m3h10,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Causing amazement; astounding; marvelous: stupendous news. Amazingly large or great; immense: colossal, vast, gigantic, prodigious.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Harmony',guideName: 'Harmony', day: 'March 11th', theme: ' Humans', src:m3h11,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Agreement; accord; harmonious relations. A consistent, orderly, or pleasing arrangement of parts; congruity.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Terrific',guideName: 'Terrific', day: 'March 12th', theme: ' Humans', src:m3h12,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Extraordinarily great or intense, extremely good; wonderful.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Gracious',guideName: 'Gracious', day: 'March 13th', theme: ' Humans', src:m3h13,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Pleasantly kind, benevolent, and courteous. Characterized by good taste, comfort, ease, or luxury.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Jubilant',guideName: 'Jubilant', day: 'March 14th', theme: ' Humans', src:m3h14,power: 'Connect Raimbow Humans to Japanese Goddesses', des: 'Feeling or showing great joy, satisfaction, or triumph; rejoicing; exultant.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Exquisite',guideName: 'Exquisite', day: 'March 15th', theme: ' Humans', src:m3h15,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Of special beauty or charm, or rare and appealing excellence, as a face, a flower, coloring, music, or poetry. Extraordinarily fine or admirable.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Lively',guideName: 'Lively', day: 'March 16th', theme: ' Humans', src:m3h16,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Full or suggestive of life or vital energy; active, vigorous, or brisk. Animated, spirited, vivacious, or sprightly.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Superb',guideName: 'Superb', day: 'March 17th', theme: ' Humans', src:m3h17,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Admirably fine or excellent; extremely good. Sumptuous; rich; grand.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Attractive',guideName: 'Attractive', day: 'March 18th', theme: ' Humans', src:m3h18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Providing pleasure or delight, especially in appearance or manner; pleasing; charming; alluring. Arousing interest or engaging one's thought, consideration, etc` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Jauntiness',guideName: 'Jauntiness', day: 'March 19th', theme: ' Humans', src:m3h19,power: 'Connect Diamond Humans to Norse Goddesses', des: '' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Rejoice',guideName: 'Rejoice', day: 'March 20th', theme: ' Humans', src:m3h20,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Bearing, conduct, or speech indicative of self-respect or appreciation of the formality or gravity of an occasion or situation. Nobility or elevation of character; worthiness.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Genius',guideName: 'Genius', day: 'March 21th', theme: ' Humans', src:m3h21,power: 'Connect Indigo Humans to Greek Goddesses', des: 'To be glad; take delight (often followed by in). To make joyful; gladden.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Delicacy',guideName: 'Delicacy', day: 'March 22th', theme: ' Humans', src:m3h22,power: 'Connect Raimbow Humans to Norse Goddesses', des: 'Causing a radical and typically positive change in outlook, character, form, or condition.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Compassion',guideName: 'Compassion', day: 'March 23th', theme: ' Humans', src:m3h23,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'A feeling of deep sympathy and sorrow for another who is stricken by misfortune, accompanied by a strong desire to alleviate the suffering.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Proud',guideName: 'Proud', day: 'March 24th', theme: ' Humans', src:m3h24,power: 'Connect Crystal Humans to Greek Goddesses', des: `Feeling pleasure or satisfaction over something regarded as highly honorable or creditable to oneself (often followed by of, an infinitive, or a clause). Having, proceeding from, or showing a high opinion of one's own dignity, importance, or superiority.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Diplomacy',guideName: 'Diplomacy', day: 'March 25th', theme: ' Humans', src:m3h25,power: 'Connect Indigo Humans to Norse Goddesses', des: 'The art or science of conducting such negotiations.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Plentiful',guideName: 'Plentiful', day: 'March 26th', theme: ' Humans', src:m3h26,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Existing in great plenty. Yielding abundantly:' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Dignity',guideName: 'Dignity', day: 'March 27th', theme: ' Humans', src:m3h27,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Bearing, conduct, or speech indicative of self-respect or appreciation of the formality or gravity of an occasion or situation. Nobility or elevation of character; worthiness.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Reverence',guideName: 'Reverence', day: 'March 28th', theme: ' Humans', src:m3h28,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'A feeling or attitude of deep respect tinged with awe; veneration. The outward manifestation of this feeling.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Transformative',guideName: 'Transformative', day: 'March 29th', theme: ' Humans', src:m3h29,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Causing a radical and typically positive change in outlook, character, form, or condition.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Liberty',guideName: 'Liberty', day: 'March 30th', theme: ' Humans', src:m3h30,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Freedom from arbitrary or despotic government or control. Freedom from external or foreign rule; independence.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Loquacity',guideName: 'Loquacity', day: 'March 31th', theme: ' Humans', src:m3h31,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'The state of being loquacity talkativeness; garrulity. An instance of talkativeness or garrulity; a loquacious  flow of talk.' },
]
export const MarchUniverseIntros = [
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Larawag',guideName: 'Larawag', day: 'March 1st', theme: 'Humans', src:m3u1, power: 'Connect Indigo Humans to Greek Goddesses', des: 'Epsilon Scorpii (ε Scorpii, abbreviated Eps Sco, ε Sco), formally named Larawag  is a star  in the southern zodiac constellation of scorpius. It has an apparent visual magnitude  of +2.3, making it the fifth-brightest member of the constellation. Parallax measurements made during the Hipparcos mission provide an estimated distance to this star of around 63.7 light-years (19.5 parsecs) from the sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'La Superba',guideName: 'La Superba', day: 'March 2nd', theme: 'Humans', src:m3u2, power: ' Connect Raimbow Humans to Norse Goddesses ', des: 'La Superba (Y CVn, Y Canum Venaticorum) is a strikingly red giant star in constellation canes venatici. It is a carbon star and semi regular variable. ' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Kurhah',guideName: 'Kurhah', day: 'March 3rd', theme: ' Humans', src:m3u3, power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Xi Cephei (ξ Cephei, abbreviated Xi Cep, ξ Cep) is a multiple star in the constellation of orpheus. It is approximately 86 light years from earth. ' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Sadachbia',guideName: 'Sadachbia', day: 'March 4th', theme: ' Humans', src:m3u4, power: 'Connect Crystal Humans to Norse Goddesses', des: 'Sadachbia (Gamma Aquarii, or γ Aquarii), is a suspected binary star system in the constellation  of Aquarius. It has an apparent visual magnitude of 3.849, making it one of the brighter members of the constellation. Based upon parallax measurements taken during the Hipparcos  mission, this star is located at a distance of approximately 164 light-year (50 parsecs) from the sun. It is drifting closer to the Sun with a radial velocity of −16 km/s. In 1998, Olin J. Eggen included this star as a candidate member of the Hyades Supercluster. ' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Libertas',guideName: 'Libertas', day: 'March 5th', theme: ' Humans', src:m3u5, power: 'Connect Indigo Humans to Japanese Goddesses', des: `Libertas (Latin for 'liberty' or 'freedom') is the Roman goddess and personification of liberty. She became a politicised figure in the Late Republic, featured on coins supporting the populares faction, and later those of the assassins of Julius Caesar. Nonetheless, she sometimes appears on coins from the imperial period, such as Galba's "Freedom of the People" coins during his short reign after the death of Nero. She is usually portrayed with two accoutrements: the spear, which she holds out on the spear, rather than wears on her head.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Lich',guideName: 'Lich', day: 'March 6th', theme: ' Humans', src:m3u6,power: 'Connect Raimbow Humans to Greek Goddesses', des: `PSR B1257+12, previously designated PSR 1257+12, alternatively designated PSR J1300+1240, is a millisecond pulsar located 2,300 light-years (710 parsecs) from the Sun in the constellation of Virgo, rotating at about 161 times per second (faster than a blender's blade). It is also named Lich, after a powerful, fictional undead creature of the same name.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Lilii Borea',guideName: 'Lilii Borea', day: 'March 7th', theme: ' Humans', src:m3u7,power: 'Connect Diamond Humans to Norse Goddesses', des: '39 Arietis (abbreviated 39 Ari), officially named Lilii Borea, is a star  in the northern constellation  of Aries. It is visible to the naked eye with an apparent visual magnitudes  of +4.5. The distance to this star, as determined from an annual parallax  shift of 19.01 mas, is approximately 172 light-years  (53 parsecs). This star was formerly located in the obsolete constellation Musca Borealis.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Maasym',guideName: 'Maasym', day: 'March 8th', theme: ' Humans', src:m3u8,power: 'Connect Crystal Humans to Japanese Goddesses ', des: 'Lambda Herculis (λ Herculis. abbreviated Lambda Her, λ Her), formally named Maasym, is a star  in the constellation  of Hercules. From parallax  measurements taken during the Gaia  mission, it is approximately 393 light-years  from the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Vega',guideName: 'Vega', day: 'March 9th', theme: ' Humans', src:m3u9,power: 'Connect Indigo Humans to Norse Goddesses ', des: `Vega is the brightest star  in the northern constellation  of Lyra. It has the Bayer designation  α Lyrae, which is Latinised  to Alpha Lyrae and abbreviated Alpha Lyr or α Lyr. This star is relatively close  at only 25 light-years  (7.7 parsecs) from the Sun, and one of the most luminous stars in the Sun's neighborhood. It is the fifth -brightest star  in the night sky, and the second-brightest star in the northern celestial hemisphere, after Arcturus.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Alrescha',guideName: 'Alrescha', day: 'March 10th', theme: ' Humans', src:m3u10,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Alpha Piscium (α Piscium) is a binary star system in the equatorial  constellation  of Pisces. Based upon parallel  measurements made by the Hipparcos  spacecraft, it is about 151 light-years  from the Solar System.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Denebola',guideName: 'Denebola', day: 'March 11th', theme: ' Humans', src:m3u11,power: 'Connect Diamond Humans to Japanese Goddesses', des: `Denebola is the second-brightest individual star in the zodiac constellation of Leo. It is the easternmost of the bright stars of Leo. It has the Bayer designation Beta Leonis or β Leonis, which are abbreviated Beta Leo or β Leo. Denebola is an A-type main sequence star with 75% more mass than the Sun and 15 times the Sun's luminosity. Based on parallax measurements from the Hipparcos astrometry satellite, the star is at a distance of 36 light-years (11 parsecs) from the Sun. Its apparent visual magnitude is 2.14, making it readily visible to the naked eye. Denebola is a Delta Scuti type variable star, meaning its luminosity varies very slightly over a period of a few hours.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Markab',guideName: 'Markab', day: 'March 12th', theme: ' Humans', src:m3u12,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Alpha Pegasi (α Pegasi, abbreviated Alpha Peg, α Peg), formally named Markab /ˈmɑːrkæb/, is the third-brightest star in the constellation of Pegasus and one of the four stars in the asterism known as the Great Square of Pegasus. Alpha Pegasi has a stellar classification of A0 IV, indicating that it is an A-type subgiant star that has exhausted the hydrogen at its core and has evolved beyond the main sequence.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Secunda Giedi',guideName: 'Secunda Giedi', day: 'March 13th', theme: ' Humans', src:m3u13,power: 'Connect Indigo Humans to Greek Goddesses', des: `Alpha2 Capricorni (α2 Capricorni), or Algedi /ælˈdʒiːdi/, is a triple star system in the southern constellation of Capricornus. It is visible to the naked eye with an apparent visual magnitude of +3.57. It is separated from the fainter α¹ Capricorni by 0.11° of the sky, a gap just resolvable with the naked eye, similar to Mizar and Alcor. Based on parallax shift as refined from orbits around the Sun of the Gaia spacecraft at Earth's Lagrange point 2, the star is 101 to 103 light years from the Solar System.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Matar',guideName: 'Matar', day: 'March 14th', theme: ' Humans', src:m3u14,power: 'Connect Raimbow Humans to Japanese Goddesses', des: 'Eta Pegasi or η Pegasi, formally named Matar /ˈmeɪtɑːr/, is a binary star in the constellation of Pegasus. The apparent visual magnitude of this star is +2.95, making it the fifth-brightest member of Pegasus. Based upon parallax measurements, the distance to this star is about 214 light-years (66 parsecs) from the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Mebsuta',guideName: 'Mebsuta', day: 'March 15th', theme: ' Humans', src:m3u15,power: 'Connect Diamond Humans to Greek Goddesses', des: `Epsilon Geminorum or ε Geminorum, formally named Mebsuta /mɛbˈsuːtə/, is a star in the constellation of Gemini, on the outstretched right 'leg' of the twin Castor. The apparent visual magnitude of +3.06 makes it one of the brighter stars in this constellation. The distance to this star can be determined by parallax measurements, giving a value of 840 light-years (260 parsecs), with a margin of error of 40 ly (12 pc).` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Megrez',guideName: 'Megrez', day: 'March 16th', theme: ' Humans', src:m3u16,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Megrez /ˈmiːɡrɛz/, also called Delta Ursae Majoris (δ Ursae Majoris, abbreviated Delta UMa, δ UMa), is a star in the northern constellation of Ursa Major. With an apparent magnitude of +3.3,it is the dimmest of the seven stars in the Big Dipper asterism. Parallax measurements yield a distance estimate of 80.5 light-years (24.7 parsecs) from the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Meissa',guideName: 'Meissa', day: 'March 17th', theme: ' Humans', src:m3u17,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Meissa /ˈmaɪsə/, designated Lambda Orionis (λ Orionis, abbreviated Lambda Ori, λ Ori) is a star in the constellation of Orion. It is a multiple star approximately 1,300 ly away with a combined apparent magnitude of 3.33. The main components are an O8 giant star and a B-class main sequence star, separated by about 4″. Despite Meissa being more luminous and only slightly further away than Rigel, it appears 3 magnitudes dimmer at visual wavelengths, with much of its radiation emitted in the ultraviolet due to its high temperature.  ' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Mekbuda',guideName: 'Mekbuda', day: 'March 18th', theme: ' Humans', src:m3u18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Zeta Geminorum (ζ Geminorum, abbreviated Zeta Gem, ζ Gem) is a bright star with cluster components, distant optical components and a likely spectroscopic partner in the zodiac constellation of Gemini — in its south, on the left 'leg' of the twin Pollux. It is a classical Cepheid variable star, of which over 800 have been found in our galaxy.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Meleph',guideName: 'Meleph', day: 'March 19th', theme: ' Humans', src:m3u19,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Epsilon Cancri (ε Cancri, abbreviated Epsilon Cnc, ε Cnc) is a white-hued binary star system in the zodiac constellation of Cancer. It is the brightest member of the Beehive Cluster with an apparent visual magnitude of +6.29, which is near the lower limit of visibility with the naked eye. The annual parallax shift of 5.4 mas as seen from Earth yields a distance estimate of approximately 606 light-years from the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Ancha',guideName: 'Ancha', day: 'March 20th', theme: ' Humans', src:m3u20,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Theta Aquarii (θ Aquarii, abbreviated Theta Aqr, θ Aqr), officially named Ancha /ˈæŋkə/(distinguish Ankaa, with the same pronunciation), is a star in the equatorial constellation of Aquarius. Visible to the naked eye at apparent magnitude 4.175, it is located at a distance of around 187 light-years (57 parsecs) from the Sun. Since it is near the ecliptic it can be occulted by the Moon, or very rarely by planets.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Menkar',guideName: 'Menkar', day: 'March 21th', theme: ' Humans', src:m3u21,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Alpha Ceti (α Ceti, abbreviated Alpha Cet, α Cet), officially named Menkar /ˈmɛŋkɑːr/ is the second-brightest star in the constellation of Cetus. It is a cool luminous red giant estimated to be about 250 light years away based on parallax. In Chinese, 天囷 (Tiān Qūn), meaning Circular Celestial Granary, refers to an asterism consisting of α Ceti.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Cursa',guideName: 'Cursa', day: 'March 22th', theme: ' Humans', src:m3u22,power: 'Connect Raimbow Humans to Norse Goddesses', des: 'Beta Eridani (β Eridani, abbreviated Beta Eri, β Eri), formally named Cursa /ˈkɜːrsə/, is the second-brightest star in the constellation of Eridanus, located in the northeast end of this constellation near the shared border with Orion. The apparent visual magnitude of this star is 2.796, so it can be viewed with the naked eye in dark skies. Parallax measurements yield an estimated distance of about 89 light-years (27 parsecs) from the Earth.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Ogma',guideName: 'Ogma', day: 'March 23th', theme: ' Humans', src:m3u23,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'HD 149026, also named Ogma /ˈɒɡmə/, is a yellow subgiant star approximately 250 light-years from the Sun in the constellation of Hercules. An extrasolar planet (designated HD 149026 b, later named Smertrios) is believed to orbit the star.  In December 2015, the IAU announced the winning names were Ogma for this star and Smertrios for its planet.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Castula',guideName: 'Castula', day: 'March 24th', theme: ' Humans', src:m3u24,power: 'Connect Crystal Humans to Greek Goddesses', des: 'Upsilon2 Cassiopeiae, Latinised from υ2 Cassiopeiae, is a solitary star in the constellation of Cassiopeia, a few degrees to the south of Gamma Cassiopeiae. It has the proper name Castula /ˈkæstjʊlə/, which has been officially adopted by the IAU. The star has a yellow hue and is faintly visible to the naked eye with an apparent visual magnitude of +4.62. Distance of approximately 189 light years from the Sun. The star is drifting closer with a radial velocity of −48 km/s.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Chertan',guideName: 'Chertan', day: 'March 25th', theme: ' Humans', src:m3u25,power: 'Connect Indigo Humans to Norse Goddesses', des: 'Theta Leonis, Latinized from θ Leonis, formally named Chertan, is a star in the constellation of Leo. With an apparent visual magnitude of +3.324 it is visible to the naked eye and forms one of the brighter stars in the constellation. The distance from the Sun can be directly determined from parallax measurements, yielding a value of about 165 light-years (51 parsecs).' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Meridiana',guideName: 'Meridiana', day: 'March 26th', theme: ' Humans', src:m3u26,power: 'Connect Diamond Humans to Greek Goddesses', des: `Alpha Coronae Australis or α Coronae Australis, officially named Meridiana (/məˌrɪdiˈænə/), is the brightest star in the constellation of Corona Australis and is located about 125 light-years from Earth. It is the only star in the constellation with a traditional proper name, Alphekka Meridiana (Latin for 'Alphekka South'), after Alphecca, the brightest star in the constellation Corona Borealis.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Merope',guideName: 'Merope', day: 'March 27th', theme: ' Humans', src:m3u27,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Merope /ˈmɛrəpiː/, designated 23 Tauri (abbreviated 23 Tau), is a star in the constellation of Taurus and a member of the Pleiades star cluster. It is approximately 440 light-years (135 pc) away. Merope is a blue-white B-type subgiant with a mean apparent magnitude of +4.18. Richard Hinckley Allen described the star as lucid white and violet. It has a luminosity of 927 times that of the Sun and a surface temperature of 14,550 K' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Mesarthim',guideName: 'Mesarthim', day: 'March 28th', theme: ' Humans', src:m3u28,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Gamma Arietis (γ Arietis, abbreviated Gamma Ari, γ Ari) is a binary star (possibly trinary) in the northern constellation of Aries. The two components are designated γ1 Arietis or Gamma Arietis B and γ2 Arietis or Gamma Arietis A (formally named Mesarthim /mɛˈsɑːrθɪm/, the traditional name for the Gamma Arietis system). γ1 Arietis may itself be a spectroscopic binary with a low mass companion.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Subra',guideName: 'Subra', day: 'March 29th', theme: ' Humans', src:m3u29,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Omicron Leonis (ο Leonis, abbreviated Omicron Leo, ο Leo) is a multiple star system in the constellation of Leo, west of Regulus, some 130 light-years from the Sun, where it marks one of the lion's forepaws. It consists of a binary pair, designated Omicron Leonis A and an optical companion, Omicron Leonis B. A's two components are themselves designated Omicron Leonis Aa (officially named Subra /ˈsuːbrə/, the traditional name for the system) and Ab.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Sulafat',guideName: 'Sulafat', day: 'March 30th', theme: ' Humans', src:m3u30,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Gamma Lyrae, Latinised from γ Lyrae, and formally named Sulafat /ˈsuːləfæt/, is the second-brightest star in the northern constellation of Lyra. With an apparent visual magnitude of 3.3, it is readily visible to the naked eye. Parallax measurements yield an estimated distance of 620 light-years (190 parsecs) from the Sun. At that distance, the visual magnitude of the star is diminished by an extinction factor of 0.12±0.03 due to interstellar dust.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Adhafera',guideName: 'Adhafera', day: 'March 31th', theme: ' Humans', src:m3u31,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Zeta Leonis (ζ Leonis, abbreviated Zeta Leo, ζ Leo), also named Adhafera /ædəˈfɪərə/, is a third-magnitude star in the constellation of Leo, the lion. It forms the second star (after Gamma Leonis) in the blade of the sickle, which is an asterism formed from the head of Leo. Adhafera is a giant star with a stellar classification of F0 III. Since 1943, the spectrum of this star has served as one of the stable anchor points by which other stars are classified' },
]
export const MarchNatureIntros = [
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Watsonia',guideName: 'Watsonia', day: 'March 1st', theme: ' Humans', src:m3n1,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Watsonia (bugle lily) is a genus of plants in the family Iridaceae, subfamily Crocoideae. Watsonias are native to southern Africa (South Africa, Lesotho, Eswatini). The genus is named after Sir William Watson, an 18th-century British botanist.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Coreopsis',guideName: 'Coreopsis', day: 'March 2nd', theme: ' Humans', src:m3n2,power: 'Connect Raimbow Humans to Norse Goddesses ', des: `Cotoneaster /kəˈtoʊniːˈæstər/ is a genus of flowering plants in the rose family, Rosaceae, native to the Palaearctic region (temperate Asia, Europe, north Africa), with a strong concentration of diversity in the genus in the mountains of southwestern China and the Himalayas. They are related to hawthorns (Crataegus), firethorns (Pyracantha), photinias (Photinia), and rowans (Sorbus).` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Hosta',guideName: 'Hosta', day: 'March 3rd', theme: ' Humans', src:m3n3,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Hosta (/ˈhɒstə/, syn. Funkia) is a genus of plants commonly known as hostas, plantain lilies and occasionally by the Japanese name gibōshi. Hostas are widely cultivated as shade-tolerant foliage plants. The genus is currently placed in the family Asparagaceae, subfamily Agavoideae, and is native to northeast Asia (China, Japan, Korea, and the Russian Far East).' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Cotoneaster',guideName: 'Cotoneaster', day: 'March 4th', theme: ' Humans', src:m3n4,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Rudbeckia hirta, commonly called black-eyed Susan, is a North American flowering plant in the family Asteraceae, native to Eastern and Central North America and naturalized in the Western part of the continent as well as in China. It has now been found in all 10 Canadian Provinces and all 48 of the states in the contiguous United States.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Orchid',guideName: 'Orchid', day: 'March 5th', theme: ' Humans', src:m3n5,power: 'Connect Indigo Humans to Japanese Goddesses', des: `Orchids are plants that belong to the family Orchidaceae (/ˌɔːrkɪˈdeɪsi.iː, -si.aɪ/), a diverse and widespread group of flowering plants with blooms that are often colourful and fragrant. Orchids are cosmopolitan plants that are found in almost every habitat on Earth except glaciers. The world's richest diversity of orchid genera and species is found in the tropics.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Creeping Phlox',guideName: 'Creeping Phlox', day: 'March 6th', theme: ' Humans', src:m3n6,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Phlox stolonifera (creeping phlox or moss phlox) is a species of flowering plant in the family Polemoniaceae. It is a perennial herbaceous plant that is native to the eastern United States. It occurs in woodlands and stream banks in the vicinity of the Appalachian Mountains from Pennsylvania south to northern Georgia. Naturalized populations occur as far north as Québec, Canada.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Crocosmia',guideName: 'Crocosmia', day: 'March 7th', theme: ' Humans', src:m3n7,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Crocosmia (/krəˈkɒzmiə, kroʊ-/;), also known as montbretia (/mɒnˈbriːʃə), is a small genus of flowering plants in the iris family, Iridaceae. It is native to the grasslands of southern and eastern Africa, ranging from South Africa to Sudan. One species is endemic to Madagascar.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Rondeletia',guideName: 'Rondeletia', day: 'March 8th', theme: ' Humans', src:m3n8,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Rondeletia is a genus of flowering plants in the family Rubiaceae. It is endemic to the Neotropics. There are around 160 species. Rondeletia odorata is widely grown as an ornamental. Several other species are also known in cultivation. Rondeletia was named in 1753 by Linnaeus in his book, Species Plantarum.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Crown Imperial',guideName: 'Crown Imperial', day: 'March 9th', theme: ' Humans', src:m3n9,power: 'Connect Indigo Humans to Norse Goddesses', des: 'Cyclamen (US: /ˈsaɪkləmən/ SY-klə-mən or UK: /ˈsɪkləmən/ SIK-lə-mən) is a genus of 23 species of perennial flowering plants in the family Primulaceae. In English, it is known by the common names sowbread or swinebread. Cyclamen species are native to Europe and the Mediterranean Basin east to the Caucasus and Iran, with one species in Somalia. They grow from tubers and are valued for their flowers with upswept petals and variably patterned leaves.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Daphne',guideName: 'Daphne', day: 'March 10th', theme: ' Humans', src:m3n10,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Daphne /ˈdæfni/ (Greek: δάφνη, romanized: dafni, "laurel") is a genus of between 70 and 95 species of deciduous and evergreen shrubs in the family Thymelaeaceae, native to Asia, Europe and north Africa. They are noted for their scented flowers and often brightly coloured berries. Two species are used to make paper. Many species are grown in gardens as ornamental plants; the smaller species are often used in rock gardens. All parts of daphnes are poisonous, especially the berries.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Cyclamen',guideName: 'Cyclamen', day: 'March 11th', theme: ' Humans', src:m3n11,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Cyclamen (US: /ˈsaɪkləmən/ SY-klə-mən or UK: /ˈsɪkləmən/ SIK-lə-mən) is a genus of 23 species of perennial flowering plants in the family Primulaceae. In English, it is known by the common names sowbread or swinebread. Cyclamen species are native to Europe and the Mediterranean Basin east to the Caucasus and Iran, with one species in Somalia. They grow from tubers and are valued for their flowers with upswept petals and variably patterned leaves.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Fuschia',guideName: 'Fuschia', day: 'March 12th', theme: ' Humans', src:m3n12,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Fuchsia ( /ˈfjuːʃə/) is a genus of flowering plants that consists mostly of shrubs or small trees. Almost 110 species of Fuchsia are recognized; the vast majority are native to South America, but a few occur north through Central America to Mexico, and also several from New Zealand to Tahiti. One species, F. magellanica, extends as far as the southern tip of South America, occurring on Tierra del Fuego in the cool temperate zone, but the majority are tropical or subtropical.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Coral Bells',guideName: 'Coral Bells', day: 'March 13th', theme: ' Humans', src:m3n13,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Heuchera (/ˈhjuːkɪrə/ HEW-kih-rə or /ˈhɔɪkərə/ HOY-kih-rə) is a genus of largely evergreen perennial plants in the family Saxifragaceae, all native to North America. Common names include alumroot and coral bells. Heuchera have palmately lobed leaves on long petioles, and a thick, woody rootstock.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Lavatera',guideName: 'Lavatera', day: 'March 14th', theme: ' Humans', src:m3n14,power: 'Connect Raimbow Humans to Japanese Goddesses', des: 'Malva knows also as Lavatera is a genus of herbaceous annual, biennial, and perennial plants in the family Malvaceae. It is one of several closely related genera in the family to bear the common English name mallow. The genus is widespread throughout the temperate, subtropical and tropical regions of Africa, Asia and Europe. The leaves are alternate, palmately lobed. The flowers are from 0.5–5 cm diameter, with five pink, lilac, purple or white petals.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Cosmos',guideName: 'Cosmos', day: 'March 15th', theme: ' Humans', src:m3n15,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Cosmos is a genus, with the same common name of cosmos, consisting of flowering plants in the sunflower family. Cosmos are herbaceous perennial plants or annual plants growing 0.3–2 m  tall. The leaves are simple, pinnate, or bipinnate, and arranged in opposite pairs. The flowers are produced in a capitulum with a ring of broad ray florets and a center of disc florets; flower color varies noticeably between the different species. ' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Day Lily',guideName: 'Day Lily', day: 'March 16th', theme: ' Humans', src:m3n16,power: 'Connect Crystal Humans to Norse Goddesses', des: `A daylily or day lily is a flowering plant in the genus Hemerocallis /ˌhɛmɪroʊˈkælɪs/, a member of the family Asphodelaceae, subfamily Hemerocallidoideae, native to Asia. Despite the common name, it is not in fact a lily. Gardening enthusiasts and horticulturists have long bred daylily species for their attractive flowers. Thousands of cultivars have been registered by local and international Hemerocallis societies. Daylilies are perennial plants, whose name alludes to its flowers, which typically last about a day.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Gladiolus',guideName: 'Gladiolus', day: 'March 17th', theme: ' Humans', src:m3n17,power: 'Connect Indigo Humans to Japanese Goddesses', des: `Gladiolus (from Latin, the diminutive of gladius, a sword is a genus of perennial cormous flowering plants in the iris family (Iridaceae). It is sometimes called the 'sword lily', but is usually called by its generic name (plural gladioli). The genus occurs in Asia, Mediterranean Europe, South Africa, and tropical Africa. The center of diversity is in the Cape Floristic Region. The genera Acidanthera, Anomalesia, Homoglossum, and Oenostachys, formerly considered distinct, are now included in Gladiolus.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Desert Rose',guideName: 'Desert Rose', day: 'March 18th', theme: ' Humans', src:m3n18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Adenium obesum, more commonly known as a desert rose, is a poisonous species of flowering plant belonging to the tribe Nerieae of the subfamily Apocynoideae of the dogbane family, Apocynaceae. It is native to the Sahel regions south of the Sahara (from Mauritania and Senegal to Sudan), tropical and subtropical eastern and southern Africa and also the Arabian Peninsula. Other names for the flower include Sabi star, kudu, mock azalea, and impala lily. Adenium obesum is a popular houseplant and bonsai in temperate regions.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Dichondra',guideName: 'Dichondra', day: 'March 19th', theme: ' Humans', src:m3n19,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Dichondra is a small genus of flowering plants in the morning glory family, Convolvulaceae. They are prostrate, perennial, herbaceous plants, with creeping stems which take root readily at the leaf nodes. The flowers are white, greenish or yellowish, 2–3 mm (0.079–0.118 in) diameter. Members of the genus are commonly known as ponysfoots and are native to tropical and cool temperate regions around the world.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Geranium',guideName: 'Geranium', day: 'March 20th', theme: ' Humans', src:m3n20,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Geranium is a genus of 422 species of annual, biennial, and perennial plants that are commonly known as geraniums or cranesbills. They are found throughout the temperate regions of the world and the mountains of the tropics, with the greatest diversity in the eastern part of the Mediterranean region. The palmately cleft leaves are broadly circular in form. The flowers have five petals and are coloured white, pink, purple or blue, often with distinctive veining.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Diascia',guideName: 'Diascia', day: 'March 21th', theme: ' Humans', src:m3n21,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Diascia is a genus of around 70 species of herbaceous annual and perennial flowering plants of the family Scrophulariaceae, native to southern Africa, including South Africa, Lesotho and neighbouring areas. The perennial species are found mainly in summer-rainfall areas such as the KwaZulu-Natal Drakensberg; about 50 species, mostly annuals, are found in the Western Cape and Namaqualand, winter rainfall areas.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Bluebell',guideName: 'Bluebell', day: 'March 22th', theme: ' Humans', src:m3n22,power: 'Connect Raimbow Humans to Norse Goddesses', des: 'Campanula rotundifolia, the harebell, Scottish bluebell, or bluebell of Scotland, is a species of flowering plant in the bellflower family Campanulaceae. This herbaceous perennial is found throughout the temperate regions of the northern hemisphere. In Scotland, it is often known simply as bluebell. It is the floral emblem of Sweden where it is known as small bluebell. It produces its violet-blue, bell-shaped flowers in late summer and autumn' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Lobelia',guideName: 'Lobelia', day: 'March 23th', theme: ' Humans', src:m3n23,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Lobelia (/loʊˈbiːliə, lə-/) is a genus of flowering plants comprising 415 species, with a subcosmopolitan distribution primarily in tropical to warm temperate regions of the world, a few species extending into cooler temperate regions. They are known generally as lobelias.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Dahlia',guideName: 'Dahlia', day: 'March 24th', theme: ' Humans', src:m3n24,power: 'Connect Crystal Humans to Greek Goddesses', des: 'Dahlia (UK: /ˈdeɪliə/ DAY-lee-ə, US: /ˈdæljə, ˈdɑːljə, ˈdeɪljə/ DA(H)L-yə, DAYL-yə) is a genus of bushy, tuberous, herbaceous perennial plants native to Mexico and Central America. As a member of the Asteraceae (synonym name: Compositae) family of dicotyledonous plants, its relatives include the sunflower, daisy, chrysanthemum, and zinnia. There are 49 species of dahlia, with flowers in almost every hue (except blue), with hybrids commonly grown as garden plants.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Echinacea',guideName: 'Echinacea', day: 'March 25th', theme: ' Humans', src:m3n25,power: 'Connect Indigo Humans to Norse Goddesses', des: 'Echinacea /ˌɛkɪˈneɪʃiə/ is a genus of herbaceous flowering plants in the daisy family. It has ten species, which are commonly called coneflowers. They are found only in eastern and central North America, where they grow in moist to dry prairies and open wooded areas. They have large, showy heads of composite flowers, blooming in summer. The generic name is derived from the Greek word ἐχῖνος (ekhinos), meaning "hedgehog", due to the spiny central disk' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Echium',guideName: 'Echium', day: 'March 26th', theme: ' Humans', src:m3n26,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Gaillardia /ɡeɪˈlɑːrdiə/ (common name blanket flower) is a genus of flowering plants in the family Asteraceae, native to North and South America. It was named after Maître Gaillard de Charentonneau, an 18th-century French magistrate who was an enthusiastic botanist. The common name may refer to the resemblance of the inflorescence to the brightly patterned blankets made by Native Americans, or to the ability of wild taxa to blanket the ground with colonies. Many cultivars have been bred for ornamental use.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Acacia',guideName: 'Acacia', day: 'March 27th', theme: ' Humans', src:m3n27,power: 'Connect Crystal Humans to Norse Goddesses', des: 'The four-leaf clover is a rare variation of the common three-leaf clover. According to traditional sayings, such clovers bring good luck, though it is not clear when or how this idea began. One early mention of "Fower-leafed or purple grasse" is from 1640 and simply says that it was kept in gardens because it was "good for the purples in children or others". Clovers can have more than four leaves. Five-leaf clovers are less commonly found naturally than four-leaf clovers; however, they, too, have been successfully cultivated. Some four-leaf clover collectors, particularly in Ireland, regard the five-leaf clover, known as a rose clover, as a particular prize.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Erica',guideName: 'Erica', day: 'March 28th', theme: ' Humans', src:m3n28,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Erica is a genus of roughly 857 species of flowering plants in the family Ericaceae. The English common names heath and heather are shared by some closely related genera of similar appearance. The genus Calluna was formerly included in Erica – it differs in having even smaller scale-leaves (less than 2–3 millimetres long), and the flower corolla consisting of separate petals. Erica is sometimes referred to as "winter (or spring) heather" to distinguish it from Calluna "summer (or autumn) heather".' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Rock Rose',guideName: 'Rock Rose', day: 'March 29th', theme: ' Humans', src:m3n29,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'The Cistaceae are a small family of plants (rock-rose or rock rose family) known for their beautiful shrubs, which are profusely covered by flowers at the time of blossom. This family consists of about 170(-200) species in nine genera that are not very distinct, distributed primarily in the temperate areas of Europe and the Mediterranean basin, but also found in North America; a limited number of species are found in South America. ' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Ageratum',guideName: 'Ageratum', day: 'March 29th', theme: ' Humans', src:m3n30,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Ageratum (/əˈdʒɛrətəm/) (whiteweed in the US) is a genus of 40 to 60 tropical and warm temperate flowering annuals and perennials from the family Asteraceae, tribe Eupatorieae. Most species are native to Central America and Mexico but four are native to the United States. They form tussocks or small hills. They grow to a height of 30 inches (76 cm). The opposite leaves are cordate or oval, hairy or tomentose.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Gaillardia',guideName: 'Gaillardia', day: 'March 29th', theme: ' Humans', src:m3n31,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Gaillardia /ɡeɪˈlɑːrdiə/ (common name blanket flower) is a genus of flowering plants in the family Asteraceae, native to North and South America. It was named after Maître Gaillard de Charentonneau, an 18th-century French magistrate who was an enthusiastic botanist. The common name may refer to the resemblance of the inflorescence to the brightly patterned blankets made by Native Americans, or to the ability of wild taxa to blanket the ground with colonies. Many cultivars have been bred for ornamental use.' },
]

export const AprilHumanIntros = [
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Refined',guideName: 'Refined', day: 'April 1st', theme: ' Humans', src:m4h1, power: 'Connect Indigo Humans to Greek Goddesses', des: 'With impurities or unwanted elements having been removed by processing. Elegant and cultured in appearance, manner, or taste. Developed or improved so as to be precise or subtle.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Honorable',guideName: 'Honorable', day: 'April 2nd', theme: ' Humans', src:m4h2, power: ' Connect Raimbow Humans to Norse Goddesses ', des: 'Bringing or deserving honour. Used as a title for certain high officials, the children of certain ranks of the the nobility, and MPs.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Sunny',guideName: 'Sunny', day: 'April 3rd', theme: ' Humans', src:m4h3, power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Bright with sunlight. (of a place) receiving much sunlight. (of a person or their their temperament) cheery and bright.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Intelligent',guideName: 'Intelligent', day: 'April 4th', theme: ' Humans', src:m4h4, power: 'Connect Crystal Humans to Norse Goddesses', des: 'Having or showing intelligence, especially of a high level. (of a device or building) able to vary its state or action in response to varying situations and past experience.' },
  { sex: 'pink', deesse: 'The Calendar of Love',name: 'Fluidity',guideName: 'Fluidity', day: 'April 5th', theme: ' Humans', src:m4h5, power: 'Connect Indigo Humans to Japanese Goddesses', des: 'The ability of a substance to flow easily. "lead especially assists in the fluidity of the molten metal". The state of being unsettled  or unstable; changeability.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Capacity',guideName: 'Capacity', day: 'April 6th', theme: ' Humans', src:m4h6,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Of a person: cognitive abilities such as problem-solving and critical thinking, physical abilities such as strength and dexterity, and emotional abilities such as empathy and self-awareness.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Courageous',guideName: 'Courageous', day: 'April 7th', theme: ' Humans', src:m4h7,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Not deterred  by danger or pain; brave.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Fortunate',guideName: 'Fortunate', day: 'April 8th', theme: ' Humans', src:m4h8,power: 'Connect Crystal Humans to Japanese Goddesses ', des: 'Favoured by or involving good luck; lucky. Auspicious or favourable. Materially  well off; prosperous' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Alliance',guideName: 'Alliance', day: 'April 9th', theme: ' Humans', src:m4h9,power: 'Connect Indigo Humans to Norse Goddesses ', des: 'A union or association formed for mutual benefit, especially between countries or organizations.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Mastery',guideName: 'Mastery', day: 'April 10th', theme: ' Humans', src:m4h10,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Comprehensive knowledge or skill in a particular subject or activity. Control or superiority over someone or something.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Ability',guideName: 'Ability', day: 'April 11th', theme: ' Humans', src:m4h11,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Possession of the means or skill to do something. Talent, skill, or proficiency  in a particular area' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Acquiescence',guideName: 'Acquiescence', day: 'April 12th', theme: ' Humans', src:m4h12,power: 'Connect Crystal Humans to Norse Goddesses', des: 'The reluctant  acceptance of something without protest.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Wondrous',guideName: 'Wondrous', day: 'April 13th', theme: ' Humans', src:m4h13,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Inspiring a feeling of wonder or delight; marvelous.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Affectionate',guideName: 'Affectionate', day: 'April 14th', theme: ' Humans', src:m4h14,power: 'Connect Raimbow Humans to Japanese Goddesses', des: 'Readily feeling or showing fondness  or tenderness. Expressing fondness.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Upbeat',guideName: 'Upbeat', day: 'April 15th', theme: ' Humans', src:m4h15,power: 'Connect Diamond Humans to Greek Goddesses', des: `Beat.cheerful; optimistic. If you tend to be cheerful and positive no matter what's going on, your friends probably describe you as upbeat.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Perfection',guideName: 'Perfection', day: 'April 16th', theme: ' Humans', src:m4h16,power: 'Connect Crystal Humans to Norse Goddesses', des: 'The state or quality of being perfect. A person or thing considered to be perfect. The action or process of improving something until it is faultless.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Cheerful',guideName: 'Cheerful', day: 'April 17th', theme: ' Humans', src:m4h17,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Noticeably  happy and optimistic. Causing happiness by its nature or appearance' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Keen',guideName: 'Keen', day: 'April 18th', theme: ' Humans', src:m4h18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Having or showing eagerness  or enthusiasm. (of a sense) highly developed.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Gaiety',guideName: 'Gaiety', day: 'April 19th', theme: ' Humans', src:m4h19,power: 'Connect Diamond Humans to Norse Goddesses', des: 'The state or quality of being lighthearted  or Cheerful.  Lively celebration or or festivities.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Magnificent',guideName: 'Magnificent', day: 'April 20th', theme: ' Humans', src:m4h20,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Extremely beautiful, elaborate, or impressive. Very good; excellent.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Lover',guideName: 'Lover', day: 'April 21th', theme: ' Humans', src:m4h21,power: 'Connect Indigo Humans to Greek Goddesses', des: 'A partner in a sexual or romantic relationship outside marriage. A person who likes or enjoys a specified thing.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Thorough',guideName: 'Thorough', day: 'April 22th', theme: ' Humans', src:m4h22,power: 'Connect Raimbow Humans to Norse Goddesses', des: 'Complete with regard to every detail; not superficial  or partial. Performed or written with great care and completeness. Taking pains  to do something carefully and completely.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Sympathy',guideName: 'Sympathy', day: 'April 23th', theme: ' Humans', src:m4h23,power: 'Connect Diamond Humans to Japanese Goddesses', des: `Feelings of pity and sorrows for someone else's misfortunes. Understanding between people; common feeling.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Friendly',guideName: 'Friendly', day: 'April 24th', theme: ' Humans', src:m4h24,power: 'Connect Crystal Humans to Greek Goddesses', des: `Kind and pleasant. Denoting something that is adapted for or is not harmful to a specified thing.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Luscious',guideName: 'Luscious', day: 'April 25th', theme: ' Humans', src:m4h25,power: 'Connect Indigo Humans to Norse Goddesses', des: 'Appealing strongly to the senses; pleasingly rich. Very sexually attractive.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Dynamicity',guideName: 'Dynamicity', day: 'April 26th', theme: ' Humans', src:m4h26,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Marked by usually continuous and productive activity or change. Energetic, forceful. a dynamic personality.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Passionate',guideName: 'Passionate', day: 'April 27th', theme: ' Humans', src:m4h27,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Having, showing, or caused by strong feelings or beliefs. Arising from intense feelings of sexual love.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Flashiness',guideName: 'Flashiness', day: 'April 28th', theme: ' Humans', src:m4h28,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Excessive or unnecessary display his penchant for flashiness, as evidenced by his expensive, specially tailored suits.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Jovial',guideName: 'Jovial', day: 'April 29th', theme: ' Humans', src:m4h29,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'Characterized by good-humored cheerfulness and conviviality.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Legend',guideName: 'Legend', day: 'April 30th', theme: ' Humans', src:m4h30,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'A traditional story sometimes popularly  regarded as historical but not authenticated.' },
]
export const AprilUniverseIntros = [
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Sabik',guideName: 'Sabik', day: 'April 1st', theme: 'Humans', src:m4u1, power: 'Connect Indigo Humans to Greek Goddesses', des: `Eta Ophiuchi (η Ophiuchi, abbreviated Eta Oph, η Oph) is a binary star  in the constellation  of Ophiuchus. Based on parallax  measurements taken during the Hipparcos  mission, it is approximately 88 light-years  from the Sun. Eta Ophiuchi is part of a multiple star system  designated WDS J17104-1544. It itself is designated WDS J17104-1544AB and its two components WDS J17104-1544A (also called Sabik, the traditional name for the system) and WDS J17104-1544B. The 'C' component is UCAC4 372-080717 and 'D' is UCAC2 26022336.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Aludra',guideName: 'Aludra', day: 'April 2nd', theme: 'Humans', src:m4u2, power: ' Connect Raimbow Humans to Norse Goddesses ', des: 'Eta Canis Majoris (η Canis Majoris, abbreviated Eta CMa, η CMa), also named Aludra /əˈluːdrə,əˈljuːdrə/, is a star  in the constellation  of Canis Major. Since 1943, the spectrum  of this star has served as one of the stable anchor points by which other stars are classified.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Celaeno',guideName: 'Celaeno', day: 'April 3rd', theme: ' Humans', src:m4u3, power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Celaeno /səˈliːnoʊ/,designated 16 Tauri, is a star  in the constellation  of Taurus  and a member of the Pleiades  open star cluster  (M45) of stars.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Diphda',guideName: 'Diphda', day: 'April 4th', theme: ' Humans', src:m4u4, power: 'Connect Crystal Humans to Norse Goddesses', des: `Beta Ceti (β Ceti, abbreviated Beta Cet, β Cet), officially named Diphda /ˈdɪfdə/, is the brightest star  in the constellation  of Cetus. Although designated 'beta', it is actually brighter than the 'alpha' star in the constellation, Menkar, by half a magnitude. This orange giant is easy to identify due to its location in an otherwise dark section of the celestial sphere. Based on parallax measurements, it lies at an estimated distance of 96.3 light-years  (29.5 parsecs) from the Sun.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Minelauva',guideName: 'Minelauva', day: 'April 5th', theme: ' Humans', src:m4u5, power: 'Connect Indigo Humans to Japanese Goddesses', des: `Delta Virginis (δ Virginis, abbreviated Del Vir, δ Vir), formally named Minelauva /mɪnəˈlɔːvə/ is a star  in the zodiac  constellation  of Virgo. With an apparent visual magnitude  of 3.4,this star is bright enough to be seen with the naked eye. It is located at a distance of about 202 light-years  (62 parsecs) from the Sun ` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Cujam',guideName: 'Cujam', day: 'April 6th', theme: ' Humans', src:m4u6,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Omega Herculis (ω Herculis, abbreviated Ome Her, ω Her) is a binary star system in the northern constellation  of Hercules. Based upon an annual parallax shift  of 13.04 mas  as seen from Earth, it is located around 250 light-years  from the Sun. It is faintly visible to the naked eye, having a combined apparent visual magnitude  of 4.58. The system is a candidate for membership in the Ursa Major Moving Group, although this remains uncertain.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Algieba',guideName: 'Algieba', day: 'April 7th', theme: ' Humans', src:m4u7,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Gamma Leonis (γ Leonis, abbreviated Gamma Leo, γ Leo), also named Algieba /ælˈdʒiːbə/ is a binary star  system in the constellation  of Leo. In 2009, a planetary companion around the primary was announced.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Spica',guideName: 'Spica', day: 'April 8th', theme: ' Humans', src:m4u8,power: 'Connect Crystal Humans to Japanese Goddesses ', des: 'Spica is the brightest object in the constellation  of Virgo  and one of the 20 brightest stars  in the night sky. It has the Bayer designation  α Virginis, which is Latinised  to Alpha Virginis and abbreviated Alpha Vir or α Vir. Analysis of its parallax  shows that it is located 250±10 light-years  from the Sun. It is a spectroscopic  binary star  and rotating ellipsoidal variable; a system whose two stars are so close together they are egg-shaped rather than spherical, and can only be separated by their spectra. The primary is a blue giant  and a variable star  of the Beta Cephei type.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Salm',guideName: 'Salm', day: 'April 9th', theme: ' Humans', src:m4u9,power: 'Connect Indigo Humans to Norse Goddesses ', des: `Tau Pegasi (τ Pegasi, abbreviated Tau Peg, τ Peg), formally named Salm /ˈsɑːm/, is a magnitude  4.6 star 162 light years  away in the constellation  of Pegasus. With about twice the mass of the Sun  and thirty times as luminous, tt is a δ Scuti  variable star with its brightness changing by a few hundredths of a magnitude over about an hour.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Butterfly',guideName: 'Butterfly', day: 'April 10th', theme: ' Humans', src:m4u10,power: 'Connect Crystal Humans to Norse Goddesses', des: `Star vs. the Forces of Evil is an American animated  magical girl  television series created by Daron Nefcy  and developed by Jordana Arkin and Dave Wasson, which aired on Disney Channel  and Disney XD. It is the first Disney XD series created by a woman, and the third overall for Disney Television Animation  (following Pepper Ann  from 1997 and Doc McStuffins  from 2012).` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Cartwheel',guideName: 'Cartwheel', day: 'April 11th', theme: ' Humans', src:m4u11,power: 'Connect Raimbow Humans to Greek Goddesses', des: `The Cartwheel Galaxy (also known as ESO 350-40 or PGC 2248) is a lenticular ring galaxy  about 500 million  light-years  away in the constellation  Sculptor. It has a D25 isophotal diameter of 44.23 kiloparsecs (144,300 light-years), and a mass of about 2.9–4.8 × 109 solar masses; its outer ring has a circular velocity of 217 km/s. ` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Fang',guideName: 'Fang', day: 'April 12th', theme: ' Humans', src:m4u12,power: 'Connect Crystal Humans to Norse Goddesses', des: `Pi Scorpii or π Scorpii, is a triple star  system  in the southern constellation  of Scorpius. With a combined apparent magnitude  of 2.9, it can be easily seen with the naked eye. Parallax  measurements yield an estimated distance of around 590 light-years  (180 parsecs) from the Sun. It consists of a binary pair, designated Pi Scorpii A, with a more distant third companion, B. A's two components are themselves designated Pi Scorpii Aa (formally named Fang) and Ab.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Alphecca',guideName: 'Alphecca', day: 'April 13th', theme: ' Humans', src:m4u13,power: 'Connect Indigo Humans to Greek Goddesses', des: `Alpha Coronae Borealis (α Coronae Borealis, abbreviated Alpha CrB, α CrB), officially named Alphecca /ælˈfɛkə/, is an eclipsing binary  star in the constellation  of Corona Borealis. It is located about 75 light years  from the Sun  and contains two main sequence  stars, one class A  and one class G ` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Schedar',guideName: 'Schedar', day: 'April 14th', theme: ' Humans', src:m4u14,power: 'Connect Raimbow Humans to Japanese Goddesses', des: `Alpha Cassiopeiae or α Cassiopeiae, also named Schedar (/ˈʃɛdɑːr/), is a second-magnitude star in the northern constellation  of Cassiopeia. Though listed as the "alpha  star" by Johann Bayer, α Cas's visual brightness closely matches the 'beta' (β) star in the constellation (Beta Cassiopeiae) and it may appear marginally brighter or dimmer, depending on which passband  is used. However, recent calculations from NASA's  WISE  telescope confirm that α Cas is the brightest in Cassiopeia, with an apparent magnitude  of 2.240. Its absolute magnitude  is 18 times greater than β Cas, and it is located over four times farther away from the Sun.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Chara',guideName: 'Chara', day: 'April 15th', theme: ' Humans', src:m4u15,power: 'Connect Diamond Humans to Greek Goddesses', des: `Beta Canum Venaticorum (β Canum Venaticorum, abbreviated Beta CVn, β CVn), also named Chara /'kɛərə/, is a G-type main-sequence star  in the northern constellation  of Cannes Venatici. At an Venatici visual magnitude  of 4.25, it is the second-brightest star in the constellation. Based upon an annual parallax  shift of 118 mas, this star is 27.6 light-years  (8.5 parsecs) distant from the Sun.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Segin',guideName: 'Segin', day: 'April 16th', theme: ' Humans', src:m4u16,power: 'Connect Crystal Humans to Norse Goddesses', des: `Epsilon Cassiopeiae or ε Cassiopeiae, officially named Segin (/'sɛɡɪn/), is a single star  in the northern constellation  of Cassiopeia. With an apparent visual magnitude  of 3.4, this is one of the brightest stars  in the constellation. The distance to this star has been determined directly using parallax  measurements, yielding a value of around 460–430 light-years  (140–130 parsecs) from the Sun. It is drifting closer with a radial velocity  of −8 km/s.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Phecda',guideName: 'Phecda', day: 'April 17th', theme: ' Humans', src:m4u17,power: 'Connect Indigo Humans to Japanese Goddesses', des: 'Phecda /ˈfɛkdə/, also called Gamma Ursae Majoris (γ Ursae Majoris, abbreviated Gamma UMa, γ UMa),is a star  in the constellation  of Ursa Major. Since 1943, the spectrum  of this star has served as one of the stable anchor points by which other stars are classified. Based upon parallax  measurements with the Hipparcos  astrometry satellite,it is located at distance of around 83.2 light-years  (25.5 parsecs) from the Sun.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Fawaris',guideName: 'Fawaris', day: 'April 18th', theme: ' Humans', src:m4u18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `Delta Cygni (δ Cygni, abbreviated Delta Cyg, δ Cyg) is a binary star  of a combined third-magnitude in the constellation  of Cygnus. It is also part of the Northern Cross  asterism  whose brightest star is Deneb. Based upon parallax  measurements obtained during the Hipparcos  mission, Delta Cygni is located roughly 165 light-years  (51 parsecs) distant from the Sun.
  Delta Cygni's two components are designated Delta Cygni A (officially named Fawaris /fəˈwɛərɪs/) and B. More widely separated is a faint third component, a 12th magnitude star that is moving along with the others. Together they form a triple star system.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Large Magellanic Cloud',guideName: 'Large Magellanic Cloud', day: 'April 19th', theme: ' Humans', src:m4u19,power: 'Connect Diamond Humans to Norse Goddesses', des: 'The Large Magellanic Cloud (LMC) is a spiral satellite galaxy  of the Milky Way. At a distance of around 50 kiloparsecs  (163,000 light-years),the LMC is the second- or third-closest galaxy  to the Milky Way, after the Sagittarius Dwarf Spheroidal  (c. 16 kiloparsecs (52,000 light-years) away) and the possible dwarf irregular galaxy  called the Canis Major Overdensity. Based on the D25 isophote at the B-band (445 nm wavelength of light), the Large Magellanic Cloud is about 9.86 kiloparsecs  (32,200 light-years) across. It is roughly one-hundredth the mass of the Milky Way and is the fourth-largest galaxy in the Local Group, after the Andromeda Galaxy  (M31), the Milky Way, and the Triangulum Galaxy  (M33).' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Alruba',guideName: 'Alruba', day: 'April 20th', theme: ' Humans', src:m4u20,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Alruba, a name derived from Arabic for "the foal", is a suspected astrometric binary star system in the northern circumpolar constellation of Draco.  It is just barely visible to the naked eye as a dim point of light with an apparent visual magnitude  of 5.76. Based on parallax  measurements obtained during the Gaia  mission, it is located at a distance of about 457 light-years  (140 parsecs) from the Sun. The system is drifting closer with a radial velocity  of −2 km/s.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Sadachbia',guideName: 'Sadachbia', day: 'April 21th', theme: ' Humans', src:m4u21,power: 'Connect Indigo Humans to Greek Goddesses', des: `Gamma Aquarii, or γ Aquarii, is a suspected binary star  system in the constellation  of Aquarius. It has an apparent visual magnitude  of 3.849, making it one of the brighter members  of the constellation. Based upon parallax  measurements taken during the Hipparcos  mission, this star is located at a distance of approximately 164 light-years  (50 parsecs) from the Sun. It is drifting closer to the Sun with a radial velocity  of −16 km/s. In 1998, Olin J. Eggen  included this star as a candidate member of the Hyades Supercluster.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Miaplacidus',guideName: 'Miaplacidus', day: 'April 22th', theme: ' Humans', src:m4u22,power: 'Connect Raimbow Humans to Norse Goddesses', des: `Beta Carinae is the second-brightest star  in the southern constellation  of Carina. It has the official name Miaplacidus; Beta Carinae is the star's Bayer designation, which is Latinised  from β Carinae and abbreviated Beta Car or β Car. With apparent magnitude  of 1.68, it is one of the brightest stars  in the night sky. It is the brightest star in the southern  asterism  known as the Diamond Cross, marking the southwestern end of the asterism. It lies near the planetary nebula  IC 2448. Parallax  measurements place it at a distance of 113.2 light-years  (34.7 parsecs) from the Sun.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Gomeisa',guideName: 'Gomeisa', day: 'April 23th', theme: ' Humans', src:m4u23,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Beta Canis Minoris (β Canis Minoris, abbreviated Beta CMi, β CMi), also named Gomeisa /ɡɒˈmaɪzə/,is a star  in the constellation  of Canis Minor. In the night sky it is notable for its proximity to the prominent star Procyon.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Syrma',guideName: 'Syrma', day: 'April 24th', theme: ' Humans', src:m4u24,power: 'Connect Crystal Humans to Greek Goddesses', des: `Iota Virginis (ι Virginis, abbreviated Iota Vir, ι Vir) is a binary star  in the constellation  of Virgo. Its apparent magnitude is 4.08. Based on its parallax, it is assumed to be relatively nearby, at 72.5 light-years  (22.2 parsecs). Its two components are designated Iota Virginis A (officially named Syrma /'sɜːrmə/,the traditional name for the system) and B.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Skat',guideName: 'Skat', day: 'April 25th', theme: ' Humans', src:m4u25,power: 'Connect Indigo Humans to Norse Goddesses', des: `Delta Aquarii (δ Aquarii, abbreviated Delta Aqr, δ Aqr), officially named Skat /'skæt/, is the third-brightest star in the constellation  of Aquarius. The apparent visual magnitudes  is 3.3, which can be seen with the naked eye. The distance to this star is about 113 light-years  (35 parsecs) based upon parallax  measurements, and it has a close companion.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Sualocin',guideName: 'Sualocin', day: 'April 26th', theme: ' Humans', src:m4u26,power: 'Connect Diamond Humans to Greek Goddesses', des: `Alpha Delphini (α Delphini, abbreviated Alpha Del, α Del) is a multiple star system  in the constellation  of Delphinus. It consists of a triple star, designated Alpha Delphini A, together with five faint, probably optical  companions, designated Alpha Delphini B, C, D, E and F. A's two components are themselves designated Alpha Delphini Aa (officially named Sualocin /ˈswɒloʊsɪn/,the historical name for the entire system) and Ab.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Tania Australis',guideName: 'Tania Australis', day: 'April 27th', theme: ' Humans', src:m4u27,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Mu Ursae Majoris (μ Ursae Majoris, abbreviated Mu UMa, μ UMa), formally named Tania Australis /ˈteɪniə ɔːˈstreɪlɪs/, is a binary star  in the constellation  of Ursa Major. An apparent visual magnitude  of +3.06 places it among the brighter members  of the constellation. Parallax  measurements give an estimated distance of roughly 230 light-years  (71 parsecs) from the Sun, with a margin of error  of 4%.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Zubenelgenubi',guideName: 'Zubenelgenubi', day: 'April 28th', theme: ' Humans', src:m4u28,power: 'Connect Indigo Humans to Japanese Goddesses', des: `Alpha Librae (α Librae, abbreviated Alpha Lib, α Lib) is a double star  and, despite its 'alpha' designation, it is the second-brightest star system (or star) in the constellation of Libra. The two components are designated α1 Librae and α2 Librae. The system bore the traditional name of Zubenelgenubi  /zuːˌbɛnɛldʒɪˈnuːbi/,  though the International Astronomical Union  now regards that name as only applying to α2 Librae.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Veritate',guideName: 'Veritate', day: 'April 29th', theme: ' Humans', src:m4u29,power: 'Connect Raimbow Humans to Greek Goddesses', des: `14 Andromedae, abbreviated 14 And, also named Veritate /vɛrɪˈteɪtiː/,is a single, orange-hued giant star  situated approximately 247 light-years  away in the northern constellation  of Andromeda. It is dimly visible to the naked eye with an apparent visual magnitude  of 5.22. The star is moving closer to the Earth with a heliocentric radial velocity  of −60 km/s. In 2008 an extrasolar planet  (designated 14 Andromedae b, later named Spe) was discovered to be orbiting the star.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Sadr',guideName: 'Sadr', day: 'April 30th', theme: ' Humans', src:m4u30,power: ' Connect Crystal Humans to Greek Goddesses', des: `Gamma Cygni (γ Cygni, abbreviated Gamma Cyg, γ Cyg), officially named Sadr /ˈsædər/, is a star  in the northern constellation  of Cygnus, forming the intersection of an asterism  of five stars called the Northern Cross. Based upon parallax  measurements obtained during the Hipparcos  mission, it is approximately 1,800 light-years  (560 parsecs) from the Sun. It forms the primary or 'A' component of a multiple star system  designated WDS J20222+4015 (the secondary or 'BC' component is CCDM J20222+4015BC, a close pair of stars 40" away from γ Cygni).` },
]
export const AprilNatureIntros = [
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Eustoma',guideName: 'Eustoma', day: 'April 1st', theme: ' Humans', src:m4n1,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Known as Stock flower or Lisanthus, Eustoma is a common flower in the US. They grow in the empty fields and grounds and they bloom beautifully. The name was taken from the ancient Greek words, eu and stoma. “Eu” is a prefix word which means goodness while “stoma” means mouth.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Evening Primrose',guideName: 'Evening Primrose', day: 'April 2nd', theme: ' Humans', src:m4n2,power: 'Connect Raimbow Humans to Norse Goddesses ', des: `A plant with pale yellow flowers. Its seeds are used to make an oil that is used to treat various medical conditions: evening primrose oil.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'French Marigold',guideName: 'French Marigold', day: 'April 3rd', theme: ' Humans', src:m4n3,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'A strong-scented bushy annual herb (Tagetes patula) having flower heads usually about 1¹/₂ inches across and marked with red.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Euphorbia cyathophor',guideName: 'Euphorbia cyathophor', day: 'April 4th', theme: ' Humans', src:m4n4,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Euphorbia - name after Euphorbus 1st century physicia. cyathophora - kythos means cup, phoros means bearing. Global_description. E. cyathophora is a herbaceous to shrubby annual plant, erect to ascending, grows up to 1.50 m high, glabrous or loosely hairy, with multicellular hairs.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Floss Flower',guideName: 'Floss Flower', day: 'April 5th', theme: ' Humans', src:m4n5,power: 'Connect Indigo Humans to Japanese Goddesses', des: `Ageratum is derived from the Greek “a geras,” meaning non-aging, referring to the longevity of the flowers. These annual or perennial herbs and shrubs in the plant family Compositae (Asteraceae) are all native to Central and South America.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Flax Flower',guideName: 'Flax Flower', day: 'April 6th', theme: ' Humans', src:m4n6,power: 'Connect Raimbow Humans to Greek Goddesses', des: `The flax flower is symbolic of domestication, with a sheath of flax plants being given to newlyweds to honor their new home. California Goldenrod (Sometimes referred to as Canadian Goldenrod) – Solidago californica. The Washoe boiled leaves to make a salve for cuts. Symbolic of precaution and or anticipation.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Weigela',guideName: 'Weigela', day: 'April 7th', theme: ' Humans', src:m4n7,power: 'Connect Diamond Humans to Norse Goddesses', des: 'Any of a genus (Weigela) of showy eastern Asian shrubs of the honeysuckle family. especially : one (W. florida) widely grown for its usually pink or red funnel-shaped flowers.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Forget Me Not',guideName: 'Forget Me Not', day: 'April 8th', theme: ' Humans', src:m4n8,power: 'Connect Crystal Humans to Japanese Goddesses', des: `While traditionally this flower represents remembrance, they also carry several other meanings including true love, devotion, and royalty and are also associated with Alzheimer's and Alaska.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Rose of Sharon',guideName: 'Rose of Sharon', day: 'April 9th', theme: ' Humans', src:m4n9,power: 'Connect Indigo Humans to Norse Goddesses', des: 'An expression used, especially in Christianity, to refer to a beautiful flower, especially as a symbol of beauty and other good qualities: Every disciple going forth will carry a fragrance like the rose of Sharon' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Foxglove',guideName: 'Foxglove', day: 'April 10th', theme: ' Humans', src:m4n10,power: 'Connect Raimbow Humans to Greek Goddesses', des: '[ foks-gluhv ] show ipa. noun. any Eurasian plant belonging to the genus Digitalis, of the figwort family, especially D. purpurea, having drooping, tubular, purple or white flowers on tall spikes, and leaves that are the source of digitalis in medicine.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Frangipani',guideName: 'Frangipani', day: 'April 11th', theme: ' Humans', src:m4n11,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'Frangipani (countable and uncountable, plural frangipanis or frangipani) Any of several tropical American shrubs and trees of the genus Plumeria, having fragrant, showy, funnel-shaped flowers of a wide range of colours from creamy to red.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Alstroemeria aurea',guideName: 'Alstroemeria aurea', day: 'April 12th', theme: ' Humans', src:m4n12,power: 'Connect Crystal Humans to Norse Goddesses', des: 'The flower symbolizes devotion and friendship. Its leaves grow upside, twisting out from the stem as it grows – much like the twists, turns and growth of friendships. Alstroemeria is also a symbol of wealth, fortune and prosperity.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Honesty',guideName: 'Honesty', day: 'April 13th', theme: ' Humans', src:m4n13,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Lunaria annua, commonly called honesty or annual honesty, is a species of flowering plant in the cabbage and mustard family Brassicaceae. It is native to southern Europe, and cultivated throughout the temperate world.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Everlasting Daisy',guideName: 'Everlasting Daisy', day: 'April 14th', theme: ' Humans', src:m4n14,power: 'Connect Raimbow Humans to Japanese Goddesses', des: `The Daisy Flower's symbolic meaning is 'childhood, innocence and purity'. So the Everlasting Daisy would mean holding onto all of these qualities for all your life - forever. The dry, papery petals on the Everlasting (or Strawflower), last 'forever', and are always available as a dried arrangement in a vase.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Oyster',guideName: 'Oyster', day: 'April 15th', theme: ' Humans', src:m4n15,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Any of a number of bivalve  molluscs  with rough irregular shells. Several kinds are eaten (especially raw) as a delicacy and may be farmed  for food or pearls.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Mandevilla',guideName: 'Mandevilla', day: 'April 16th', theme: ' Humans', src:m4n16,power: 'Connect Crystal Humans to Norse Goddesses', des: `Origin:French. Meaning:great town. Mandeville as a boy's name is of Old French origin, and the meaning of Mandeville is "great town".` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Flannel Flower',guideName: 'Flannel Flower', day: 'April 17th', theme: ' Humans', src:m4n17,power: 'Connect Indigo Humans to Japanese Goddesses', des: `The flannel flower has come to symbolize elegance, beauty, and purity and is highly valued for many festivals and religious ceremonies. Cut flowers are used for both ornamental garden displays and sales in the flower industry` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Maltese Cross',guideName: 'Maltese Cross', day: 'April 18th', theme: ' Humans', src:m4n18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `The Maltese Cross is a symbol of protection - a badge of honor. Its story is hundreds of years old. When a courageous band of crusaders, known as the Knights of St. John, fought the Saracens for possession of the Holy Land, they encountered a new weapon unknown to European warriors.` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Gerbera',guideName: 'Gerbera', day: 'April 19th', theme: ' Humans', src:m4n19,power: 'Connect Diamond Humans to Norse Goddesses', des: `The gerbera has different meanings to different cultures. The Egyptians believed that they symbolised a closeness to nature and a devotion to the sun, whereas the Celts thought they lessened the sorrows and stresses of everyday life. Generally, gerberas symbolise innocence, purity, cheerfulness and loyal love.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Dutch Iris',guideName: 'Dutch Iris', day: 'April 20th', theme: ' Humans', src:m4n20,power: 'Connect Crystal Humans to Japanese Goddesses', des: 'Irises are a symbol of enduring faith and hope. While their blooms wither when cold weather arrives, shoots and colorful flowers reemerge .' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Winter Jasmine',guideName: 'Winter Jasmine', day: 'April 21th', theme: ' Humans', src:m4n21,power: 'Connect Indigo Humans to Greek Goddesses', des: 'In the language of flowers, winter jasmine is said to be a symbol of elegance and grace, It is also the flower of Epiphany, and is dedicated to the Virgin Mary in the Christian calendar.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Zenobia',guideName: 'Zenobia', day: 'April 22th', theme: ' Humans', src:m4n22,power: 'Connect Raimbow Humans to Norse Goddesses', des: 'life of Zeus. Parents seeking a timeless name with a mystical aura will love the feminine title, Zenobia. This lyrical name is Greek in origin and means “life of Zeus.” A name that inspires greatness, the third-century-BC Queen Zenobia was the ruler of the wealthy city of Palmyra in the Arabian desert.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: `Jacob's Ladder`,guideName: `Jacob's Ladder`, day: 'April 23th', theme: ' Humans', src:m4n23,power: 'Connect Diamond Humans to Japanese Goddesses', des: `The ladder signifies the divine connection between God and the earthly realm, specifically Jacob's family. The dream's significance was to remind Jacob he had received God's graces and encourage him to fulfill the destiny of his people` },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Jaborosa',guideName: 'Jaborosa', day: 'April 24th', theme: ' Humans', src:m4n24,power: 'Connect Crystal Humans to Greek Goddesses', des: 'Jaborosa is a genus of flowering plants in the family Solanaceae, the nightshades. There are about 23 species, all native to South America, where they are distributed from Peru to Patagonia. Most occur in the Andes. Most can be found in Argentina and ten are endemic to the country.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Gypsophila',guideName: 'Gypsophila', day: 'April 25th', theme: ' Humans', src:m4n25,power: 'Connect Indigo Humans to Norse Goddesses', des: `In the language of flowers, Gypsophila symbolizes innocence and eternal love; with these meanings, it's easy to see why it's such a beloved wedding flower! Aside from their use in weddings, Gypsophila flowers are also a staple for celebrating baby showers and newborn babies.` },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Jonquil',guideName: 'Jonquil', day: 'April 26th', theme: ' Humans', src:m4n26,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Jonquil. noun. jon·​quil ˈjän-kwəl. ˈjäŋ- : a Mediterranean daffodil that bears clusters of fragrant yellow or white flowers having a short central tube' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Jasmine',guideName: 'Jasmine', day: 'April 27th', theme: ' Humans', src:m4n27,power: 'Connect Crystal Humans to Norse Goddesses', des: 'The name Jasmine is of Persian origin, meaning ”gift from God." It is derived from the Persian word ”yasmin,” referring to the plant, meaning "fragrant flower." Origin: Jasmine is a Persian name meaning "gift from God" or "fragrant flower." Gender: Jasmine is commonly used as a girl name' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Jamesia americana',guideName: 'Jamesia americana', day: 'April 28th', theme: ' Humans', src:m4n28,power: 'Connect Diamond Humans to Greek Goddesses', des: 'Jamesia americana, also called cliffbush, waxflower or five petal cliffbush, is a species of flowering plant in the Hydrangeaceae.' },
  { sex: 'blue', deesse: 'The Calendar of Love', name: 'Wishbone Flower',guideName: 'Wishbone Flower', day: 'April 29th', theme: ' Humans', src:m4n29,power: 'Connect Raimbow Humans to Greek Goddesses', des: 'The Wishbone Flower symbolizes joy, happiness, and a desire for good fortune. Its elegant presence in gardens has long been associated with positive energy and optimism.' },
  { sex: 'pink', deesse: 'The Calendar of Love', name: 'Kalmia',guideName: 'Kalmia', day: 'April 30th', theme: ' Humans', src:m4n30,power: ' Connect Crystal Humans to Japanese Goddesses', des: 'kalmia. / (ˈkælmɪə) / noun. any evergreen ericaceous shrub of the North American genus Kalmia, having showy clusters of white or pink flowers: See also mountain laurel.' },
]

// export const AprilNatureIntros = [
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Watsonia',guideName: 'Watsonia', day: 'March 1st', theme: ' Humans', src:m3n1,power: 'Connect Indigo Humans to Greek Goddesses', des: 'Known as Stock flower or Lisanthus, Eustoma is a common flower in the US. They grow in the empty fields and grounds and they bloom beautifully. The name was taken from the ancient Greek words, eu and stoma. “Eu” is a prefix word which means goodness while “stoma” means mouth.' },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Coreopsis',guideName: 'Coreopsis', day: 'March 2nd', theme: ' Humans', src:m3n2,power: 'Connect Raimbow Humans to Norse Goddesses ', des: `A plant with pale yellow flowers. Its seeds are used to make an oil that is used to treat various medical conditions: evening primrose oil.` },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Hosta',guideName: 'Hosta', day: 'March 3rd', theme: ' Humans', src:m3n3,power: 'Connect Diamond Humans to Japanese Goddesses', des: 'A strong-scented bushy annual herb (Tagetes patula) having flower heads usually about 1¹/₂ inches across and marked with red.' },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Cotoneaster',guideName: 'Cotoneaster', day: 'March 4th', theme: ' Humans', src:m3n4,power: 'Connect Crystal Humans to Norse Goddesses', des: 'Euphorbia - name after Euphorbus 1st century physicia. cyathophora - kythos means cup, phoros means bearing. Global_description. E. cyathophora is a herbaceous to shrubby annual plant, erect to ascending, grows up to 1.50 m high, glabrous or loosely hairy, with multicellular hairs.' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Orchid',guideName: 'Orchid', day: 'March 5th', theme: ' Humans', src:m3n5,power: 'Connect Indigo Humans to Japanese Goddesses', des: `` },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Creeping Phlox',guideName: 'Creeping Phlox', day: 'March 6th', theme: ' Humans', src:m3n6,power: 'Connect Raimbow Humans to Greek Goddesses', des: `` },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Crocosmia',guideName: 'Crocosmia', day: 'March 7th', theme: ' Humans', src:m3n7,power: 'Connect Diamond Humans to Norse Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Rondeletia',guideName: 'Rondeletia', day: 'March 8th', theme: ' Humans', src:m3n8,power: 'Connect Crystal Humans to Japanese Goddesses', des: '' },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Crown Imperial',guideName: 'Crown Imperial', day: 'March 9th', theme: ' Humans', src:m3n9,power: 'Connect Indigo Humans to Norse Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Daphne',guideName: 'Daphne', day: 'March 10th', theme: ' Humans', src:m3n10,power: 'Connect Raimbow Humans to Greek Goddesses', des: '' },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Cyclamen',guideName: 'Cyclamen', day: 'March 11th', theme: ' Humans', src:m3n11,power: 'Connect Diamond Humans to Japanese Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Fuschia',guideName: 'Fuschia', day: 'March 12th', theme: ' Humans', src:m3n12,power: 'Connect Crystal Humans to Norse Goddesses', des: '' },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Coral Bells',guideName: 'Coral Bells', day: 'March 13th', theme: ' Humans', src:m3n13,power: 'Connect Indigo Humans to Greek Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Lavatera',guideName: 'Lavatera', day: 'March 14th', theme: ' Humans', src:m3n14,power: 'Connect Raimbow Humans to Japanese Goddesses', des: '' },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Cosmos',guideName: 'Cosmos', day: 'March 15th', theme: ' Humans', src:m3n15,power: 'Connect Diamond Humans to Greek Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Day Lily',guideName: 'Day Lily', day: 'March 16th', theme: ' Humans', src:m3n16,power: 'Connect Crystal Humans to Norse Goddesses', des: `` },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Gladiolus',guideName: 'Gladiolus', day: 'March 17th', theme: ' Humans', src:m3n17,power: 'Connect Indigo Humans to Japanese Goddesses', des: `` },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Desert Rose',guideName: 'Desert Rose', day: 'March 18th', theme: ' Humans', src:m3n18,power: 'Connect Raimbow Humans to Greek Goddesses', des: `` },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Dichondra',guideName: 'Dichondra', day: 'March 19th', theme: ' Humans', src:m3n19,power: 'Connect Diamond Humans to Norse Goddesses', des: '' },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Geranium',guideName: 'Geranium', day: 'March 20th', theme: ' Humans', src:m3n20,power: 'Connect Crystal Humans to Japanese Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Diascia',guideName: 'Diascia', day: 'March 21th', theme: ' Humans', src:m3n21,power: 'Connect Indigo Humans to Greek Goddesses', des: '' },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Bluebell',guideName: 'Bluebell', day: 'March 22th', theme: ' Humans', src:m3n22,power: 'Connect Raimbow Humans to Norse Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Lobelia',guideName: 'Lobelia', day: 'March 23th', theme: ' Humans', src:m3n23,power: 'Connect Diamond Humans to Japanese Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Dahlia',guideName: 'Dahlia', day: 'March 24th', theme: ' Humans', src:m3n24,power: 'Connect Crystal Humans to Greek Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Echinacea',guideName: 'Echinacea', day: 'March 25th', theme: ' Humans', src:m3n25,power: 'Connect Indigo Humans to Norse Goddesses', des: '' },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Echium',guideName: 'Echium', day: 'March 26th', theme: ' Humans', src:m3n26,power: 'Connect Diamond Humans to Greek Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Acacia',guideName: 'Acacia', day: 'March 27th', theme: ' Humans', src:m3n27,power: 'Connect Crystal Humans to Norse Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Erica',guideName: 'Erica', day: 'March 28th', theme: ' Humans', src:m3n28,power: 'Connect Diamond Humans to Greek Goddesses', des: '' },
//   { sex: 'pink', deesse: 'The Calendar of Love', name: 'Rock Rose',guideName: 'Rock Rose', day: 'March 29th', theme: ' Humans', src:m3n29,power: 'Connect Raimbow Humans to Greek Goddesses', des: ' ' },
//   { sex: 'blue', deesse: 'The Calendar of Love', name: 'Ageratum',guideName: 'Ageratum', day: 'March 29th', theme: ' Humans', src:m3n30,power: ' Connect Crystal Humans to Japanese Goddesses', des: '' },
// ]
