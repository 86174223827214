
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme';

import logoDeesse from 'assets/img/logo_deesse.svg'
import logoAmazon from 'assets/img/logo_amazon.svg'
import { ReactComponent as IconArrow } from 'assets/img/icon_arrow.svg'
import { ReactComponent as IconMore } from 'assets/img/more_g.svg'
import { ReactComponent as IconClose } from 'assets/img/close.svg'
import { Button } from '@mui/material';

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100px;
  padding: 0 120px;
  background: #292526;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 0 24px;
  }
`
const LogoBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const LogoBoxDeesse = styled.img`
  height: 60px;
`
const LogoBoxAmazon = styled.img`
  height: 40px;
`
const MenuBox = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: none;
  }
`
const MenuBoxH5 = styled.div`
  display: none;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const NavItem = styled.a<{ active: boolean }>`
  height: 100px;
  line-height: 100px;
  margin: 0 18px;
  display: flex;
  justify-content: center;
  /* width: 80px; */
  white-space: nowrap;
  font-weight: 500;
  letter-spacing: -0.322px;
  border-bottom: 4px solid ${props => props.active ? '#EC307F' : 'transparent'} ;
  color: ${props => props.active ? '#EC307F' : '#fff'};
  cursor: pointer;
`
const NavItemButton = styled(Button) <{ active: boolean }>`
  height: 100px;
  line-height: 100px;
  margin: 0 18px;
  font-weight: 500;
  letter-spacing: -0.322px;
  border-bottom: 4px solid ${props => props.active ? '#EC307F' : 'transparent'} ;
  color: ${props => props.active ? '#EC307F' : '#fff'} !important;
  cursor: pointer;
  font-size: 18px !important;
  font-family: Helvetica;
  &:hover {
    background: none !important;
  }
`
const MenuBoxH5Pop = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  background: #000;
  z-index: 999;
`
const NavItemH5 = styled.a`
  display: block;
  margin-top: 16px;
  padding-left: 40px;
  color: #fff;
  font-size: 18px;
`
const NavClose = styled(IconClose)`
  position: absolute;
  top: 24px;
  right: 24px;
`
const LinkA = styled.a`
  color: #fff;
`
export default function Header() {
  const [isNav, setIsNav] = React.useState('1');
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [buyEl, setBuyEl] = React.useState(null);
  const openBuy = Boolean(buyEl);
  const open = Boolean(anchorEl);
  const location = useLocation()
  const history = useHistory()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickBuy = (event) => {
    setBuyEl(event.currentTarget)
  }
  const handleCloseBuy = () => {
    setBuyEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openAbout = () => {
    setIsNav('4')
    setAnchorEl(null);
  }
  const openDeesse = () => {
    window.open('https://deesse.art/market?tab=trade ')
    setAnchorEl(null);
  }
  const openWhitePaper = () => {
    window.open('https://deesse-2.gitbook.io/deesse/')
    setAnchorEl(null);
  }
  const openHuobi = () => {
    window.open('https://www.htx.com/en-us/trade/love_usdt?inviter_id=11345710&invite_code=zh5i6223')
    setBuyEl(null);
  }
  const openBitMart = () => {
    window.open('https://www.bitmart.com/trade/en-US?symbol=LOVE_USDT&layout=pro')
    setBuyEl(null);
  }
  const goToHome = () => {
    history.push('/home')
  }
  useEffect(() => {
    if (location.pathname === '/about') {
      setIsNav('4')
    } else {
      setIsNav('1')
    }
  }, [])
  return (
    <HeaderBox>
      <LogoBox onClick={goToHome}>
        <LogoBoxDeesse src={logoDeesse}></LogoBoxDeesse>
        <LogoBoxAmazon src={logoAmazon}></LogoBoxAmazon>
      </LogoBox>
      <MenuBox>
        <NavItem active={false}>
          <NavItemButton
            active={false}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onMouseMove={handleClickBuy}
          >
            Buy $LOVE <IconArrow className='ml4 mt4'></IconArrow>
          </NavItemButton>
          <Menu
            id="basic-menu"
            anchorEl={buyEl}
            open={openBuy}
            onClose={handleCloseBuy}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={openHuobi}>On Huobi Global</MenuItem>
            <MenuItem onClick={openBitMart}>On BitMart</MenuItem>
          </Menu>
        </NavItem>
        <NavItem href='/home#booksOfLove' active={isNav === '1'} onClick={() => { setIsNav('1') }}>Books Of Love</NavItem>
        <NavItem href="/home#goddessesOfLove" active={isNav === '3'} onClick={() => { setIsNav('3') }}> Goddesses Of Love</NavItem>
        <NavItem href="/home#calenderOfLove" active={isNav === '2'} onClick={() => { setIsNav('2') }}>Calender Of Love</NavItem>
        <NavItem active={isNav === '4'}>
          <NavItemButton
            active={false}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onMouseMove={handleClick}
          >
            About <IconArrow className='ml4 mt4'></IconArrow>
          </NavItemButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={openAbout}><LinkA href='/about'>Book & NFT SAGA</LinkA></MenuItem>
            <MenuItem onClick={openAbout}><LinkA href='/about#NFTprice&Trade'>NFT price & Trade</LinkA></MenuItem>
            <MenuItem onClick={openAbout}><LinkA href='/about#Token$LOVE'>Token $LOVE</LinkA></MenuItem>
            <MenuItem onClick={openWhitePaper}>White Paper</MenuItem>
            <MenuItem onClick={openAbout}><LinkA href='/about#TheTeam'>The Team</LinkA></MenuItem>
          </Menu>
        </NavItem>
      </MenuBox>
      <MenuBoxH5>
        <IconMore onClick={() => { setIsOpen(!isOpen) }}></IconMore>
      </MenuBoxH5>
      {
        isOpen &&
        <MenuBoxH5Pop>
          <NavClose onClick={() => { setIsOpen(!isOpen) }}></NavClose>
          <NavItemH5>Buy $Love
            <NavItemH5><LinkA target='_blank' href='https://www.htx.com/en-us/trade/love_usdt?inviter_id=11345710&invite_code=zh5i6223'>On Huobi Global</LinkA> </NavItemH5>
            <NavItemH5><LinkA target='_blank' href='https://www.bitmart.com/trade/en-US?symbol=LOVE_USDT&layout=pro-'>On BitMart</LinkA> </NavItemH5>
          </NavItemH5>
          <NavItemH5 href='/home#booksOfLove' onClick={() => { setIsOpen(false) }}>Books Of Love</NavItemH5>
          <NavItemH5 href="/home#goddessesOfLove" onClick={() => { setIsOpen(false) }}>Goddesses Of Love</NavItemH5>
          <NavItemH5 href="/home#calenderOfLove" onClick={() => { setIsOpen(false) }}>Calender Of Love</NavItemH5>
          <NavItemH5 href="/about">About
            <NavItemH5><LinkA href='/about' >Book & NFT SAGA</LinkA> </NavItemH5>
            <NavItemH5><LinkA href='/about#NFTprice&Trade' onClick={() => { setIsOpen(false) }}>NFT price & Trade</LinkA> </NavItemH5>
            <NavItemH5><LinkA href='/about#Token$LOVE' onClick={() => { setIsOpen(false) }}>Token $LOVE</LinkA> </NavItemH5>
            <NavItemH5 onClick={openWhitePaper}><LinkA>White Paper</LinkA> </NavItemH5>
            <NavItemH5><LinkA href='/about#TheTeam' onClick={() => { setIsOpen(false) }}>The Team</LinkA> </NavItemH5>
          </NavItemH5>
          {/* <NavItemH5 href='/about'>About Deesse</NavItemH5> */}
        </MenuBoxH5Pop>
      }
    </HeaderBox>
  )
}
