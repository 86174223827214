import React from 'react'
import { Box } from '@mui/material';
import { BREAKPOINTS } from 'theme';

import styled from 'styled-components'
import banner from 'assets/img/about/banner.png'
import banner1 from 'assets/img/about/banner1.png'
import banner2 from 'assets/img/about/banner2.png'
import humans from 'assets/img/human/1stJanuary-Elegance.png'
import universe from 'assets/img/universe/1st January - Rigel Kent.png'
import nature from 'assets/img/nature/2nd January - White Gazania.png'
import logoA from 'assets/img/logo_a.svg';
import openSeaTxt from 'assets/img/logo_Opensea.svg'
import elementTxt from 'assets/img/logo_Rarible.svg'
import { ReactComponent as IconLineLeftBig } from 'assets/img/lineLeftBig.svg';
import { ReactComponent as IconLineRightBig } from 'assets/img/lineRightBig.svg';
import IconAmazon from 'assets/img/partner/amazon.svg';
import IconChatGPT from 'assets/img/partner/chatgpt-icon-logo.svg';
import IconBinance from 'assets/img/partner/BinanceNFT.svg';
import IconOpensea from 'assets/img/partner/OpenSea.svg';
import IconHuobi from 'assets/img/partner/huobi-global.svg';
import IconBitMart from 'assets/img/partner/bitmart-logo.svg';
import IconTofu from 'assets/img/partner/tofu.svg';
import IconElement from 'assets/img/partner/ElementLogo.svg';
import IconDream from 'assets/img/partner/dreamlabs.svg';
import IconCoinMarketCap from 'assets/img/partner/Coinmarketcap.svg';
import IconCoinGecko from 'assets/img/partner/coingecko.svg';
import IconMetamask from 'assets/img/partner/metamask.svg';
import chart from 'assets/img/about/chart.png'
import team1 from 'assets/img/universe/25th January - Yed Posterior.png'
import team2 from 'assets/img/universe/20th January - Aldebaran.png'
import team3 from 'assets/img/universe/31th January -Dalim.png'
import team4 from 'assets/img/universe/16th January - Misam.png'
import team5 from 'assets/img/human/10th January - Energetic.png'
import team6 from 'assets/img/human/23th January - Reliable.png'
import team7 from 'assets/img/nature/17th January - Pansy.png'
import team8 from 'assets/img/human/2nd January - Genial.png'
const AboutBox = styled.div`  
`
const BoxR = styled.div`
  width: 1100px;
  margin: auto;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const Box1 = styled(BoxR)`
  margin: 50px auto;
  text-align: center;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin: 20px auto;
    padding: 24px;
  }
`
const MulTitle = styled.div`
  font-family: Baigo;
  font-size: 56px;
  letter-spacing: 2px;
  background: linear-gradient(90deg,#f734ff,#9a2fff,#05c0ff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 28px;
  }
`
const SubTitle = styled.div`
  margin-top: 10px;
  color: #292526;
  text-align: center;
  font-family: Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 2px;
  text-transform: capitalize;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const Banner = styled.img`
  width: 100%;
`
const Box2 = styled(BoxR)`
  margin: 70px auto;
  padding: 30px 50px;
  background-color: #FFF6FA;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin: 20px auto;
    padding: 24px;
  }
`
const Box2Info = styled.div`
  color: #292526;
  font-family: Helvetica;
  font-size: 18px;
  line-height: 1.4;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
      font-size: 16px;
  }
`
const Box2Title = styled(Box2Info)`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 8px;
`
const Box3 = styled(BoxR)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
    margin-bottom: 0px;
    padding: 24px;
  }
`
const Box3Cover = styled.img`
  width: 550px;
  height: 260px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const Box3Intro = styled.div`
  width: 486px;
  color: #292526;
  font-family: Helvetica;
  font-size: 18px;
  line-height: 28px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const BoxIntro = styled.div`
  margin: 20px auto;
  color: #292526;
  font-family: Helvetica;
  font-size: 18px;
  line-height: 28px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const BoxIntroNoMargin = styled.div`
  color: #292526;
  font-family: Helvetica;
  font-size: 18px;
  line-height: 28px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
  }
`
const BoxTitle = styled.div`
  color: #292526;
  font-family: Helvetica;
  font-size: 20px;
  font-weight: 700;
`
const Box5 = styled(BoxR)`
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 10px 24px 24px;
  }
`
const BoxGuiding = styled.div`
  width: 720px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 10px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.30);
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const BoxGuiding1 = styled.div`
  display: flex;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
  }
`
const BoxGuiding2 = styled.div`
  /* display: flex; */
  margin-top: 10px;
  border-top: 1px solid #dedede;
`
const BoxGuidingCover = styled.img`
  width: 260px;
  height: 260px;
  margin-right: 10px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin: 0 auto;
  }
`
const PopoverBoxName = styled.div<{ active: Boolean }>`
  width: 430px;
  height: 44px;
  line-height: 44px;
  padding-left: 15px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  color: #000;
  background: ${props => props.active ? '#FFD3E5' : '#C7EFFF'};
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const PopoverBoxItem = styled.div`
  line-height: 20px;
  padding-left: 10px;
  color: #333;
  font-size: 16px;
`
const GuideName = styled.span`
  text-transform: uppercase;
`

const PopoverBoxItem2 = styled.div`
  padding-left: 10px;
  color: #939393;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 18px;
`
const FlexBox = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0;
  font-size: 16px;
`
const FlexBoxImg = styled.img`
  width: auto;
  height: 24px;
`
const IconSquare = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 6px;
  transform: rotate(45deg);
  background: #EC307F;
  flex-shrink: 0;
`
const IntroLink = styled.a`
  font-size: 18px;
  color: #EC307F;
  text-decoration-line: underline;
  word-break: break-all;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
  }
`
const BoxPlatformTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1400px;
  margin: 40px auto;
  font-family: Baigo;
  font-size: 36px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    margin-bottom: 0px;
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
  }
`
const BoxTitleLine = styled.div`
  padding: 0 10px;
`
const TitleRed = styled.span`
  color: #EC307F;
  padding-right: 5px;
`
const Box4 = styled(BoxR)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /* width: 1200px; */
  margin: 30px auto;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    padding: 0 24px;
  }
`
const Box4Item = styled.div`
  /* width: 25%; */
  margin-right: 30px;
  text-align: center;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    img {
      max-width: 100px;
      max-height: 38px;
    }
    width: 33%;
    max-width: 100px;
    max-height: 38px;
    margin-right: 0px;
    margin-bottom: 8px;
  }
`
const BoxFlex = styled.div`
  display: flex;
  align-items: center;
`
const BoxChart = styled.img`
  margin: 0 auto;
  width: 690px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const BoxTableItem = styled.div`
  display: flex;
  max-width: 1100px;
  margin: auto;
`
const BoxCell = styled.div`
  height: 70px;
  margin-right: 1px;
  margin-bottom: 1px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
  font-size: 16px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
    padding: 4px;
    min-height: 80px;
  }
`
const BoxCellBlack = styled(BoxCell)`
  width: 22%;
  background: #292526;
  color: #FFF;
  text-align: center;
`
const BoxCellBlackBig = styled(BoxCell)`
  width: 34%;
  background: #292526;
  color: #FFF;
  text-align: center;
`
const BoxCellWhite = styled(BoxCell)`
  width: 22%;
  background: #F5F5F5;;
  color: #333;
`
const BoxCellWhiteBig = styled(BoxCell)`
  width: 34%;
  background: #F5F5F5;;
  color: #333;
`
const BoxTeam = styled(BoxR)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`
const BoxTeamItem = styled.div`
  margin: 20px;
`
const BoxTeamItemImg = styled.img`
  width: 200px;
  height: 200px;
`
const TeamName = styled.div`
  color: #292526;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
`
const TeamInfo = styled.div`
  color: #292526;
  text-align: center;
  font-family: Helvetica;
  font-size: 16px;
  line-height: 24px;
`
export default function About() {
  return (
    <AboutBox>
      <Box1>
        <MulTitle>Déesse: The Books Of Love</MulTitle>
        <SubTitle>Change the world by <br />
          reading, sharing, playing and collecting NFTs</SubTitle>
      </Box1>
      <Banner src={banner}></Banner>
      <Box2>
        <Box2Title>The year is 2027 and human civilization is facing a profound psychological instability. </Box2Title>
        <Box2Info className='mb30'>Many human beings are aware that a bright new ERA is coming, however many other human beings are indulging in fears and weaknesses, both personal and externally fueled by world societies and from other tyrant men.</Box2Info>
        <Box2Info className='mb30'>Greed, abuse, war, addictions, apathy and so many other negative feelings and actions are throwing humanity into chaos and despondency. But all is not lost! A group of human beings with courage and good heart, as "Warriors of Light" by wise elders, are teaching a very precious secret.</Box2Info>
        <Box2Info>They know that thanks to the infinite power of mind and heart they could call the "Goddesses Of Love" (Line 1 NFTs on sale) with their magical helpers, the “Guiding Souls” (Line 2 NFTs on sale) down here on planet Earth. </Box2Info>
      </Box2>
      <Box3>
        <Box3Cover src={banner1}></Box3Cover>
        <Box3Intro>{`Only the Goddesses of Love and their Guiding Souls, as extraterrestrial beings with supernatural powers, could have saved the world by collaborating with a special awakened kind of humans, which in this storyline and in future we will call the “Warriors of Light'', all of you dear reader. `}</Box3Intro>
      </Box3>
      <Box3>
        <Box3Intro>You, human, as “Warriors of Light'' which we fantastically like to identify in these special evolved groups:<br />
          - "Indigo" Humans<br />
          - "Crystal" Humans<br />
          - "Rainbow" Humans<br />
          - "Diamond" Humans</Box3Intro>
        <Box3Cover src={banner2}></Box3Cover>
      </Box3>
      <Box5>
        <BoxIntro>Which of these human beings with a special sensitivity do you feel best represented by? Read on and think about it…The time has come to identify yourself, to make this magical story a reality!</BoxIntro>
        <BoxIntro>Three interconnected books will follow, which will contain the "Guiding Souls" NFTs for each theme:</BoxIntro>
        <BoxTitle>(-1-) Humans Guiding Souls</BoxTitle>
        <BoxGuiding>
          <BoxGuiding1>
            <BoxGuidingCover src={humans}></BoxGuidingCover>
            <div>
              <PopoverBoxName active={true}>Elegance</PopoverBoxName>
              <PopoverBoxItem className='mt10'><span>Guiding Soul Name: </span><GuideName>Elegance</GuideName></PopoverBoxItem>
              <PopoverBoxItem><span>Day of Celebration: </span><span>January 1st</span></PopoverBoxItem>
              <PopoverBoxItem><span>Book NFT Theme: </span><span>Humans</span></PopoverBoxItem>
              <PopoverBoxItem><span>Power: </span><span> Connect Indigo Humans to Greek Goddesses </span></PopoverBoxItem>
              <PopoverBoxItem className='mt10'>Elegance is the quality of being graceful and stylish in appearance or manner; style. Or the quality of being pleasingly ingenious and simple; neatness.</PopoverBoxItem>
              <PopoverBoxItem2 className='mt20'>* (max supply first minting round: 20 copies)</PopoverBoxItem2>
              <PopoverBoxItem2>** The price will vary: first-come first-serve basis</PopoverBoxItem2>
            </div>
          </BoxGuiding1>
          <BoxGuiding2>
            <FlexBox>
              <IconSquare></IconSquare>
              <span>Read the full story on the Book</span>
              <IntroLink className='pl10' target='_blank' href='https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks'>Get it now</IntroLink>
              <img className='ml4' width={22} src={logoA}></img>
            </FlexBox>
            <FlexBox>
              <IconSquare></IconSquare>
              <span>Check the best price of this rare NFT on</span>
              <img className='pl10' style={{cursor: 'pointer'}} onClick={() => { window.open('https://opensea.io/DEESSE_ART') }} src={openSeaTxt}></img>
              <FlexBoxImg className='pl10' style={{cursor: 'pointer'}} onClick={() => { window.open('https://element.market/collections/the-calendar-of-love-february-guiding-souls') }} src={IconElement}></FlexBoxImg>
            </FlexBox>
          </BoxGuiding2>
        </BoxGuiding>
        <BoxTitle>(-2-) Universe Guiding Souls</BoxTitle>
        <BoxGuiding>
          <BoxGuiding1>
            <BoxGuidingCover src={universe}></BoxGuidingCover>
            <div>
              <PopoverBoxName active={true}>Rigil Kent</PopoverBoxName>
              <PopoverBoxItem className='mt10'><span>Guiding Soul Name: </span><GuideName>Rigil Kent</GuideName></PopoverBoxItem>
              <PopoverBoxItem><span>Day of Celebration: </span><span>January 1st</span></PopoverBoxItem>
              <PopoverBoxItem><span>Book NFT Theme: </span><span> Universe</span></PopoverBoxItem>
              <PopoverBoxItem><span>Power: </span><span> Connect Indigo Humans to Greek Goddesses  </span></PopoverBoxItem>
              <PopoverBoxItem className='mt10'>Alpha Centauri (α Centauri, Alpha Cen, or α Cen) is a triple star system in the southern constellation of Centaurus. It consists of three stars: Rigil Kentaurus (Alpha Centauri A), Toliman (B) and Proxima Centauri...</PopoverBoxItem>
              <PopoverBoxItem2 className='mt10'>* (max supply first minting round: 20 copies)</PopoverBoxItem2>
              <PopoverBoxItem2>** The price will vary: first-come first-serve basis</PopoverBoxItem2>
            </div>
          </BoxGuiding1>
          <BoxGuiding2>
            <FlexBox>
              <IconSquare></IconSquare>
              <span>Read the full story on the Book</span>
              <IntroLink className='pl10' target='_blank' href='https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks'>Get it now</IntroLink>
              <img className='ml4' width={22} src={logoA}></img>
            </FlexBox>
            <FlexBox>
              <IconSquare></IconSquare>
              <span>Check the best price of this rare NFT on</span>
              <img className='pl10' style={{cursor: 'pointer'}}  onClick={() => { window.open('https://opensea.io/DEESSE_ART') }} src={openSeaTxt}></img>
              <FlexBoxImg className='pl10' style={{cursor: 'pointer'}} onClick={() => { window.open('https://element.market/collections/the-calendar-of-love-february-guiding-souls') }} src={IconElement}></FlexBoxImg>
            </FlexBox>
          </BoxGuiding2>
        </BoxGuiding>
        <BoxTitle>(-3-) Nature Guiding Souls</BoxTitle>
        <BoxGuiding>
          <BoxGuiding1>
            <BoxGuidingCover src={nature}></BoxGuidingCover>
            <div>
              <PopoverBoxName active={true}>White Gazania</PopoverBoxName>
              <PopoverBoxItem className='mt10'><span>Guiding Soul Name: </span><GuideName>White Gazania</GuideName></PopoverBoxItem>
              <PopoverBoxItem><span>Day of Celebration: </span><span>January 1st</span></PopoverBoxItem>
              <PopoverBoxItem><span>Book NFT Theme: </span><span> Nature</span></PopoverBoxItem>
              <PopoverBoxItem><span>Power: </span><span>Connect Raimbow Humans to Norse Goddesses</span></PopoverBoxItem>
              <PopoverBoxItem className='mt10'>Gazania is also called a Treasure flower and it represents wealth and richness in a spiritual meaning in many cultures. Is a stunning compact groundcover that produces multiple blooms over a long flowering period...</PopoverBoxItem>
              <PopoverBoxItem2 className='mt20'>* (max supply first minting round: 20 copies)</PopoverBoxItem2>
              <PopoverBoxItem2>** The price will vary: first-come first-serve basis</PopoverBoxItem2>
            </div>
          </BoxGuiding1>
          <BoxGuiding2>
            <FlexBox>
              <IconSquare></IconSquare>
              <span>Read the full story on the Book</span>
              <IntroLink target='_blank' href='https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks' className='pl10'>Get it now</IntroLink>
              <img className='ml4' width={22} src={logoA}></img>
            </FlexBox>
            <FlexBox>
              <IconSquare></IconSquare>
              <span>Check the best price of this rare NFT on</span>
              <img className='pl10' style={{cursor: 'pointer'}}  onClick={() => { window.open('https://opensea.io/DEESSE_ART') }} src={openSeaTxt}></img>
              <FlexBoxImg className='pl10' style={{cursor: 'pointer'}} onClick={() => { window.open('https://element.market/collections/the-calendar-of-love-february-guiding-souls') }} src={IconElement}></FlexBoxImg>
            </FlexBox>
          </BoxGuiding2>
        </BoxGuiding>
        <BoxIntro>The Guiding Souls complete the 3 groups of our SuperHeroes, and thus also complete the 4-book saga "Deesse The Books Of Love". They are functional to support conscious human beings, Warriors of Light and magical Goddesses Of Love, as always coming from the infinite parts of the Universe.   When human beings of goodwill "Warriors of Light" connect to the Goddesses of Love, something very special happens: the noblest sentiments of human beings, the treasures of nature of Planet Earth and the highest energy powerful from the Infinite Universe will come in support. All of it is represented by the "Guiding Souls" as already introduced.</BoxIntro>
        <BoxIntro>You will thus be able to discover a fantastic world where each Guiding Soul will have a special name full of meaning.  These names will be identifiable in "Hashtags" (#) which will thus be indexed on the pages of the NFTs Marketplaces which will host the sale of the corresponding NFTs. That's all for now, follow the SAGA in the 3 books just described, which will expand for a whole year 2024 and for the next few years to come! </BoxIntro>
        <BoxIntroNoMargin>Books on <IntroLink target='_blank' href='https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks'> Amazon KDP Deesse Official Page</IntroLink></BoxIntroNoMargin>
        <BoxIntroNoMargin>Different NFT Lines on <IntroLink href='/home#goddessesOfLove'> various Marketplaces</IntroLink></BoxIntroNoMargin>
        <BoxIntroNoMargin>All details about Deesse on <IntroLink target='_blank' href='https://deesse-2.gitbook.io/deesse/'>White Paper</IntroLink> </BoxIntroNoMargin>
      </Box5>
      <Box id='NFTprice&Trade'>
        <BoxPlatformTitle>
          <IconLineLeftBig width={395}></IconLineLeftBig>
          <div className='pl10 pr10'>
            <TitleRed> NFT </TitleRed>price & Trade
          </div>
          <IconLineRightBig width={395}></IconLineRightBig>
        </BoxPlatformTitle>
        <Box5>
          <Box2Title>Dear warrior of light,</Box2Title>
          <BoxIntro>The "Goddesses Of Love" NFTs have different prices and are regulated by the free market, by the owners and by the threshold prices that we assigned at the time of launch. The mystery boxes have variable prices which are determined by the owners, inside the mystery boxes you can discover Goddesses Of Love of different origins and potencies (Greek, Norse, Japan).</BoxIntro>
          <BoxIntro>The "Guiding Souls" NFTs have prices set at the time of listing which vary in quantity as follows. First-mover has the competitive advantage of being able to purchase NFTs in limited annual production for prices up to 10 times lower. Following this, the owners will decide the sales prices by exchanging them on free markets and wallets.</BoxIntro>
          <BoxIntroNoMargin>Pricing list for each Day Avatar NFT of the Calendar Of Love. Are minted in a max of 20 copies (per release) on each marketplace:</BoxIntroNoMargin>
          <BoxIntroNoMargin>6.9 Matic X 2 copies </BoxIntroNoMargin>
          <BoxIntroNoMargin>16.9 Matic X 2 copies </BoxIntroNoMargin>
          <BoxIntroNoMargin>69.69 Matic X 4 copies </BoxIntroNoMargin>
          <BoxIntroNoMargin>88.8 Matic X 4 copies </BoxIntroNoMargin>
          <BoxIntroNoMargin>169.88 Matic X 8 copies </BoxIntroNoMargin>
        </Box5>
      </Box>
      <Box4>
        <Box4Item ><img src={IconAmazon} /></Box4Item>
        <Box4Item ><img src={IconChatGPT} /></Box4Item>
        <Box4Item ><img src={IconBinance} /></Box4Item>
        <Box4Item ><img src={IconOpensea} /></Box4Item>
        <Box4Item ><img src={IconHuobi} /></Box4Item>
        <Box4Item ><img src={IconBitMart} /></Box4Item>
        <Box4Item ><img src={IconElement} /></Box4Item>
        <Box4Item ><img src={IconTofu} /></Box4Item>
        <Box4Item ><img src={IconDream} /></Box4Item>
        {/* <Box4Item ><img src={IconCoinMarketCap} /></Box4Item>
        <Box4Item ><img src={IconCoinGecko} /></Box4Item>
        <Box4Item ><img src={IconMetamask} /></Box4Item> */}
      </Box4>
      <Box id='Token$LOVE'>
        <BoxPlatformTitle>
          <IconLineLeftBig></IconLineLeftBig>
          <div className='pl10 pr10'>
            <TitleRed>Token </TitleRed> $LOVE
          </div>
          <IconLineRightBig></IconLineRightBig>
        </BoxPlatformTitle>
        <Box5>
          <BoxIntro>$LOVE token is the core of the DEESSE.art economic model. It will serve as both a reward mechanism within the platform activities and as a means of value exchange within the broader DEESSE.art ecosystem. More info about the Token on CoinMarketCap & CoinGeko (link the 2 logos below)</BoxIntro>
          <BoxFlex>
            <Box4Item ><img src={IconCoinMarketCap} /></Box4Item>
            <Box4Item ><img src={IconCoinGecko} /></Box4Item>
          </BoxFlex>
          <BoxIntroNoMargin className='wordBreak'>Contract address (ERC-20) 0xB2021671E5a8C8695e017EbBab41999c3DCCFC7a </BoxIntroNoMargin>
          <BoxIntroNoMargin><IntroLink target='_blank' href='https://etherscan.io/token/0xB2021671E5a8C8695e017EbBab41999c3DCCFC7a'>https://etherscan.io/token/0xB2021671E5a8C8695e017EbBab41999c3DCCFC7a</IntroLink></BoxIntroNoMargin>
          <Box2Title className='mt60'>Token Functions</Box2Title>
          <BoxIntroNoMargin>Rewards: Players, artists, and users earn $LOVE tokens by playing and creating NFT art.</BoxIntroNoMargin>
          <BoxIntroNoMargin>Trading: $LOVE tokens can be traded on HTX as Huobi Global & BitMart CEX or utilised within the NFT art marketplace to trade NFTs.</BoxIntroNoMargin>
          <BoxIntroNoMargin>Sharing of LOVE: The $LOVE tokens foster collaboration and community by enabling sharing between users, players, artists, and partner crypto projects and an exponential usage as a SocialFi instrument."</BoxIntroNoMargin>
          <BoxIntroNoMargin>Token Distribution</BoxIntroNoMargin>
          <BoxIntroNoMargin>The distribution of $LOVE tokens is designed to encourage participation, reward contribution, and ensure the long-term sustainability of the platform. The token distribution includes allocations for development, marketing, partnerships, community rewards, and reserves. Advertised also on the various Social Media, Communities and on the AMAZON KDP Bookshelf. </BoxIntroNoMargin>
          <FlexCenter>
            <BoxChart src={chart}></BoxChart>
          </FlexCenter>
        </Box5>

        <BoxTableItem>
          <BoxCellBlack>Allocation</BoxCellBlack>
          <BoxCellBlack>% of Total Suply</BoxCellBlack>
          <BoxCellBlack>Token Amount (billion)</BoxCellBlack>
          <BoxCellBlackBig>Vesting</BoxCellBlackBig>
        </BoxTableItem>
        <BoxTableItem>
          <BoxCellBlack>Private Sale</BoxCellBlack>
          <BoxCellWhite>7.5%</BoxCellWhite>
          <BoxCellWhite>1.5</BoxCellWhite>
          <BoxCellWhiteBig>5% initialy, 3 month cliff then 12 month vesting</BoxCellWhiteBig>
        </BoxTableItem>
        <BoxTableItem>
          <BoxCellBlack>Metaverse Eco Fund</BoxCellBlack>
          <BoxCellWhite>10%</BoxCellWhite>
          <BoxCellWhite>2</BoxCellWhite>
          <BoxCellWhiteBig>6 month cliff 3 year monthly vesting</BoxCellWhiteBig>
        </BoxTableItem>
        <BoxTableItem>
          <BoxCellBlack>Staking Issuance</BoxCellBlack>
          <BoxCellWhite>30%</BoxCellWhite>
          <BoxCellWhite>6</BoxCellWhite>
          <BoxCellWhiteBig>3 year monthly vesting</BoxCellWhiteBig>
        </BoxTableItem>
        <BoxTableItem>
          <BoxCellBlack>Gaming Issuance</BoxCellBlack>
          <BoxCellWhite>20%</BoxCellWhite>
          <BoxCellWhite>4</BoxCellWhite>
          <BoxCellWhiteBig>3 year monthly vesting</BoxCellWhiteBig>
        </BoxTableItem>
        <BoxTableItem>
          <BoxCellBlack>Team</BoxCellBlack>
          <BoxCellWhite>10%</BoxCellWhite>
          <BoxCellWhite>2</BoxCellWhite>
          <BoxCellWhiteBig>1 year cliff 3 year monthly</BoxCellWhiteBig>
        </BoxTableItem>
        <BoxTableItem>
          <BoxCellBlack>Deesse Dao</BoxCellBlack>
          <BoxCellWhite>20%</BoxCellWhite>
          <BoxCellWhite>4</BoxCellWhite>
          <BoxCellWhiteBig>3 year monthly vesting</BoxCellWhiteBig>
        </BoxTableItem>
        <BoxTableItem>
          <BoxCellBlack>Pre-listing Event</BoxCellBlack>
          <BoxCellWhite>2.5%</BoxCellWhite>
          <BoxCellWhite>0.5</BoxCellWhite>
          <BoxCellWhiteBig>Fully unlock (Subject to changes based on CEX or IDO platform request)</BoxCellWhiteBig>
        </BoxTableItem>
      </Box>
      <Box id='TheTeam'>
        <BoxPlatformTitle>
          <IconLineLeftBig width={485}></IconLineLeftBig>
          <div className='pl10 pr10'>
            <TitleRed>The </TitleRed> Team
          </div>
          <IconLineRightBig width={485}></IconLineRightBig>
        </BoxPlatformTitle>
        <Box5>
          <BoxIntro>We are designers, crypto natives, games web2 & web3 fans, blockchain developers, copywriters, statistical analysis, marketing and advertising experts. But above all, we are passionate about blockchain and its revolution, creativity in general, so we believe in dreams. </BoxIntro>
          <BoxIntro>Experts mainly from Asia but also with international profiles. The main office has people in Singapore, Japan & China, but others come from Italy, USA and Thailand. The project is "backed by the best", so we'll always have the support  of our precious investors and partners.</BoxIntro>
          <BoxIntro>… No, it's not just a job. We want to make our planet a better world, with small actions and projects that could become hugely successful and transform the world on a global scale. You are our strength and an active part in our mission! Thank you very much, with love...</BoxIntro>
          <BoxIntro>The operations team shares only legit emails as follows.</BoxIntro>
        </Box5>
      </Box>
      <BoxTeam>
        <BoxTeamItem>
          <BoxTeamItemImg src={team1}></BoxTeamItemImg>
          <TeamName>Angel Investor & CEO</TeamName>
          <TeamInfo>Lero Xie</TeamInfo>
          <TeamInfo>zor@deesse.art</TeamInfo>
        </BoxTeamItem>
        <BoxTeamItem>
          <BoxTeamItemImg src={team2}></BoxTeamItemImg>
          <TeamName>Angel Investor & PR</TeamName>
          <TeamInfo>Mike Lee</TeamInfo>
        </BoxTeamItem>
        <BoxTeamItem>
          <BoxTeamItemImg src={team3}></BoxTeamItemImg>
          <TeamName>Product Manager</TeamName>
          <TeamInfo>Hans Syh</TeamInfo>
          <TeamInfo>hans@deesse.art</TeamInfo>
        </BoxTeamItem>
        <BoxTeamItem>
          <BoxTeamItemImg src={team4}></BoxTeamItemImg>
          <TeamName>CMO & Book Author</TeamName>
          <TeamInfo>Andrea Falcinelli</TeamInfo>
          <TeamInfo>andrea@deesse.art</TeamInfo>
        </BoxTeamItem>
        <BoxTeamItem>
          <BoxTeamItemImg src={team5}></BoxTeamItemImg>
          <TeamName>Business Developer</TeamName>
          <TeamInfo>Jhon Tan</TeamInfo>
          <TeamInfo>john@deesse.art</TeamInfo>
        </BoxTeamItem>
        <BoxTeamItem>
          <BoxTeamItemImg src={team6}></BoxTeamItemImg>
          <TeamName>Head Of Development</TeamName>
          <TeamInfo>Lyon Lelouch</TeamInfo>
          <TeamInfo>able@deesse.art</TeamInfo>
        </BoxTeamItem>
        <BoxTeamItem>
          <BoxTeamItemImg src={team7}></BoxTeamItemImg>
          <TeamName>Art Director</TeamName>
          <TeamInfo>Ying Jia</TeamInfo>
          <TeamInfo>Jiaying@deesse.art</TeamInfo>
        </BoxTeamItem>
        <BoxTeamItem>
          <BoxTeamItemImg src={team8}></BoxTeamItemImg>
          <TeamName>CFO</TeamName>
          <TeamInfo>Andy Nighttrain</TeamInfo>
          <TeamInfo>andy@deesse.art</TeamInfo>
        </BoxTeamItem>
      </BoxTeam>
    </AboutBox>
  )
}
