
import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import 'swiper/swiper.min.css';
import "swiper/swiper-bundle.min.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { BREAKPOINTS } from 'theme';
import PinkBg from 'assets/img/banner_top_bg.png';
// import WhiteBg from 'assets/img/whiteBg.png';
import WhiteBg from 'assets/img/welcomeBg.png';
import welcomeH5 from 'assets/img/Welcome.png';
import welcome from 'assets/img/WelcomePc.svg';
import book1 from 'assets/img/img_book1.png';
import book2 from 'assets/img/img_book2.png';
import book3 from 'assets/img/img_book3.png';
import book4 from 'assets/img/img_book4.png';
import book5 from 'assets/img/img_book5.png';
import book6 from 'assets/img/img_book6.png';
import book7 from 'assets/img/img_book7.png';
import book8 from 'assets/img/img_book8.png';
import book9 from 'assets/img/img_book9.png';
import book10 from 'assets/img/img_book10.png';
import book11 from 'assets/img/img_book11.png';
import book12 from 'assets/img/img_book12.png';
import book13 from 'assets/img/img_book13.png';
import btnPinkBg from 'assets/img/btnPinkBg.svg';
import btnPinkBorder from 'assets/img/btnPinkBorder.svg';
// import logoBinance from 'assets/img/Logo_Binance.svg';
import logoBinance from 'assets/img/Binance_Logo.svg';
import logoDreamlab from 'assets/img/logo_dreamlab.svg';
import iconPdf from 'assets/img/icon_pdf.svg';
import logoA from 'assets/img/logo_a.svg';
import line1 from 'assets/img/line_1.svg';
import logAmazonBook from 'assets/img/logo_amazonbooks.svg';
import swiperBg from 'assets/img/swiperBg.png';
import btnPinkBgSmall from 'assets/img/pinkBg_small.svg';
import btnPinkBorderSmall from 'assets/img/pinkBorder_small.svg';
import btnBlackBgSmall from 'assets/img/blackBg_small.svg';
import btnBlackBorderSmall from 'assets/img/blackBorder_small.svg';
import PinkBg2 from 'assets/img/pinkBanner_bg.jpg';
import maskPinkBox from 'assets/img/pinkBox.svg';
import tabBtnPink from 'assets/img/btn_tab_pink.svg';
import tabBtnGray from 'assets/img/btn_tab_gray.svg';
import openSea from 'assets/img/opensea.svg'
import element from 'assets/img/element.svg'
// import binance from 'assets/img/logo_Binance2.svg'
import tofu from 'assets/img/tofu.svg'
import logo6 from 'assets/img/logo_6.svg'
import btnPlatform from 'assets/img/btnPlatform.svg'
import openSeaTxt from 'assets/img/logo_Opensea.svg'
import { ReactComponent as IconLineLeft } from 'assets/img/lineLeft.svg';
import { ReactComponent as IconLineRight } from 'assets/img/lineRight.svg';
import  IconAmazon from 'assets/img/partner/amazon.svg';
import  IconChatGPT from 'assets/img/partner/chatgpt-icon-logo.svg';
import IconBinance from 'assets/img/partner/BinanceNFT.svg';
import IconOpensea from 'assets/img/partner/OpenSea.svg';
import  IconHuobi from 'assets/img/partner/huobi-global.svg';
import IconBitMart from 'assets/img/partner/bitmart-logo.svg';
import IconTofu from 'assets/img/partner/tofu.svg';
import IconElement from 'assets/img/partner/ElementLogo.svg';
import IconDream from 'assets/img/partner/dreamlabs.svg';
import IconCoinMarketCap from 'assets/img/partner/Coinmarketcap.svg';
import IconCoinGecko from 'assets/img/partner/coingecko.svg';
import IconMetamask from 'assets/img/partner/metamask.svg';
import buyLovesw from 'assets/img/buyLovesw.png';
import platinum1 from 'assets/img/platinum1.png';
import platinum2 from 'assets/img/platinum2.png';
import platinum3 from 'assets/img/platinum3.png';
import platinum4 from 'assets/img/platinum4.png';
import { JanuaryHumanIntros, JanuaryUniverseIntros, JanuaryNatureIntros,FebruaryHumanIntros,FebruaryUniverseIntros,FebruaryNatureIntros, MarchHumanIntros, MarchUniverseIntros, MarchNatureIntros, AprilHumanIntros, AprilUniverseIntros, AprilNatureIntros } from '../../bookData/index'

const PinkBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background: url(${PinkBg});
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    height: auto;
    padding: 40px 24px;
  }
`
const WhiteBox = styled.div`
  position: relative;
  width: 1032px;
  height: 110px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px 30px 24px 20px;
  background: url(${WhiteBg});
  background-size: 100%;
  background-repeat: no-repeat;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    height: auto;
    background: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 24px 10px;
  }
`
const Welcome = styled.img`
  display: block;
  position: absolute;
  top: -15px;
  left: calc(50% - 448px);
  width: 900px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: none;
  }
`
const WelcomeH5 = styled.img`
  display: none;
  position: absolute;
  top: -35px;
  left: calc(50% - 150px);
  width: 300px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: block;
  }
`
const Txt = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  padding: 0 4px;
  font-style: italic;
`
const TxtLove = styled(Txt)`
  color: #EC307F;
`
const TxtHold = styled(Txt)`
  color: #F90;
`
const TxtCrypto = styled(Txt)`
  color: #4187FF;
`
const TxtConsciousness = styled(Txt)`
  color: #32C9E9;
`
const TxtDeesse = styled(Txt)`
  color: #EB02FF;
`
const TxtNFT = styled(Txt)`
  color: #F75EB6;  
`
const TxtEBook = styled(Txt)`
  color: #4188F0;  
`
const TxtBlockchain = styled(Txt)`
  color: #F3BA2F;
`
const TxtWeb3 = styled(Txt)`
  color: #6DC2EC;
`
const TxtWeb2 = styled(Txt)`
  color: #62D675;
`
const TxtBuild = styled(Txt)`
  color: #006BFF;
`
const TxtDecen = styled(Txt)`
  color: #56B119;
`
const TxtHuobi = styled(Txt)`
  color: #236DDE;
`
const BookTop = styled.div`
   @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 24px;
  }
`
const BookBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: -40px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-top: 10px;
    flex-direction: column;
  }
`
const BookImg = styled.img`
  width: 433px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const BookRight = styled.div`
  padding-left:72px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding-left:0px;
    padding-top: 20px;
  }
`
const BookName = styled.div`
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
  color: #292526;
  font-family: 'Baigo';
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-bottom: 10px;
  }
`
const BookNameRed = styled.span`
  font-family: 'Baigo';
  color: #EC307F;
`
const BookSubName = styled.div`
  margin-bottom: 40px;
  font-size: 24px;
  color: #292526;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-bottom: 20px;
    font-size: 20px;
  }
`
const BtnPinkBg = styled.a`
  position: relative;
  display: block;
  width: 400px;
  height: 50px;
  margin-top: 20px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  background: url(${btnPinkBg});
  background-repeat: no-repeat;
  background-size: 100%;
  color: #fff;
  cursor: pointer;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 300px;
    height: 38px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const BtnPinkBorder = styled.a`
  position: relative;
  display: block;
  width: 400px;
  height: 50px;
  margin-top: 14px;
  text-align: center;
  line-height: 50px;
  color: #EC307F;
  font-size: 20px;
  cursor: pointer;
  background: url(${btnPinkBorder});
   background-repeat: no-repeat;
  background-size: 100%;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 300px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const BtnIcon1 = styled.img`
  position: absolute;
  top: 10px;
  margin-left: 10px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    position: static;
    margin-left: 8px;
    width: 20px;
  }
`
const LineBox = styled.div`
  width: 600px;
  height: 1px;
  margin: 25px 0;
  background: #D1D1D1;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 300px;
  }
`
const Book1Intro = styled.div`
  width: 600px;
  margin-top: 10px;
  color: #292526;
  font-family: Helvetica;
  font-size: 15px;
  line-height: 24px;
   @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const Book1IntroNoMargin = styled.div`
  width: 450px;
  color: #292526;
  font-family: Helvetica;
  font-size: 15px;
  line-height: 24px;
   @media screen and (max-width: ${BREAKPOINTS.md}px) {
   width: 100%;
  }
`
const BookIntro = styled.div`
  width: 1050px;
  margin: 50px auto;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    margin: 30px auto 50px;
    padding: 0 24px;
  }
`
const Intro = styled.div`
  margin-bottom: 10px;
  color: #292526;
  font-size: 18px;
  line-height: 28px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
    line-height: 24px;
  }
`
const IntroLink = styled.a`
  font-size: 18px;
  color: #EC307F;
  text-decoration-line: underline;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
  }
`
const JanuaryBox = styled.div`
  margin-top: 60px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
      margin-top: 30px;
  }
`
const BoxPlatformTitle = styled.div`
  display: flex;
  align-items: center;
  width: 1200px;
  margin: auto;
  font-family: Baigo;
  font-size: 36px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    font-size: 26px;
    text-align: center;
  }
`
const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
  }
`
const RedTitle = styled.div`
  display: inline-block;
  margin: auto;
  padding-bottom: 4px;
  text-align: center;
  color: #EC307F;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 700;
  border-bottom: 2px dotted #292526;
`
const BuyLovesw = styled.img`
  display: block;
  width: 1082px;
  margin: 10px auto;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const BoxPlatform = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1056px;
  margin: 30px auto;
   @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    flex-direction: column;
    margin: 30px auto;
  }
`
const PlatformItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 50px;
  background: url(${btnPlatform});
  font-size: 20px;
  color: #fff;
  cursor: pointer;
   @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin: 10px auto;
  } 
`
const PlatinumCover = styled.img`
  width: 250px;
  margin: auto;
`
const TitleRed = styled.span`
  color: #EC307F;
  padding-right: 5px;
`
const ChangeSlideBox = styled.div`
  width: 1240px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: auto;
  padding: 10px;
  text-align: right;
  color: #EC307F;
  font-size: 20px;
  cursor: pointer;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const ImgBox = styled.div`
  display: flex;
  justify-content: center;
  
`
const LogoAmazonBooks = styled.img`
  width: 300px;
  margin: auto;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 200px;
  }
`
const SwiperBox = styled.div`
  background: url(${swiperBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 25%;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    /* background-size: 1000%;
    background-position: 0 30%; */
    background: none;
  }
`
const SwiperMain = styled(Swiper)`
  width: 1240px;
  margin: auto;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const BookList = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 60px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
    padding: 0 24px;
  }
`
const BookItem = styled.div`
  padding: 20px 30px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: flex;
    padding: 0px;
    margin-bottom: 10px;
  }
`
const BookItemImg = styled.img`
  width: 310px;
  height: 473px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 130px;
    height: auto;
  }
`
const BookItemInfo = styled.div`
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const BookItemName = styled.div`
  margin-top: 14px;
  margin-bottom: 8px;
  color: #333;
  font-size: 20px;
  font-weight: 700;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-top: 0px;
    margin-bottom: 4px;
    font-size: 16px;
  }
`
const BookItemDesc = styled.div`
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-break: 20px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 12px;
  }
`
const BookItemBtnPinkBg = styled.div<{ active: Boolean }>`
  position: relative;
  width: 310px;
  height: 44px;
  margin-top: 20px;
  text-align: center;
  line-height: 44px;
  font-size: 18px;
  background: url(${props => props.active ? btnPinkBgSmall : btnBlackBgSmall});
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 186px;
    height: 28px;
    margin-top: 6px;
    font-size: 12px;
    background-size: 100%;
    letter-spacing: -0.5px;
  }
`
const BookItemBtnPinkBorder = styled.div<{ active: Boolean }>`
  position: relative;
  width: 310px;
  height: 44px;
  margin-top: 10px;
  text-align: center;
  line-height: 44px;
  font-size: 18px;
  background: url(${props => props.active ? btnPinkBorderSmall : btnBlackBorderSmall});
  background-size: contain;
  background-repeat: no-repeat;
  color: #EC307F;
  cursor: pointer;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 186px;
    height: 28px;
    margin-top: 6px;
    font-size: 12px;
  }
`
const BtnIcon2 = styled.img`
  position: absolute;
  top: 10px;
  margin-left: 10px;
  width: 24px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    position: static;
    margin-left: 6px;
    width: 16px;
  }
`
const Mask = styled.div`
  position: absolute;
  width: calc(100% - 190px);
  height: 100%;
  top: 0;
  left: 95px;
  display: flex;
  justify-content: center;
  background: rgba(255,255,255,.8);
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    left: 0px;
    display: flex;
    align-items: center;
  }
`
const MaskBg = styled.div`
  margin-top: 170px;
  width: 900px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${maskPinkBox});
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin-top: 0px;
    width: 80%;
    background-size: 300%;
    background-position: 20%;
    background-repeat: no-repeat;
  }
`
const MaskTip = styled.div`
  color: #FFF;
  font-family: Abril Fatface;
  font-size: 46px;
  letter-spacing: 2px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 26px;
  }
`
const Box4 = styled.div`
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${PinkBg2});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 25%;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    padding: 0 24px;
  }
`
const Box4Title = styled.div`
  color: #FFF;
  text-shadow: 0px 6px 0px rgba(124, 65, 90, 0.75);
  font-family: Calistoga;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3px;
  -webkit-text-stroke: 10px transparent;
  background: #EC307F;
  -webkit-background-clip: text;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 28px;
    text-align: center;
  }
`
const Box4SubTitle = styled.div`
  color: #EC307F;
  font-family: Baigo;
  font-size: 42px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.826px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    font-size: 22px;
  }
`
const Book4Intro = styled.div`
  width: 644px;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  color: #292526;
  font-family: Helvetica;
  line-height: 30px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const Box5 = styled.div`
  width: 1300px;
  margin: 40px auto;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const Tabs = styled.div`
  display: flex;
`
const TabBtn = styled.div<{ active: Boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 54px;
  margin-right: 10px;
  background: url(${props => props.active ? tabBtnPink : tabBtnGray});
  color: ${props => props.active ? "#fff" : '#292526'};
  cursor: pointer;
  font-weight: 700;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 120px;
    font-size: 14px;
    letter-spacing: -0.6px;
  }
`
const PopoverBox = styled.div`
  width: 720px;
  padding: 10px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const PopoverBox1 = styled.div`
  display: flex;
  font-size: 14px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
  }
`
const PopoverBoxCover = styled.img`
  width: 250px;
  height: 250px;
  margin-right: 10px;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    margin: 0 auto;
  }
`
const PopoverBoxName = styled.div<{ active: Boolean }>`
  width: 440px;
  height: 44px;
  line-height: 44px;
  padding-left: 15px;
  font-size: 18px;
  font-weight: 700;
  font-family: Helvetica;
  color: #000;
  background: ${props => props.active ? '#FFD3E5' : '#C7EFFF'};
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
  }
`
const PopoverBoxItem = styled.div`
  line-height: 20px;
`
const GuideName = styled.span`
  text-transform: uppercase;
`

const PopoverBoxItem2 = styled.div`
  color: #939393;
  font-family: Helvetica;
  font-size: 14px;
`
const PopoverBox2 = styled.div`
  margin-top: 10px;
  border-top: 1px solid #dedede;
`
const FlexBox = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 0;
  img {
    cursor: pointer;
  }
`
const FlexBoxImg = styled.img`
  width: auto;
  height: 24px;
`
const IconSquare = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 6px;
  transform: rotate(45deg);
  background: #EC307F;
  flex-shrink: 0;
`
const CellBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const SmallCover = styled.img`
  width: 64px;
  height: 64px;
  background-size: 100%;
`
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
    fontSize: '18px',
    paddingLeft: 16,
    paddingRight: 4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Helvetica',
    paddingLeft: 16,
    paddingRight: 4,
  },
}));

const Box6Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 30px auto;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    width: 100%;
    padding: 0 24px;
  }
`
const Box6ContainerItem = styled.div`
  width: 25%;
  text-align: center;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    img {
      max-width: 100px;
      max-height: 38px;
    }
    width: 33%;
    max-width: 100px;
    max-height: 38px;
    margin-right: 0px;
    margin-bottom: 8px;
  }
`

const LoveBooks = [
  {
    month: 'January',
    books: [
      {
        src: book2,
        eBookNum: 2,
        name: 'HUMAN SOULS',
        des: `In this book the "Guiding Souls" represent some of the noblest characteristics and sentiments of Human Beings. <br/>
        One for each day of the month!`,
        link1: 'https://opensea.io/collection/deesse-nft-calendar-of-love-january-guiding-souls',
        link2: 'https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks',
        link3: 'https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks',
      },
      {
        src: book3,
        eBookNum: 3,
        name: 'UNIVERSE SOULS',
        des: `In this book the "Guiding Souls'' represent some of the greatest Universe elements known to the human eye.<br/>
        One for each day of the month!`,
        link1: 'https://opensea.io/collection/deesse-nft-calendar-of-love-january-guiding-souls',
        link2: 'https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks',
        link3: 'https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks',
      },
      {
        src: book4,
        eBookNum: 4,
        name: 'NATURE SOULS',
        des: `In this book the "Guiding Souls" represent some of the most beautiful Nature creations in the form of plants and flowers.<br/> One for each day of the month!`,
        link1: 'https://opensea.io/collection/deesse-nft-calendar-of-love-january-guiding-souls',
        link2: 'https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks',
        link3: 'https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks',
      },
    ],
  },
  {
    month: 'February',
    books: [
      {
        src: book5,
        eBookNum: 5,
        name: 'HUMAN SOULS',
        des: `In this book the "Guiding Souls" represent some of the noblest characteristics and sentiments of Human Beings. 
        One for each day of the month!`,
        link1: 'https://opensea.io/collection/deesse-nft-calendar-of-love-february-guiding-souls ',
        link2: 'https://www.amazon.com/dp/B0CR1H65ZP',
        link3: 'https://www.amazon.com/dp/B0CR1H65ZP',
      },
      {
        src: book6,
        eBookNum: 6,
        name: 'UNIVERSE SOULS',
        des: `In this book the "Guiding Souls'' represent some of the greatest Universe elements known to the human eye.
        One for each day of the month!`,
        link1: 'hhttps://opensea.io/collection/deesse-nft-calendar-of-love-february-guiding-souls ',
        link2: 'https://www.amazon.com/dp/B0CR1H65ZP',
        link3: 'https://www.amazon.com/dp/B0CR1H65ZP',
      },
      {
        src: book7,
        eBookNum: 7,
        name: 'NATURE SOULS',
        des: `In this book the "Guiding Souls" represent some of the most beautiful Nature creations in the form of plants and flowers. One for each day of the month!`,
        link1: 'https://opensea.io/collection/deesse-nft-calendar-of-love-february-guiding-souls ',
        link2: 'https://www.amazon.com/dp/B0CR1H65ZP',
        link3: 'https://www.amazon.com/dp/B0CR1H65ZP',
      },
    ],
  },
  {
    month: 'March',
    books: [
      {
        src: book8,
        eBookNum: 8,
        name: 'HUMAN SOULS',
        des: `In this book the "Guiding Souls" represent some of the noblest characteristics and sentiments of Human Beings. 
        One for each day of the month!`,
        link1: 'https://opensea.io/collection/the-calendar-of-love-march-guiding-souls',
        link2: 'https://www.amazon.com/dp/B0CR1H65ZP',
        link3: 'https://www.amazon.com/dp/B0CR1H65ZP',
      },
      {
        src: book9,
        eBookNum: 9,
        name: 'UNIVERSE SOULS',
        des: `In this book the "Guiding Souls'' represent some of the greatest Universe elements known to the human eye.
        One for each day of the month!`,
        link1: 'https://opensea.io/collection/the-calendar-of-love-march-guiding-souls',
        link2: 'https://www.amazon.com/dp/B0CR1H65ZP',
        link3: 'https://www.amazon.com/dp/B0CR1H65ZP',
      },
      {
        src: book10,
        eBookNum: 10,
        name: 'NATURE SOULS',
        des: `In this book the "Guiding Souls" represent some of the most beautiful Nature creations in the form of plants and flowers. One for each day of the month!`,
        link1: 'https://opensea.io/collection/the-calendar-of-love-march-guiding-souls',
        link2: 'https://www.amazon.com/dp/B0CR1H65ZP',
        link3: 'https://www.amazon.com/dp/B0CR1H65ZP',
      },
    ],
  },
  {
    month: 'April',
    books: [
      {
        src: book11,
        eBookNum: 11,
        name: 'HUMAN SOULS',
        des: `In this book the "Guiding Souls" represent some of the noblest characteristics and sentiments of Human Beings. 
        One for each day of the month!`,
        link1: 'https://opensea.io/collection/the-calendar-of-love-april-guiding-souls',
        link2: 'https://www.amazon.com/dp/B0CR1H65ZP',
        link3: 'https://www.amazon.com/dp/B0CR1H65ZP',
      },
      {
        src: book12,
        eBookNum: 12,
        name: 'UNIVERSE SOULS',
        des: `In this book the "Guiding Souls'' represent some of the greatest Universe elements known to the human eye.
        One for each day of the month!`,
        link1: 'https://opensea.io/collection/the-calendar-of-love-april-guiding-souls',
        link2: 'https://www.amazon.com/dp/B0CR1H65ZP',
        link3: 'https://www.amazon.com/dp/B0CR1H65ZP',
      },
      {
        src: book13,
        eBookNum: 13,
        name: 'HUMAnatureN SOULS',
        des: `In this book the "Guiding Souls" represent some of the most beautiful Nature creations in the form of plants and flowers. One for each day of the month!`,
        link1: 'https://opensea.io/collection/the-calendar-of-love-april-guiding-souls',
        link2: 'https://www.amazon.com/dp/B0CR1H65ZP',
        link3: 'https://www.amazon.com/dp/B0CR1H65ZP',
      },
    ]
  },
  {
    month: 'May',
    books: [
      {
        src: book2,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book3,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book4,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
    ]
  },
  {
    month: 'June',
    books: [
      {
        src: book2,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book3,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book4,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
    ]
  },
  {
    month: 'July',
    books: [
      {
        src: book2,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book3,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book4,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
    ]
  },
  {
    month: 'August',
    books: [
      {
        src: book2,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book3,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book4,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
    ]
  },
  {
    month: 'September',
    books: [
      {
        src: book2,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book3,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book4,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
    ]
  },
  {
    month: 'October',
    books: [
      {
        src: book2,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book3,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book4,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
    ]
  },
  {
    month: 'November',
    books: [
      {
        src: book2,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book3,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book4,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
    ]
  },
  {
    month: 'December',
    books: [
      {
        src: book2,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book3,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
      {
        src: book4,
        name: 'HUMAN SOULS',
        des: 'Description：',
        link1: '',
        link2: '',
        link3: '',
      },
    ]
  }
]
const bookNums = {
  human: 2,
  universe: 3,
  nature: 4
}
function createData(day, JanuaryIntro, February, March, April, May, June, July, August, September, October, November, December) {
  return { day, JanuaryIntro, February, March, April, May, June, July, August, September, October, November, December };
}
export default function Home() {
  const swiperRef = useRef(null);
  const [tabActive, setTabActive] = useState('human')
  const [monthActive, setMonthActive] = useState(LoveBooks[0])
  const [monthNext, setMonthNext] = useState(LoveBooks[1])

  const rows = {
    human: [
      createData('1st', JanuaryHumanIntros[0], FebruaryHumanIntros[0], MarchHumanIntros[0], AprilHumanIntros[0], 'Coming 1st May', 'Coming 1st June', 'Coming 1st July', 'Coming 1st August', 'Coming 1st September', 'Coming 1st October', 'Coming 1st November', 'Coming 1st December'),
      createData('2nd', JanuaryHumanIntros[1], FebruaryHumanIntros[1], MarchHumanIntros[1], AprilHumanIntros[1], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('3rd', JanuaryHumanIntros[2], FebruaryHumanIntros[2], MarchHumanIntros[2], AprilHumanIntros[2], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('4th', JanuaryHumanIntros[3], FebruaryHumanIntros[3], MarchHumanIntros[3], AprilHumanIntros[3], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('5th', JanuaryHumanIntros[4], FebruaryHumanIntros[4], MarchHumanIntros[4], AprilHumanIntros[4], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('6th', JanuaryHumanIntros[5], FebruaryHumanIntros[5], MarchHumanIntros[5], AprilHumanIntros[5], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('7th', JanuaryHumanIntros[6], FebruaryHumanIntros[6], MarchHumanIntros[6], AprilHumanIntros[6], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('8th', JanuaryHumanIntros[7], FebruaryHumanIntros[7], MarchHumanIntros[7], AprilHumanIntros[7], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('9th', JanuaryHumanIntros[8], FebruaryHumanIntros[8], MarchHumanIntros[8], AprilHumanIntros[8], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('10th', JanuaryHumanIntros[9], FebruaryHumanIntros[9],MarchHumanIntros[9], AprilHumanIntros[9], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('11th', JanuaryHumanIntros[10], FebruaryHumanIntros[10], MarchHumanIntros[10], AprilHumanIntros[10], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('12th', JanuaryHumanIntros[11], FebruaryHumanIntros[11], MarchHumanIntros[11], AprilHumanIntros[11], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('13th', JanuaryHumanIntros[12], FebruaryHumanIntros[12], MarchHumanIntros[12], AprilHumanIntros[12], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('14th', JanuaryHumanIntros[13], FebruaryHumanIntros[13], MarchHumanIntros[13], AprilHumanIntros[13], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('15th', JanuaryHumanIntros[14], FebruaryHumanIntros[14], MarchHumanIntros[14], AprilHumanIntros[14], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('16th', JanuaryHumanIntros[15], FebruaryHumanIntros[15], MarchHumanIntros[15], AprilHumanIntros[15], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('17th', JanuaryHumanIntros[16], FebruaryHumanIntros[16], MarchHumanIntros[16], AprilHumanIntros[16], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('18th', JanuaryHumanIntros[17], FebruaryHumanIntros[17], MarchHumanIntros[17], AprilHumanIntros[17], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('19th', JanuaryHumanIntros[18], FebruaryHumanIntros[18], MarchHumanIntros[18], AprilHumanIntros[18], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('20th', JanuaryHumanIntros[19], FebruaryHumanIntros[19], MarchHumanIntros[19], AprilHumanIntros[19], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('21th', JanuaryHumanIntros[20], FebruaryHumanIntros[20], MarchHumanIntros[20], AprilHumanIntros[20], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('22th', JanuaryHumanIntros[21], FebruaryHumanIntros[21], MarchHumanIntros[21], AprilHumanIntros[21], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('23th', JanuaryHumanIntros[22], FebruaryHumanIntros[22], MarchHumanIntros[22], AprilHumanIntros[22], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('24th', JanuaryHumanIntros[23], FebruaryHumanIntros[23], MarchHumanIntros[23], AprilHumanIntros[23], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('25th', JanuaryHumanIntros[24], FebruaryHumanIntros[24], MarchHumanIntros[24], AprilHumanIntros[24], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('26th', JanuaryHumanIntros[25], FebruaryHumanIntros[25], MarchHumanIntros[25], AprilHumanIntros[25], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('27th', JanuaryHumanIntros[26], FebruaryHumanIntros[26], MarchHumanIntros[26], AprilHumanIntros[26], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('28th', JanuaryHumanIntros[27], FebruaryHumanIntros[27], MarchHumanIntros[27], AprilHumanIntros[27], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('29th', JanuaryHumanIntros[28], FebruaryHumanIntros[28], MarchHumanIntros[28], AprilHumanIntros[28], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('30th', JanuaryHumanIntros[29], '', MarchHumanIntros[29], AprilHumanIntros[29], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('31th', JanuaryHumanIntros[30], '', MarchHumanIntros[30], '', '"', '"', '"', '"', '"', '"', '"', '"'),
    ],
    universe: [
      createData('1st', JanuaryUniverseIntros[0], FebruaryUniverseIntros[0], MarchUniverseIntros[0], AprilUniverseIntros[0], 'Coming 1st May', 'Coming 1st June', 'Coming 1st July', 'Coming 1st August', 'Coming 1st September', 'Coming 1st October', 'Coming 1st November', 'Coming 1st December'),
      createData('2nd', JanuaryUniverseIntros[1], FebruaryUniverseIntros[1], MarchUniverseIntros[1], AprilUniverseIntros[1], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('3rd', JanuaryUniverseIntros[2], FebruaryUniverseIntros[2], MarchUniverseIntros[2], AprilUniverseIntros[2], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('4th', JanuaryUniverseIntros[3], FebruaryUniverseIntros[3], MarchUniverseIntros[3], AprilUniverseIntros[3], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('5th', JanuaryUniverseIntros[4], FebruaryUniverseIntros[4], MarchUniverseIntros[4], AprilUniverseIntros[4], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('6th', JanuaryUniverseIntros[5], FebruaryUniverseIntros[5], MarchUniverseIntros[5], AprilUniverseIntros[5], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('7th', JanuaryUniverseIntros[6], FebruaryUniverseIntros[6], MarchUniverseIntros[6], AprilUniverseIntros[6], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('8th', JanuaryUniverseIntros[7], FebruaryUniverseIntros[7], MarchUniverseIntros[7], AprilUniverseIntros[7], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('9th', JanuaryUniverseIntros[8], FebruaryUniverseIntros[8], MarchUniverseIntros[8], AprilUniverseIntros[8], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('10th', JanuaryUniverseIntros[9], FebruaryUniverseIntros[9], MarchUniverseIntros[9], AprilUniverseIntros[9], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('11th', JanuaryUniverseIntros[10], FebruaryUniverseIntros[10], MarchUniverseIntros[10], AprilUniverseIntros[10], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('12th', JanuaryUniverseIntros[11], FebruaryUniverseIntros[11], MarchUniverseIntros[11], AprilUniverseIntros[11], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('13th', JanuaryUniverseIntros[12], FebruaryUniverseIntros[12], MarchUniverseIntros[12], AprilUniverseIntros[12], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('14th', JanuaryUniverseIntros[13], FebruaryUniverseIntros[13], MarchUniverseIntros[13], AprilUniverseIntros[13], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('15th', JanuaryUniverseIntros[14], FebruaryUniverseIntros[14], MarchUniverseIntros[14], AprilUniverseIntros[14], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('16th', JanuaryUniverseIntros[15], FebruaryUniverseIntros[15], MarchUniverseIntros[15], AprilUniverseIntros[15], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('17th', JanuaryUniverseIntros[16], FebruaryUniverseIntros[16], MarchUniverseIntros[16], AprilUniverseIntros[16], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('18th', JanuaryUniverseIntros[17], FebruaryUniverseIntros[17], MarchUniverseIntros[17], AprilUniverseIntros[17], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('19th', JanuaryUniverseIntros[18], FebruaryUniverseIntros[18], MarchUniverseIntros[18], AprilUniverseIntros[18], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('20th', JanuaryUniverseIntros[19], FebruaryUniverseIntros[19], MarchUniverseIntros[19], AprilUniverseIntros[19], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('21th', JanuaryUniverseIntros[20], FebruaryUniverseIntros[20], MarchUniverseIntros[20], AprilUniverseIntros[20], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('22th', JanuaryUniverseIntros[21], FebruaryUniverseIntros[21], MarchUniverseIntros[21], AprilUniverseIntros[21], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('23th', JanuaryUniverseIntros[22], FebruaryUniverseIntros[22], MarchUniverseIntros[22], AprilUniverseIntros[22], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('24th', JanuaryUniverseIntros[23], FebruaryUniverseIntros[23], MarchUniverseIntros[23], AprilUniverseIntros[23], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('25th', JanuaryUniverseIntros[24], FebruaryUniverseIntros[24], MarchUniverseIntros[24], AprilUniverseIntros[24], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('26th', JanuaryUniverseIntros[25], FebruaryUniverseIntros[25], MarchUniverseIntros[25], AprilUniverseIntros[25], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('27th', JanuaryUniverseIntros[26], FebruaryUniverseIntros[26], MarchUniverseIntros[26], AprilUniverseIntros[26], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('28th', JanuaryUniverseIntros[27], FebruaryUniverseIntros[27], MarchUniverseIntros[27], AprilUniverseIntros[27], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('29th', JanuaryUniverseIntros[28], FebruaryUniverseIntros[28], MarchUniverseIntros[28], AprilUniverseIntros[28], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('30th', JanuaryUniverseIntros[29], '', MarchUniverseIntros[29], AprilUniverseIntros[29], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('31th', JanuaryUniverseIntros[30], '', MarchUniverseIntros[30], '', '"', '"', '"', '"', '"', '"', '"', '"'),
    ],
    nature: [
      createData('1st', JanuaryNatureIntros[0], FebruaryNatureIntros[0], MarchNatureIntros[0], AprilNatureIntros[0], 'Coming 1st May', 'Coming 1st June', 'Coming 1st July', 'Coming 1st August', 'Coming 1st September', 'Coming 1st October', 'Coming 1st November', 'Coming 1st December'),
      createData('2nd', JanuaryNatureIntros[1], FebruaryNatureIntros[1], MarchNatureIntros[1], AprilNatureIntros[1], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('3rd', JanuaryNatureIntros[2], FebruaryNatureIntros[2], MarchNatureIntros[2], AprilNatureIntros[2], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('4th', JanuaryNatureIntros[3], FebruaryNatureIntros[3], MarchNatureIntros[3], AprilNatureIntros[3], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('5th', JanuaryNatureIntros[4], FebruaryNatureIntros[4], MarchNatureIntros[4], AprilNatureIntros[4], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('6th', JanuaryNatureIntros[5], FebruaryNatureIntros[5], MarchNatureIntros[5], AprilNatureIntros[5], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('7th', JanuaryNatureIntros[6], FebruaryNatureIntros[6], MarchNatureIntros[6], AprilNatureIntros[6], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('8th', JanuaryNatureIntros[7], FebruaryNatureIntros[7], MarchNatureIntros[7], AprilNatureIntros[7], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('9th', JanuaryNatureIntros[8], FebruaryNatureIntros[8], MarchNatureIntros[8], AprilNatureIntros[8], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('10th', JanuaryNatureIntros[9], FebruaryNatureIntros[9], MarchNatureIntros[9], AprilNatureIntros[9], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('11th', JanuaryNatureIntros[10], FebruaryNatureIntros[10], MarchNatureIntros[10], AprilNatureIntros[10], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('12th', JanuaryNatureIntros[11], FebruaryNatureIntros[11], MarchNatureIntros[11], AprilNatureIntros[11], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('13th', JanuaryNatureIntros[12], FebruaryNatureIntros[12], MarchNatureIntros[12], AprilNatureIntros[12], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('14th', JanuaryNatureIntros[13], FebruaryNatureIntros[13], MarchNatureIntros[13], AprilNatureIntros[13], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('15th', JanuaryNatureIntros[14], FebruaryNatureIntros[14], MarchNatureIntros[14], AprilNatureIntros[14], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('16th', JanuaryNatureIntros[15], FebruaryNatureIntros[15], MarchNatureIntros[15], AprilNatureIntros[15], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('17th', JanuaryNatureIntros[16], FebruaryNatureIntros[16], MarchNatureIntros[16], AprilNatureIntros[16], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('18th', JanuaryNatureIntros[17], FebruaryNatureIntros[17], MarchNatureIntros[17], AprilNatureIntros[17], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('19th', JanuaryNatureIntros[18], FebruaryNatureIntros[18], MarchNatureIntros[18], AprilNatureIntros[18], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('20th', JanuaryNatureIntros[19], FebruaryNatureIntros[19], MarchNatureIntros[19], AprilNatureIntros[19], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('21th', JanuaryNatureIntros[20], FebruaryNatureIntros[20], MarchNatureIntros[20], AprilNatureIntros[20], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('22th', JanuaryNatureIntros[21], FebruaryNatureIntros[21], MarchNatureIntros[21], AprilNatureIntros[21], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('23th', JanuaryNatureIntros[22], FebruaryNatureIntros[22], MarchNatureIntros[22], AprilNatureIntros[22], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('24th', JanuaryNatureIntros[23], FebruaryNatureIntros[23], MarchNatureIntros[23], AprilNatureIntros[23], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('25th', JanuaryNatureIntros[24], FebruaryNatureIntros[24], MarchNatureIntros[24], AprilNatureIntros[24], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('26th', JanuaryNatureIntros[25], FebruaryNatureIntros[25], MarchNatureIntros[25], AprilNatureIntros[25], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('27th', JanuaryNatureIntros[26], FebruaryNatureIntros[26], MarchNatureIntros[26], AprilNatureIntros[26], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('28th', JanuaryNatureIntros[27], FebruaryNatureIntros[27], MarchNatureIntros[27], AprilNatureIntros[27], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('29th', JanuaryNatureIntros[28], FebruaryNatureIntros[28], MarchNatureIntros[28], AprilNatureIntros[28], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('30th', JanuaryNatureIntros[29], '', MarchNatureIntros[29], AprilNatureIntros[29], '"', '"', '"', '"', '"', '"', '"', '"'),
      createData('31th', JanuaryNatureIntros[30], '', MarchNatureIntros[30], '', '"', '"', '"', '"', '"', '"', '"', '"'),
    ],
  }
  const slideChange = (event) => {
    console.log(event);
    setMonthActive(LoveBooks[event.activeIndex])
    if(event.activeIndex<11){
      setMonthNext(LoveBooks[event.activeIndex+1])
    }else {
      setMonthNext(LoveBooks[0])

    }
  }
  const changeSlideIndex = () => {
    // console.log(swiperRef.current);
    if (swiperRef && swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      if(monthNext.month === 'January') {
        swiperRef.current.swiper.slideTo(0,0,false)
      }
    }
  }
  const buyGuid = (item) => {
    console.log(item);
    if(item.name === 'HUMAN SOULS'){
      setTabActive('human')
    }else if(item.name === 'UNIVERSE SOULS'){
      setTabActive('universe')
    }else if(item.name === 'NATURE SOULS'){
      setTabActive('nature')
    }
    document.querySelector('#table').scrollIntoView(true)
  }
  const buyeBook = (link) => {
    window.open(link)
  }
  useEffect(() => {
    if (swiperRef && swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(3,0,false)
    }
  },[swiperRef])

  return (
    <div>
      <PinkBox>
        <WhiteBox>
          <Welcome src={welcome}></Welcome>
          <WelcomeH5 src={welcomeH5}></WelcomeH5>
          <TxtLove>$LOVE</TxtLove>
          <TxtHold>#HOLD</TxtHold>
          <TxtCrypto>#Crypto</TxtCrypto>
          <TxtConsciousness>#Consciousness</TxtConsciousness>
          <TxtDeesse>#Deesse</TxtDeesse>
          <TxtBlockchain>#Blockchain</TxtBlockchain>
          <TxtHold>#AmazonKDP</TxtHold>
          <TxtNFT>#NFTCommunity</TxtNFT>
          <TxtEBook>#eBooks</TxtEBook>
          <TxtWeb3>#Web3</TxtWeb3>
          <TxtWeb2>#Web2</TxtWeb2>
          <TxtHold>#ChangeTheWorld </TxtHold>
          <TxtBuild>#BUIDL </TxtBuild>
          {/* <TxtHold>#BinanceNFT</TxtHold> */}
          <TxtDecen>#Decentralised</TxtDecen>
          {/* <TxtHuobi>#HuobiGlobal</TxtHuobi> */}
          <TxtCrypto>#NFTart</TxtCrypto>
          <TxtDeesse>#AIArt</TxtDeesse>
          <TxtLove>$CalendarOfLove</TxtLove>
          <TxtConsciousness>#NFTMarketplace</TxtConsciousness>
        </WhiteBox>
      </PinkBox>
      <BookTop>
        <BookBox>
          <BookImg src={book1}></BookImg>
          <BookRight>
            <BookName><BookNameRed> Déesse: </BookNameRed>The Books Of Love</BookName>
            <BookSubName>Book - 1 - The Storyline </BookSubName>
            {/* <BtnPinkBorder target='_blank' href='/pdf/The Book of Love KDP PDF_free.pdf'>eBook 1 Free Download <BtnIcon1 src={iconPdf}></BtnIcon1></BtnPinkBorder> */}
            <BtnPinkBorder target='_blank' href='/pdf/The Book of Love KDP Paperbook.pdf'>eBook 1 Free Download <BtnIcon1 src={iconPdf}></BtnIcon1></BtnPinkBorder>
            <BtnPinkBorder target='_blank' href='https://www.amazon.com/D%C3%A9esse-Change-reading-playing-collecting/dp/B0CHL7DDCM/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1699349812&sr=1-1 '>Buy Paper Book <BtnIcon1 src={logoA}></BtnIcon1></BtnPinkBorder>
            <LineBox></LineBox>
            {/* <BtnPinkBg href='#calenderOfLove'>Buy Guiding Souls NFTs <BtnIcon1 src={logoDreamlab}></BtnIcon1></BtnPinkBg> */}
            <Book1Intro>When human beings of goodwill <strong>"Warriors of Light"</strong>  connect to the <strong>"Goddesses of Love"</strong> , something very special happens: the noblest sentiments of human beings, the treasures of nature of Planet Earth and the highest energy powerful from the Infinite Universe will come in support in one more magical extraterrestrial form, The <strong>“Guiding Souls”</strong> , the third element, as connectors of the warriors with the goddesses.</Book1Intro>
            <Book1Intro>So, they are the <strong>trinity of powers</strong>  united with each other, and will be able to divert the bad fate of planet earth. Warriors of Light + Goddesses of Love + Guiding Souls, thanks to the infinite universe and its secret laws will always be there to support humanity. </Book1Intro>
            <Book1IntroNoMargin>And most important: Thanks to all of you "Warriors of Light". Read the books and collect the high rarity NFTs in various Lines which will increase in value in the future and can always be traded on open markets.</Book1IntroNoMargin>
          </BookRight>
        </BookBox>
        {/* <BookIntro>
          <Intro>Change the world by reading and sharing Deesse's magical stories. Collect and trade the extremely rare NFT Art Avatars (#) in the various connected markets, one for each day of the year. Thank you Warrior Of Light!</Intro>
          <IntroLink href='/about'>To find out more read "About Deesse" &gt; GO! </IntroLink>
        </BookIntro> */}
      </BookTop>
      <JanuaryBox id="goddessesOfLove">
        <BoxPlatformTitle>
          <svg xmlns="http://www.w3.org/2000/svg" width="172" height="15" viewBox="0 0 172 15" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M164.207 0.242676L171.278 7.31374L164.207 14.3848L157.136 7.31374L164.207 0.242676ZM119.912 9L0 7.5L119.912 6V9ZM146.485 7.31374L142.243 3.0711L138 7.31374L142.243 11.5564L146.485 7.31374Z" fill="#292526" />
          </svg>
          <div className='pl10 pr10'>
            <TitleRed>Buy </TitleRed>Goddesses Of Love & Mystery Boxes
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="172" height="15" viewBox="0 0 172 15" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.79289 0.242676L0.721832 7.31374L7.79289 14.3848L14.864 7.31374L7.79289 0.242676ZM52.0883 9L172 7.5L52.0883 6V9ZM25.5147 7.31374L29.7574 3.0711L34 7.31374L29.7574 11.5564L25.5147 7.31374Z" fill="#292526" />
          </svg>
        </BoxPlatformTitle>
        <Box5>
          <CenterDiv>
            <RedTitle>Gold Rarity Line</RedTitle>
          </CenterDiv>
          <BuyLovesw src={buyLovesw}></BuyLovesw>
        </Box5>
        <BoxPlatform>
          <PlatformItem onClick={()=>{window.open('https://www.binance.com/en/nft/mystery-box/collection/178085740335413248?isBack=1')}}> Binance &nbsp;<img src={logoBinance} /></PlatformItem>
          <PlatformItem  onClick={()=>{window.open('https://opensea.io/collection/binance-nft-mystery-box-deesse')}}> OpenSea &nbsp;<img src={openSea} /></PlatformItem>
          <PlatformItem  onClick={()=>{window.open('https://element.market/collections/deessecard?search%5Btoggles%5D[0]=BUY_NOW')}}> Element &nbsp;<img src={element} /></PlatformItem>
          <PlatformItem  onClick={()=>{window.open('https://tofunft.com/collection/deesse-card/items')}}> tofuNFT &nbsp;<img src={tofu} /></PlatformItem>
        </BoxPlatform>
        <CenterDiv className='mt40'>
            <RedTitle>Platinum Rarity Line</RedTitle>
          </CenterDiv>
        <BoxPlatform>
          <PlatinumCover src={platinum1}></PlatinumCover>
          <PlatinumCover src={platinum2}></PlatinumCover>
          <PlatinumCover src={platinum3}></PlatinumCover>
          <PlatinumCover src={platinum4}></PlatinumCover>
        </BoxPlatform>
        <CenterDiv>
          <PlatformItem className='mr20'  onClick={()=>{window.open('https://opensea.io/collection/deesse-nft-platinum-the-goddesses-of-love')}}> OpenSea &nbsp;<img src={openSea} /></PlatformItem>
          <PlatformItem  onClick={()=>{window.open('https://element.market/collections/deesse-nft-platinum-the-goddesses-of-love')}}> Element &nbsp;<img src={element} /></PlatformItem>
        </CenterDiv>
        <BookIntro>
          <Intro>Change the world by reading and sharing Deesse's magical stories. Collect and trade the extremely rare NFT Art Avatars (#) in the various connected markets, one for each day of the year. Thank you Warrior Of Light!</Intro>
          <IntroLink target='_blank' href='/about'>To find out more read "About Deesse" &gt; GO! </IntroLink>
        </BookIntro>
      </JanuaryBox>
      <JanuaryBox id="booksOfLove">
        <BoxPlatformTitle>
          <IconLineLeft></IconLineLeft>
          <div className='pl10 pr10'>
            <TitleRed>{monthActive.month} </TitleRed> $LOVE stories
          </div>
          <IconLineRight></IconLineRight>
        </BoxPlatformTitle>
        {/* <JanuaryTitle><TitleRed>{monthActive.month} </TitleRed> $LOVE stories</JanuaryTitle> */}
        <ChangeSlideBox onClick={changeSlideIndex}>{monthNext.month}
          <svg style={{ paddingLeft: '4px' }} xmlns="http://www.w3.org/2000/svg" width="27" height="14" viewBox="0 0 27 14" fill="none">
            <path d="M17.5812 7L10.5173 14L9.41846 12.9111L15.3836 7L9.41846 1.08889L10.5173 0L17.5812 7Z" fill="#EC307F" />
            <path d="M8.16279 7L1.09884 14L0 12.9111L5.96512 7L0 1.08889L1.09884 0L8.16279 7Z" fill="#EC307F" />
            <path d="M27.0002 7L19.9362 14L18.8374 12.9111L24.8025 7L18.8374 1.08889L19.9362 0L27.0002 7Z" fill="#EC307F" />
          </svg>
        </ChangeSlideBox>
        <ImgBox>
          <LogoAmazonBooks src={logAmazonBook}></LogoAmazonBooks>
        </ImgBox>
        <SwiperBox>
          <SwiperMain ref={swiperRef} modules={[Navigation]} navigation={true} onSlideChange={slideChange}>
            {
              LoveBooks.map((slide, index) => (
                <SwiperSlide>
                  <BookList>
                    {
                      slide.books.map((item,idx) => (
                        <BookItem>
                          <BookItemImg src={item.src}></BookItemImg>
                          <BookItemInfo>
                            <BookItemName>{item.name}</BookItemName>
                            <BookItemDesc dangerouslySetInnerHTML={{__html:item.des}}></BookItemDesc>
                            <BookItemBtnPinkBg active={index < 4} onClick={()=>{buyeBook(item.link1)}}>Buy Guiding Souls NFTs  <BtnIcon2 src={logoDreamlab}></BtnIcon2></BookItemBtnPinkBg>
                            <BookItemBtnPinkBorder active={index < 4} onClick={()=>{buyeBook(item.link2)}}>Buy eBook {item.eBookNum}  <BtnIcon2 src={logoA}></BtnIcon2></BookItemBtnPinkBorder>
                            <BookItemBtnPinkBorder active={index < 4} onClick={()=>{buyeBook(item.link3)}}>Buy Paper Book {item.eBookNum}  <BtnIcon2 src={logoA}></BtnIcon2></BookItemBtnPinkBorder>
                          </BookItemInfo>
                        </BookItem>
                      ))
                    }
                    {
                      slide.month != 'January' && slide.month != 'February' && slide.month != 'March' && slide.month != 'April' && 
                      <Mask>
                        <MaskBg>
                          <MaskTip>Live from {monthActive.month}</MaskTip>
                          <MaskTip>2024</MaskTip>
                        </MaskBg>
                      </Mask>
                    }
                  </BookList>
                </SwiperSlide>
              ))
            }

          </SwiperMain>
          <BookIntro>
            <Intro>Each NFT Art avatar name corresponds to a "hashtag" (#), for the 3 themes elements: "Human, Universe, Nature". Each NFT will be produced in limited numbers with an exponentially increasing price. Those who purchase the first lines of NFTs produced will win a sensational price, those who follow will have to pay more or negotiate the price with the owners of the NFTs in circulation in the various markets.</Intro>
            <IntroLink target='_blank' href='/about'>To find out more read "About Deesse" &gt; GO! </IntroLink>
          </BookIntro>
        </SwiperBox>
      </JanuaryBox>
      <Box4 id="calenderOfLove">
        <Box4Title>THE CALENDAR OF LOVE</Box4Title>
        <Box4SubTitle> Buy Guiding souls nft</Box4SubTitle>
        <Book4Intro>
            <div>Every day a magic of human love, universal energy and purity of nature.
            An avatar NFT Art for each day of the calendar of love.</div>
          <IntroLink target='_blank' href='/about'>To find out more read "About Deesse" &gt; GO! </IntroLink>
        </Book4Intro>
      </Box4>
      <Box5 id='table'>
        <Tabs>
          <TabBtn active={tabActive === 'human'} onClick={() => { setTabActive('human') }}>Human elements</TabBtn>
          <TabBtn active={tabActive === 'universe'} onClick={() => { setTabActive('universe') }}>Universe elements</TabBtn>
          <TabBtn active={tabActive === 'nature'} onClick={() => { setTabActive('nature') }}>Nature elements</TabBtn>
        </Tabs>
        <TableContainer >
          <Table sx={{ minWidth: 350 }} aria-label="customized table" >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="left" width={100}>January</StyledTableCell>
                <StyledTableCell align="left">February</StyledTableCell>
                <StyledTableCell align="left">March</StyledTableCell>
                <StyledTableCell align="left">April</StyledTableCell>
                <StyledTableCell align="left">May</StyledTableCell>
                <StyledTableCell align="left">June</StyledTableCell>
                <StyledTableCell align="left">July</StyledTableCell>
                <StyledTableCell align="left">August</StyledTableCell>
                <StyledTableCell align="left">September</StyledTableCell>
                <StyledTableCell align="left">October</StyledTableCell>
                <StyledTableCell align="left">November</StyledTableCell>
                <StyledTableCell align="left">December</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows[tabActive].map((row) => (
                <TableRow
                  key={row.day}
                >
                  <StyledTableCell align="center" style={{ color: '#EC307F', fontWeight: 700 }} component="th" scope="row">
                    {row.day}
                  </StyledTableCell>
                  <StyledTableCell align="left" width={100}>
                    <Tooltip placement="right-end" interactive enterTouchDelay={0} leaveTouchDelay={50000} title={
                      <PopoverBox>
                        <PopoverBox1>
                          <PopoverBoxCover src={row.JanuaryIntro.src}></PopoverBoxCover>
                          <div>
                            <PopoverBoxName active={row.JanuaryIntro.sex === 'pink'}>{row.JanuaryIntro.name}</PopoverBoxName>
                            <PopoverBoxItem className='mt10'><span>Guiding Soul Name: </span><GuideName>{row.JanuaryIntro.guideName}</GuideName></PopoverBoxItem>
                            <PopoverBoxItem><span>Day of Celebration: </span><span>{row.JanuaryIntro.day}</span></PopoverBoxItem>
                            <PopoverBoxItem><span>Book NFT Theme: </span><span>{row.JanuaryIntro.theme}</span></PopoverBoxItem>
                            <PopoverBoxItem><span>Power: </span><span>{row.JanuaryIntro.power}</span></PopoverBoxItem>
                            <PopoverBoxItem className='mt10'>{row.JanuaryIntro.des}</PopoverBoxItem>
                            <PopoverBoxItem2>* (max supply first minting round: 20 copies)</PopoverBoxItem2>
                            <PopoverBoxItem2>** The price will vary: first-come first-serve basis</PopoverBoxItem2>
                          </div>
                        </PopoverBox1>
                        <PopoverBox2>
                          <FlexBox>
                            <IconSquare></IconSquare>
                            <span>Read the full story on the Book</span>
                            <IntroLink href='https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks' target='_blank' className='pl10'>Get it now</IntroLink>
                            <img className='ml4' width={22} src={logoA}></img>
                          </FlexBox>
                          <FlexBox>
                            <IconSquare></IconSquare>
                            <span>Check the best price of this rare NFT on</span>
                            <img className='pl10' onClick={()=>{window.open('https://opensea.io/collection/deesse-nft-calendar-of-love-january-guiding-souls')}} src={openSeaTxt}></img>
                            <FlexBoxImg className='pl10' style={{cursor: 'pointer'}} onClick={() => { window.open('https://element.market/collections/deesse-nft-calendar-of-love-january-guiding-souls') }} src={IconElement}></FlexBoxImg>
                          </FlexBox>
                        </PopoverBox2>
                      </PopoverBox>
                    }>
                      <CellBox>
                        <SmallCover src={row.JanuaryIntro.src} ></SmallCover>
                        <div className='tac'>
                          {row.JanuaryIntro.guideName}
                        </div>
                      </CellBox>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="left" width={100}>
                    {
                      row.February?
                    <Tooltip placement="right-end" interactive enterTouchDelay={0} leaveTouchDelay={50000} title={
                      <PopoverBox>
                        <PopoverBox1>
                          <PopoverBoxCover src={row.February.src}></PopoverBoxCover>
                          <div>
                            <PopoverBoxName active={row.February.sex === 'pink'}>{row.February.name}</PopoverBoxName>
                            <PopoverBoxItem className='mt10'><span>Guiding Soul Name: </span><GuideName>{row.February.guideName}</GuideName></PopoverBoxItem>
                            <PopoverBoxItem><span>Day of Celebration: </span><span>{row.February.day}</span></PopoverBoxItem>
                            <PopoverBoxItem><span>Book NFT Theme: </span><span>{row.February.theme}</span></PopoverBoxItem>
                            <PopoverBoxItem><span>Power: </span><span>{row.February.power}</span></PopoverBoxItem>
                            <PopoverBoxItem className='mt10'>{row.February.des}</PopoverBoxItem>
                            <PopoverBoxItem2>* (max supply first minting round: 20 copies)</PopoverBoxItem2>
                            <PopoverBoxItem2>** The price will vary: first-come first-serve basis</PopoverBoxItem2>
                          </div>
                        </PopoverBox1>
                        <PopoverBox2>
                          <FlexBox>
                            <IconSquare></IconSquare>
                            <span>Read the full story on the Book</span>
                            <IntroLink href='https://www.amazon.com/gp/product/B0CR1H65ZP?ref_=dbs_p_pwh_rwt_anx_b_lnk&storeType=ebooks' target='_blank' className='pl10'>Get it now</IntroLink>
                            <img className='ml4' width={22} src={logoA}></img>
                          </FlexBox>
                          <FlexBox>
                            <IconSquare></IconSquare>
                            <span>Check the best price of this rare NFT on</span>
                            <img className='pl10' onClick={()=>{window.open('https://opensea.io/collection/deesse-nft-calendar-of-love-february-guiding-souls')}} src={openSeaTxt}></img>
                            <FlexBoxImg className='pl10' style={{cursor: 'pointer'}} onClick={() => { window.open('https://element.market/collections/the-calendar-of-love-february-guiding-souls') }} src={IconElement}></FlexBoxImg>
                          </FlexBox>
                        </PopoverBox2>
                      </PopoverBox>
                    }>
                      <CellBox>
                        <SmallCover src={row.February.src} ></SmallCover>
                        <div className='tac'>
                          {row.February.guideName}
                        </div>
                      </CellBox>
                    </Tooltip>:
                    <></>
                    }
                  </StyledTableCell>
                  {/* <StyledTableCell align="left">{row.February}</StyledTableCell> */}
                  <StyledTableCell align="left">
                  {
                      row.March?
                    <Tooltip placement="right-end" interactive enterTouchDelay={0} leaveTouchDelay={50000} title={
                      <PopoverBox>
                        <PopoverBox1>
                          <PopoverBoxCover src={row.March.src}></PopoverBoxCover>
                          <div>
                            <PopoverBoxName active={row.March.sex === 'pink'}>{row.March.name}</PopoverBoxName>
                            <PopoverBoxItem className='mt10'><span>Guiding Soul Name: </span><GuideName>{row.March.guideName}</GuideName></PopoverBoxItem>
                            <PopoverBoxItem><span>Day of Celebration: </span><span>{row.March.day}</span></PopoverBoxItem>
                            <PopoverBoxItem><span>Book NFT Theme: </span><span>{row.March.theme}</span></PopoverBoxItem>
                            <PopoverBoxItem><span>Power: </span><span>{row.March.power}</span></PopoverBoxItem>
                            <PopoverBoxItem className='mt10'>{row.March.des}</PopoverBoxItem>
                            <PopoverBoxItem2>* (max supply first minting round: 20 copies)</PopoverBoxItem2>
                            <PopoverBoxItem2>** The price will vary: first-come first-serve basis</PopoverBoxItem2>
                          </div>
                        </PopoverBox1>
                        <PopoverBox2>
                          <FlexBox>
                            <IconSquare></IconSquare>
                            <span>Read the full story on the Book</span>
                            <IntroLink href='https://www.amazon.com/dp/B0CR1H65ZP?binding=paperback&ref=dbs_m_mng_wam_sft_tpbk_tkin' target='_blank' className='pl10'>Get it now</IntroLink>
                            <img className='ml4' width={22} src={logoA}></img>
                          </FlexBox>
                          <FlexBox>
                            <IconSquare></IconSquare>
                            <span>Check the best price of this rare NFT on</span>
                            <img className='pl10' onClick={()=>{window.open('https://opensea.io/collection/the-calendar-of-love-march-guiding-souls')}} src={openSeaTxt}></img>
                            <FlexBoxImg className='pl10' style={{cursor: 'pointer'}} onClick={() => { window.open('https://element.market/collections/the-calendar-of-love-march-guiding-souls') }} src={IconElement}></FlexBoxImg>
                          </FlexBox>
                        </PopoverBox2>
                      </PopoverBox>
                    }>
                      <CellBox>
                        <SmallCover src={row.March.src} ></SmallCover>
                        <div className='tac'>
                          {row.March.guideName}
                        </div>
                      </CellBox>
                    </Tooltip>:
                    <></>
                  }
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  {
                      row.April?
                    <Tooltip placement="right-end" interactive enterTouchDelay={0} leaveTouchDelay={50000} title={
                      <PopoverBox>
                        <PopoverBox1>
                          <PopoverBoxCover src={row.April.src}></PopoverBoxCover>
                          <div>
                            <PopoverBoxName active={row.April.sex === 'pink'}>{row.April.name}</PopoverBoxName>
                            <PopoverBoxItem className='mt10'><span>Guiding Soul Name: </span><GuideName>{row.April.guideName}</GuideName></PopoverBoxItem>
                            <PopoverBoxItem><span>Day of Celebration: </span><span>{row.April.day}</span></PopoverBoxItem>
                            <PopoverBoxItem><span>Book NFT Theme: </span><span>{row.April.theme}</span></PopoverBoxItem>
                            <PopoverBoxItem><span>Power: </span><span>{row.April.power}</span></PopoverBoxItem>
                            <PopoverBoxItem className='mt10'>{row.April.des}</PopoverBoxItem>
                            <PopoverBoxItem2>* (max supply first minting round: 20 copies)</PopoverBoxItem2>
                            <PopoverBoxItem2>** The price will vary: first-come first-serve basis</PopoverBoxItem2>
                          </div>
                        </PopoverBox1>
                        <PopoverBox2>
                          <FlexBox>
                            <IconSquare></IconSquare>
                            <span>Read the full story on the Book</span>
                            <IntroLink href='https://www.amazon.com/dp/B0CR1H65ZP?binding=paperback&ref=dbs_m_mng_wam_sft_tpbk_tkin' target='_blank' className='pl10'>Get it now</IntroLink>
                            <img className='ml4' width={22} src={logoA}></img>
                          </FlexBox>
                          <FlexBox>
                            <IconSquare></IconSquare>
                            <span>Check the best price of this rare NFT on</span>
                            <img className='pl10' onClick={()=>{window.open('https://opensea.io/collection/the-calendar-of-love-april-guiding-souls')}} src={openSeaTxt}></img>
                            <FlexBoxImg className='pl10' style={{cursor: 'pointer'}} onClick={() => { window.open('https://element.market/collections/the-calendar-of-love-april-guiding-souls') }} src={IconElement}></FlexBoxImg>
                          </FlexBox>
                        </PopoverBox2>
                      </PopoverBox>
                    }>
                      <CellBox>
                        <SmallCover src={row.April.src} ></SmallCover>
                        <div className='tac'>
                          {row.April.guideName}
                        </div>
                      </CellBox>
                    </Tooltip>:
                    <></>
                  }
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.May}</StyledTableCell>
                  <StyledTableCell align="left">{row.June}</StyledTableCell>
                  <StyledTableCell align="left">{row.July}</StyledTableCell>
                  <StyledTableCell align="left">{row.August}</StyledTableCell>
                  <StyledTableCell align="left">{row.September}</StyledTableCell>
                  <StyledTableCell align="left">{row.October}</StyledTableCell>
                  <StyledTableCell align="left">{row.November}</StyledTableCell>
                  <StyledTableCell align="left">{row.December}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box5>
      <JanuaryBox className='mt40'>
        <BoxPlatformTitle>
          <IconLineLeft></IconLineLeft>
          <div className='pl10 pr10'>
            <TitleRed> Backed </TitleRed> by the best
          </div>
          <IconLineRight></IconLineRight>
        </BoxPlatformTitle>
        <Box6Container>
          <Box6ContainerItem ><img src={IconAmazon} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconChatGPT} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconBinance} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconOpensea} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconHuobi} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconBitMart} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconElement} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconTofu} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconDream} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconCoinMarketCap} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconCoinGecko} /></Box6ContainerItem>
          <Box6ContainerItem ><img src={IconMetamask} /></Box6ContainerItem>
        </Box6Container>
      </JanuaryBox>
    </div>
  )
}
